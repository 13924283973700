const joinTheCommunity = 'join-the-community';
const startPage = 'start page';
// Getters

export const getJoinTheCommunityPageData = (pages) => pages.find(item => item.page.name === joinTheCommunity);
export const getJoinTheCommunityStartPage = (content) => content.page.parts.find((item => item.partName === startPage));
export const getJoinTheCommunityStartPageTitle = (content) => content.title;
export const getJoinTheCommunityStartPageDescription = (content) => content.description;
export const getJoinTheCommunityStartPageSecondDescription = (content) => content.second_description;
export const getJoinTheCommunityForm = (content) => content.form;
export const getJoinTheCommunityEducationalLevelOptions = (content) => content.educationalLevelOptions;
export const getJoinTheCommunityAdvisoryAreaOptions = (content) => content.advisoryAreaOptions
export const getJoinTheCommunityButtonTitle = (content) => content.formButtonTitle

const joinTheCommunityGetters = {
    getJoinTheCommunityPageData,
    getJoinTheCommunityStartPage,
    getJoinTheCommunityStartPageTitle,
    getJoinTheCommunityStartPageDescription,
    getJoinTheCommunityStartPageSecondDescription,
    getJoinTheCommunityForm,
    getJoinTheCommunityEducationalLevelOptions,
    getJoinTheCommunityAdvisoryAreaOptions,
    getJoinTheCommunityButtonTitle
}

export default joinTheCommunityGetters;
