<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  setup() {

  }
};
</script>

<style>

@media (max-width: 1024px) {
  body {
    overflow: hidden;
    width: 100%;
  }
}

#app {
  font-family: DM Sans, sans-serif;
}
</style>
