<template>
  <form
      class="RelaxifyContactUsForm"
      ref='form'
      @submit.prevent="sendEmail"
  >
    <div class="RelaxifyContactUsForm__names">
      <input type="text" :placeholder="formData.firstName" name="firstName"/>
      <input type="text" required :placeholder="formData.lastName" name="lastName"/>
    </div>
    <input class="RelaxifyContactUsForm__email" type="email" required :placeholder="formData.email" name="email"/>
    <input class="RelaxifyContactUsForm__message" v-if="isTheFormOnAboutUs" type="tel" name="message"
           :placeholder="formData.message"/>

    <input class="RelaxifyContactUsForm__linkedIn" v-if="isTheFormOnJoinTheCommunity" name="linkedin" :placeholder="formData.linkedin"/>
    <input class="RelaxifyContactUsForm__education" v-if="isTheFormOnJoinTheCommunity" name="education" :placeholder="formData.educationField"/>
    <div class="RelaxifyContactUsForm__education__level__options" v-if="isTheFormOnJoinTheCommunity">
      <span> {{ educationalLevelOptions.name }}</span>
      <v-radio-group v-model="educationLevel" name="educationLevel">
        <v-radio
            v-for="option in educationalLevelOptions.options"
            :key="option.value"
            :label="option.name"
            :value="option.value"
        ></v-radio>
      </v-radio-group>
    </div>
    <div class="RelaxifyContactUsForm__advisory__area__options" v-if="isTheFormOnJoinTheCommunity">
      <div>
        <span> {{ advisoryAreaOptions.name }} </span>
      </div>
      <div class="RelaxifyContactUsForm__advisory__area__checkBoxes">
        <v-checkbox v-for="option in advisoryAreaOptions.options" name="advisoryArea" v-model="advisoryArea" :value="option.value"
                    :label="option.name" :key="option.value"/>
      </div>
    </div>
    <RelaxifyButton color="primary" type="submit"> {{ formButtonTitleReactive }}</RelaxifyButton>
  </form>
</template>

<script>
import RelaxifyButton from "@/components/RelaxifyButton";
import emailjs from '@emailjs/browser';
  import {ref} from "vue";

export default {
  name: "RelaxifyContactUsForm",
  components: {RelaxifyButton},
  props: {
    formData: Object,
    isTheFormOnAboutUs: Boolean,
    isTheFormOnJoinTheCommunity: Boolean,
    formButtonTitle: String,
    educationalLevelOptions: Object,
    advisoryAreaOptions: Object
  },
  setup(props) {

    const formButtonTitleReactive = ref(props.formButtonTitle);
    const form = ref(null);

    const sendEmail = () => {
      formButtonTitleReactive.value = 'Send...'

      emailjs.sendForm('service_9lncquz', props.isTheFormOnJoinTheCommunity ? 'template_9to3a99' :'template_57xnaj8', form.value, 'Pw_T75OVNlyDUvNnX')
          .then((result) => {
            if (result.text) {
              alert('An email has been sent!');
              formButtonTitleReactive.value = props.formButtonTitle;
              form.value.reset();
            }
          }, (error) => {
            console.log('FAILED...', error.text);
          });
    }

    const educationLevel = '';
    const advisoryArea = '';

    return {
      sendEmail,
      educationLevel,
      advisoryArea,
      form,
      formButtonTitleReactive
    }
  },
}
</script>

<style lang="scss">

.RelaxifyContactUsForm {

  @media (min-width: 1440px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
  }


  &__names {
    @media (min-width: 1440px) {
      display: flex;
      input {
        width: 298px !important;

        @media (min-width: 1440px) {
          width: 100% !important;
        }

        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }

  &__email,
  &__linkedIn,
  &__education,
  &__names {
    @media (min-width: 1440px) {
      width: 621px !important;
      height: 78px !important;
    }
  }

  input {
    border: 1px solid #97A7C3;
    border-radius: 10px;
    width: 366.89px;
    height: 64.35px;
    margin-bottom: 30px;
    padding-left: 20px;
    background: white;
  }

  &__message {
    @media (min-width: 1440px) {
      width: 621px !important;
      height: 216px !important;
    }

    width: 367px;
    height: 195px;
  }

  .RelaxifyContactUsForm__message::placeholder {

    @media (min-width: 1440px) {
      transform: translatey(-60px);
    }
  }

  input::placeholder {

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #193566;
  }

  .RelaxifyButton {
    width: 100%;
    height: 50px;

    @media (min-width: 1440px) {
      margin: 50px 0 !important;
    }


    button {
      @media (min-width: 1440px) {
        width: 623px !important;
        height: 56px !important;
      }

      width: 100%;
      height: 50px !important;
      color: #193566 !important;
    }
  }

  &__education__level__options,
  &__advisory__area__options {
    margin-bottom: 30px;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #193566;
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px !important;
      color: #193566 !important;
    }

    .v-input--selection-controls__ripple {
      display: none;
    }

    .v-icon.v-icon {
      font-size: 17px;
    }

    .theme--light.v-icon {
      color: #5358C2
    }

    .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
      margin-bottom: 20px;
    }

    .v-input__control {
      margin-left: -6px;
    }
  }

  .v-input--radio-group__input,
  .RelaxifyContactUsForm__advisory__area__checkBoxes {
    @media (min-width: 1440px) {
      display: flex;
      flex-direction: row !important;
      align-items: baseline;
      justify-content: space-between;
    }
  }

  .v-radio.theme--light {
    @media (min-width: 1440px) {
    }
  }
}
</style>

