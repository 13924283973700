const reviews = [
    {
        description: 'I felt something was wrong; I just couldn’t name what was wrong exactly. Relaxify was quite a revelation, to be honest - defining my weaknesses and helping me work on them daily. It’s a game-changer!'
    },
    {
        description: 'I felt something was wrong; I just couldn’t name what was wrong exactly. Relaxify was quite a revelation, to be honest - defining my weaknesses and helping me work on them daily. It’s a game-changer!'
    }
]

export default reviews;
