// Constant titles
const corporate = 'corporate';
const startPage = 'start page';
const facts = 'FACTS AND STATS';
const numbers = 'NUMBERS';
const success = 'SUCCESS';
const growth = 'GROWTH';
// Getters

export const getCorporatePageData = (pages) => pages.find(item => item.page.name === corporate);
export const getCorporateStartPage  = (content) => content.page.parts.find(item => item.partName === startPage);
export const getCorporateStartPageDesktopDescription = (content) => content.desktop_description;
export const getCorporateFactsData = (content) => content.page.parts.find(item => item.partName === facts);
export const getCorporateNumbersData = (content) => content.page.parts.find(item => item.partName === numbers);
export const getCorporateSuccessData = (content) => content.page.parts.find(item => item.partName === success);
export const getCorporateSuccessDataYellowDotImage = (content) => content.yellow_dot_icon;
export const getCorporateGrowthData = (content) => content.page.parts.find(item => item.partName === growth);
const corporateGetters = {
  getCorporatePageData,
  getCorporateStartPage,
  getCorporateStartPageDesktopDescription,
  getCorporateFactsData,
  getCorporateNumbersData,
  getCorporateSuccessData,
  getCorporateSuccessDataYellowDotImage,
  getCorporateGrowthData
}

export default corporateGetters;
