<template>
  <div class="RelaxifyAboutUs">
    <RelaxifyHeader/>
    <div class="RelaxifyAboutUs__first-part">
      <div class="RelaxifyAboutUs__first-part__labels">
        <h1 class="RelaxifyAboutUs__first-box title "> {{ aboutUsPageFirstBoxData.title }} </h1>
        <div class="RelaxifyAboutUs__first-box description"> {{ aboutUsPageFirstBoxData.description }}</div>
        <div class="RelaxifyAboutUs__first-box-description description">
          {{ aboutUsPageFirstBoxData.secondDescription }}
        </div>
      </div>
      <div class="RelaxifyAboutUs__first-box--image-wrapper">
        <img loading="lazy" :src="aboutUsPageFirstBoxData.image" alt="First box image"/>
      </div>

    </div>

    <div class="RelaxifyAboutUs__our-drive">
      <div class="RelaxifyAboutUs__our-drive--label"> {{ aboutUsOurDriveData.partName }}</div>
      <h2 class="RelaxifyAboutUs__our-drive--title title "> {{ aboutUsOurDriveData.title }} </h2>
      <div class="RelaxifyAboutUs__our-drive__descriptions">
        <div class="RelaxifyAboutUs__our-drive--description description">
          {{ aboutUsOurDriveData.description }}
        </div>

        <div class="RelaxifyAboutUs__our-drive__description__labels">
          <div class="RelaxifyAboutUs__our-drive--second-description description">
            {{ aboutUsOurDriveData.secondDescriptionFirstPart }}
          </div>

          <div class="RelaxifyAboutUs__our-drive--second-description description">
            {{ aboutUsOurDriveData.secondDescriptionSecondPart }}
          </div>
        </div>
      </div>

      <a v-if="currentWindowWidth < 1024" href="https://onelink.to/x3rfwb">
        <RelaxifyButton color="primary" class="RelaxifyAboutTheApp__try-for-free-button">
          {{ aboutUsOurDriveData.buttonTitle }}
        </RelaxifyButton>
      </a>
      <RelaxifyButton v-else @click="goToFooterContacts('relaxifyFooter')" color="primary"
                      class="RelaxifyAboutTheApp__try-for-free-button">
        {{ aboutUsOurDriveData.buttonTitle }}
      </RelaxifyButton>

    </div>

    <div class="RelaxifyAboutUs__together">
      <div class="RelaxifyAboutUs__together__label"> {{ aboutUsTogetherData.partName }}</div>
      <h2 class="RelaxifyAboutUs__together__title"> {{ aboutUsTogetherData.title }} </h2>
      <div class="RelaxifyAboutUs__together__description"> {{ aboutUsTogetherData.description }}</div>
      <div class="RelaxifyAboutUs__together__elements">
        <div v-for="(element, index) in togetherElements" :key="index" class="RelaxifyAboutUs__together__element">
          <img loading="lazy" :src="element.image" :alt="`Element image-${index}`"/>
          <div class="RelaxifyAboutUs__together__element___text">
            <div class="RelaxifyAboutUs__together__element___text____title"> {{ element.title }}</div>
            <div class="RelaxifyAboutUs__together__element___text____description"> {{ element.description }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="RelaxifyAboutUs__team">
      <div class="RelaxifyAboutUs__team__label"> {{ aboutUsTeamMembers.partName }}</div>
      <h2 class="RelaxifyAboutUs__team__title"> {{ aboutUsTeamMembers.title }} </h2>
      <div class="RelaxifyAboutUs__team__description"> {{ aboutUsTeamMembers.description }}</div>
      <div class="RelaxifyAboutUs__team__founding_team">
        <div class="RelaxifyAboutUs__team__founding_team__title">
          <img loading="lazy" alt="Yellow dot" :src="foundingTeamIcon"/>
          <div class="RelaxifyAboutUs__team__founding_team__label"> {{ foundingTeamTitle }}</div>
        </div>
        <div class="RelaxifyAboutUs__team__founding_team__members">
          <div class="RelaxifyAboutUs__team__founding_team__member" v-for="(member, index) in foundingTeam"
               :key="index">
            <img loading="lazy" class="mobile_avatar" :src="member.image_mobile" alt="Team member"/>
            <img loading="lazy" class="desktop_avatar" :src="member.image_desktop" alt="Team member"/>
            <div class="RelaxifyAboutUs__team__founding_team__member__name"> {{ member.name }}</div>
            <div class="RelaxifyAboutUs__team__founding_team__member__role"> {{ member.role }}</div>
            <div class="RelaxifyAboutUs__team__founding_team__member__description"> {{ member.description }}</div>
          </div>
        </div>
      </div>

      <div class="RelaxifyAboutUs__team__psychology_team">
        <div class="RelaxifyAboutUs__team__psychology_team__title">
          <img loading="lazy" alt="Yellow dot" :src="psychologyTeamIcon"/>
          <div class="RelaxifyAboutUs__team__psychology_team__label"> {{ psychologyTeamTitle }}</div>
        </div>
        <div class="RelaxifyAboutUs__team__psychology_team__members">
          <div class="RelaxifyAboutUs__team__psychology_team__member" v-for="(member, index) in psychologyTeam"
               :key="index">
            <img loading="lazy" class="mobile_avatar" :src="member.image" alt="Team member"/>
            <img loading="lazy" class="desktop_avatar" :src="member.image_desktop" alt="Team member"/>
            <div class="RelaxifyAboutUs__team__psychology_team__member__name"> {{ member.name }}</div>
            <div class="RelaxifyAboutUs__team__psychology_team__member__role"> {{ member.role }}</div>
            <div class="RelaxifyAboutUs__team__founding_team__member__description"> {{ member.description }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="RelaxifyAboutUs__contactUs" id="getInTouch">
      <div class="RelaxifyAboutUs__contactUs__labels">
        <div class="RelaxifyAboutUs__contactUs__label"> {{ aboutUsContactUs.partName }}</div>
        <h2 class="RelaxifyAboutUs__contactUs__title"> {{ aboutUsContactUs.title }} </h2>
        <div class="RelaxifyAboutUs__contactUs__description"> {{ aboutUsContactUs.description }}</div>
      </div>
      <RelaxifyContactUsForm
          :form-button-title="formButtonTitle"
          :is-the-form-on-about-us=true
          :form-data="formFields"

      />
    </div>
    <div>
    <div> Some new text here </div>
    </div>
    <RelaxifyFooter/>
  </div>
</template>

<script>
import RelaxifyHeader from "@/components/RelaxifyHeader";
import RelaxifyFooter from "@/components/RelaxifyFooter";
import {
  getAboutUsPageData, getAboutUsFirstBoxData,
  getAboutUsOurDriveData, getAboutUsTogetherData, getAboutUsTeamMembers, getAboutUsContactUs
} from "@/getters/aboutUsGetters";
import { goToFooterContacts, goToGetInTouchForm } from "@/helpers/helpers";
import resources from "@/resources";
import RelaxifyButton from "@/components/RelaxifyButton";
import RelaxifyContactUsForm from "@/components/RelaxifyContactUsForm";
import { computed, onMounted } from "vue";



export default {
  name: "RelaxifyAboutUs.vue",
  components: {RelaxifyContactUsForm, RelaxifyButton, RelaxifyFooter, RelaxifyHeader},
  metaInfo() {
    return {
      title: 'Fight Depression, Anxiety, PTS, and Burnout Worldwide| RelaxifyApp',
      meta: [
        {
          name: 'description',
          content: 'We want to help people achieve better emotional, social, cognitive, and physical performance through inner peace and balance. And we want to make it as fun and engaging as it could be!'
        },
        {
          name: 'robots',
          content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
        },
        {
          property: 'fb:app_id',
          content: '833032110773133'
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:title',
          content: 'Fight Depression, Anxiety, PTS, and Burnout Worldwide| RelaxifyApp'
        },
        {
          property: 'og:site_name',
          content: 'RelaxifyApp'
        }
      ],
      link: [
        {rel: 'canonical', href: window.location.href}
      ]
    }
  },
  setup() {
    const currentWindowWidth = computed(() => window.innerWidth);
    const aboutUsPageData = getAboutUsPageData(resources);
    const aboutUsPageFirstBoxData = getAboutUsFirstBoxData(aboutUsPageData);
    const aboutUsOurDriveData = getAboutUsOurDriveData(aboutUsPageData);
    const aboutUsTogetherData = getAboutUsTogetherData(aboutUsPageData)
    const aboutUsTeamMembers = getAboutUsTeamMembers(aboutUsPageData);
    const aboutUsContactUs = getAboutUsContactUs(aboutUsPageData)
    const formFields = aboutUsContactUs.form;
    const formButtonTitle = aboutUsContactUs.submitButtonTitle;
    const investors = '/corporate/';

    onMounted(() => {
      const redirectedFrom = sessionStorage.getItem('previousRoute');
        if (redirectedFrom === investors) {
            goToGetInTouchForm('getInTouch');
        }
    });

    return {
      aboutUsPageData,
      aboutUsPageFirstBoxData,
      aboutUsOurDriveData,
      aboutUsTogetherData,
      togetherElements: aboutUsTogetherData.elements,
      aboutUsTeamMembers,
      foundingTeam: aboutUsTeamMembers.foundingTeam.members,
      foundingTeamTitle: aboutUsTeamMembers.foundingTeam.title,
      foundingTeamIcon: aboutUsTeamMembers.foundingTeam.icon,
      psychologyTeam: aboutUsTeamMembers.psychologyTeam.members,
      psychologyTeamTitle: aboutUsTeamMembers.psychologyTeam.title,
      psychologyTeamIcon: aboutUsTeamMembers.psychologyTeam.icon,
      aboutUsContactUs,
      formFields,
      formButtonTitle,
      goToFooterContacts,
      currentWindowWidth,
    }
  }
}
</script>

<style lang="scss">
.RelaxifyAboutUs {

  a {
    text-decoration: none;
  }

  .RelaxifyButton {
    button {
      color: #193566 !important;
    }
  }

  .title {
    color: white;
    font-size: 32px;
    margin: 0 20px;
    padding-top: 70px;
    font-weight: 700;
    line-height: 120%;
    max-width: 280px;
  }

  .description {
    color: white;
    font-size: 18px;
    margin: 45px 20px 50px 20px;
    font-weight: 400;
    line-height: 150%;
    max-width: 370px;
  }

  &__first-part {
    @media (min-width: 1440px) {
      display: flex;
      justify-content: space-evenly;
      height: 688px;
      padding-top: 80px;
    }

    &__labels {

    }

    background: #5358C2;

    img {
      padding-bottom: 50px;
    }

    .RelaxifyAboutUs__first-box--image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__first-box--image-wrapper {
    @media (min-width: 1440px) {
      img {
        width: 531px;
        height: 450px;
      }
    }
  }

  .RelaxifyAboutUs__first-box.title {
    @media (min-width: 1440px) {
      font-size: 56px;
      line-height: 110%;
      max-width: 615px;
    }
  }

  .RelaxifyAboutUs__first-box.description,
  .RelaxifyAboutUs__first-box-description.description {
    @media (min-width: 1440px) {
      font-size: 20px;
      line-height: 150%;
      max-width: 585px;
    }
  }

  &__our-drive {
    @media (min-width: 1440px) {
      padding: 100px 0 80px 0;
      display: flex;
      flex-direction: column;
    }

    padding-top: 60px;
    background: white;

    &--label {
      @media (max-width: 1023px) {
        text-align: center;
      }

      @media (min-width: 1440px) {
        text-align: center;
        font-size: 20px;
        line-height: 22px;
      }
      color: #718EC1;
      margin: 0 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
    }

    &--title {
      @media (max-width: 1023px) {
        margin: 20px auto 0 auto !important;
        text-align: center;
        padding: unset !important;
      }

      @media (min-width: 1440px) {
        text-align: center !important;
        font-size: 48px !important;
        line-height: 22px !important;
        max-width: unset !important;
        margin-top: 15px !important;
      }

      color: #193566 !important;
      max-width: 240px !important;
      padding-top: 15px !important;
    }

    &__descriptions {
      @media (min-width: 1440px) {
        display: flex;
        justify-content: center;
        margin-top: 100px;
      }
    }

    &--description {

      @media (max-width: 1023px) {
        font-size: 22px !important;
        font-weight: 600 !important;
      }

      @media (min-width: 1440px) {
        font-size: 32px !important;
        line-height: 130% !important;
        max-width: 481px !important;
        margin-top: unset !important;
      }

      color: #193566 !important;
      font-weight: 600 !important;
      max-width: 255px !important;
      margin-top: 35px !important;
      margin-bottom: 40px !important;
    }

    &--second-description {
      @media (min-width: 1440px) {
        min-width: 585px !important;
        margin-left: 60px !important;
      }

      color: #193566 !important;
      font-size: 20px !important;
      margin-top: unset !important;
      margin-bottom: 30px !important;
    }


    .RelaxifyButton {
      @media (min-width: 1440px) {
        display: flex;
        margin: 25px auto;
      }
      margin: 0px 20px 55px 20px;

      button {
        @media (min-width: 1440px) {
          width: 366px;
          height: 56px;
          color: #193566 !important;
        }
        width: 100%;
        height: 56px !important;
      }
    }
  }


  &__together {
    @media (min-width: 1440px) {
      padding-top: 120px;
    }

    background: #ECF0F3;
    padding: 70px 30px;

    &__label {
      @media (min-width: 1440px) {
        text-align: center;
        font-size: 20px;
        line-height: 22px;
      }

      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #5358C2;
      letter-spacing: 0.14em;
    }

    &__title {
      @media (min-width: 1440px) {
        text-align: center !important;
        font-size: 48px !important;
        line-height: 22px !important;
        max-width: unset !important;
        margin-top: 30px !important;
      }

      text-align: center;
      font-size: 32px;
      font-weight: 700;
      color: #193566;
      margin: 20px 0 35px 0;
    }

    &__description {
      @media (min-width: 1440px) {
        font-size: 20px !important;
        line-height: 30px !important;
        max-width: 481px !important;
        margin: 40px auto 100px auto !important;
      }

      color: #193566 !important;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      margin: 0 20px 70px 20px;
    }

    &__elements {
      @media (min-width: 1440px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        margin-top: 20px;
      }

      margin: 0 20px;
    }

    &__element {
      @media (min-width: 1440px) {
        flex: 33%;
        flex-direction: column;
        max-width: 390px;
        margin-bottom: 80px;
      }

      @media (min-width: 1700px) {
        max-width: 470px;
      }

      @media (min-width: 1980px) {
        max-width: 500px;
      }

      display: flex;
      margin-bottom: 30px;
      align-items: self-start;

      &___text {
        @media (min-width: 1440px) {
          margin-left: unset;
        }

        margin-left: 30px;

        &____title {
          @media (min-width: 1440px) {
            margin-top: 20px;
          }

          font-size: 24px;
          line-height: 31.25px;
          font-weight: 500;
          color: #193566;
          font-style: normal;
          margin-bottom: 15px;
        }

        &____description {
          @media (min-width: 1440px) {
            max-width: 225px;
          }

          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #193566;
        }
      }
    }
  }

  &__team {
    @media (min-width: 1440px) {
      margin: 50px 223px;
    }

    padding: 70px 0;
    margin: 0 20px;

    &__label {
      @media (max-width: 1023px) {
        text-align: center;
      }

      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 22px;
      }

      font-weight: 400;
      font-size: 14px;
      color: #5358C2;
      letter-spacing: 0.14em;
    }

    &__title {

      @media (max-width: 1023px) {
        margin: 20px auto 25px auto !important;
        text-align: center;
      }

      @media (min-width: 1440px) {
        font-size: 48px !important;
        line-height: 22px !important;
        max-width: unset !important;
        margin-top: 30px !important;
      }

      font-size: 32px;
      font-weight: 700;
      color: #193566;
      margin: 20px 0 30px 0;
      max-width: 235px;
    }

    &__description {
      @media (max-width: 1023px) {
      margin: 0 auto 35px auto !important;
      }

      @media (min-width: 1440px) {
        font-size: 20px !important;
        line-height: 30px !important;
        max-width: 651px !important;
        margin-bottom: 80px;
      }

      color: #193566 !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      max-width: 330px;
      margin-bottom: 35px;
    }

    &__founding_team,
    &__psychology_team {
      margin-bottom: 40px;

      &__title {
        @media (min-width: 1440px) {
          margin-bottom: 50px;
        }

        display: flex;
        align-items: center;
        margin-bottom: 30px;
      }

      &__label {
        margin-left: 15px;
        font-weight: 500;
        font-size: 24px;
        line-height: 31.25px;
        color: #193566;
      }

      &__members {
        display: flex;
      }

      &__member {
        &__description {
          display: none;
        }

        @media (min-width: 1440px) {
          max-width: unset;
          img {
            width: 343px;
            height: 435px;
            margin-right: 40px;
          }

          &__description {
            display: block;
            margin-top: 20px;
            max-width: 305px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #193566;
          }
        }

        max-width: 110px;
        margin-right: 20px;

        &__name {
          @media (min-width: 1440px) {
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
          }

          font-weight: 550;
          font-size: 13px;
          line-height: 17px;
          color: #193566;
          margin-top: 8px;
        }

        &__role {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #193566;
          margin-top: 5px;
        }
      }
    }
  }

  &__contactUs {
    @media (min-width: 1440px) {
      padding: 130px 223px;
      display: flex;
    }

    &__labels {
      @media (max-width: 1023px) {
        text-align: center;
      }

      @media (min-width: 1440px) {
        margin-right: 115px;
      }
      @media (min-width: 1700px) {
        margin-right: 200px;
      }
    }

    background: #ECF0F3;
    padding: 70px 20px;

    &__label {
      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 22px;
      }

      font-weight: 400;
      font-size: 14px;
      color: #5358C2;
      letter-spacing: 0.14em;
    }

    &__title {
      @media (min-width: 1440px) {
        font-size: 48px !important;
        max-width: 376px !important;
        margin-top: 30px !important;
      }

      font-size: 32px;
      font-weight: 700;
      color: #193566;
      margin: 20px 0 30px 0;
    }

    &__description {
      display: none;
      color: #193566 !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      max-width: 330px;
      margin-bottom: 35px;

      @media (min-width: 1440px) {
        display: block;
        font-size: 20px !important;
        line-height: 30px !important;
        max-width: 414px !important;
        margin-bottom: 80px;
      }

      @media (min-width: 1770px) {
        max-width: 410px !important;
      }
    }
  }

  .mobile_avatar {
    @media (min-width: 1440px) {
      display: none;
    }
  }

  .desktop_avatar {
    @media (min-width: 1440px) {
      display: block;
    }

    display: none;
  }
}

</style>
