import RelaxifyHome from "@/components/RelaxifyHome";
// import RelaxifyBeHappyPage from "@/components/RelaxifyBeHappyPage";
import RelaxifyNotFound from "@/components/RelaxifyNotFound";
import RelaxifyAboutTheApp from "@/components/RelaxifyAboutTheApp";
import RelaxifyAboutUs from "@/components/RelaxifyAboutUs";
import RelaxifyCorporate from "@/components/RelaxifyCorporate";
import RelaxifyJoinTheCommunity from "@/components/RelaxifyJoinTheCommunity";
import RelaxifyAwards from "@/components/RelaxifyAwards.vue";
// import TestPage from "@/components/TestPage.vue";


export default [
  {
    path: '/',
    component: RelaxifyHome,
    name: 'home',
  },
  // {
  //     path: '/be-happy/',
  //     component: RelaxifyBeHappyPage,
  //     name: 'beHappy'
  // },
  {
    path: '/about-the-app/',
    component: RelaxifyAboutTheApp,
    name: 'TheApp'
  },

  // { fill the details about the new page }

  {
    path: '/about-us/',
    // pathToRegexpOptions: { strict: true },
    component: RelaxifyAboutUs,
    name: 'aboutUs',
  },
  {
    path: '/corporate/',
    component: RelaxifyCorporate,
    name: 'corporate',
  },
  {
    path: '/corporate/investors',
    beforeEnter: (to, from, next) => {
      next('/corporate');

      setTimeout(() => {
        const element = document.getElementById('investors');
        if (element) {
          element.scrollIntoView({behavior: 'smooth'});
        }
      }, 100);
    }
  },
  {
    path: '/awards/',
    component: RelaxifyAwards,
    name: 'awardsPage',
  },
  {
    path: '/join-the-community/',
    component: RelaxifyJoinTheCommunity,
    name: 'joinTheCommunity',
  },

  {
    path: '/#/awards/',
    redirect: '/awards/',
  },
  {
    path: '/#awards/',
    redirect: '/awards/',
  },


  {
    path: '/*',
    component: RelaxifyNotFound
  }
]
