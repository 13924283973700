// Constant titles
const awardsPage = 'awards';
const secondPart = 'secondPart';
const thirdPart = 'thirdPart';
const fourthPart = 'fourthPart';
const fifthPart = 'fifthPart';
const sixthPart = 'sixthPart';
const lastElement = 'lastElement';
const WORK_LIFE_BALANCE_AWARDS = "WORK-LIFE BALANCE AWARDS";
// const ourDrive = '';
// const together = 'TOGETHER';
// const teamMembers = '';
// const getInTouch = '';
// Getters

export const getAwardsPageData = (pages) => pages.find(item => item.page.name === awardsPage);
export const getAwardsPageFirstPartTitle  = (content) => content.page.parts.find(item => item.partName === WORK_LIFE_BALANCE_AWARDS);
export const getAwardsPageSecondPart = (content) => content.page.parts.find(item => item.partName === secondPart);
export const getAwardsPageThirdPart = (content) => content.page.parts.find(item => item.partName === thirdPart);
export const getAwardsPageFourthPart = (content) => content.page.parts.find(item => item.partName === fourthPart);
export const getAwardsPageFifthPart = (content) => content.page.parts.find(item => item.partName === fifthPart);
export const getAwardsPageSixthPart = (content) => content.page.parts.find(item => item.partName === sixthPart);
export const getAwardsPageLastPart = (content) => content.page.parts.find(item => item.partName === lastElement);
const aboutUsGetters = {
  getAwardsPageData,
  getAwardsPageFirstPartTitle,
  getAwardsPageSecondPart,
  getAwardsPageThirdPart,
  getAwardsPageFourthPart,
  getAwardsPageFifthPart,
  getAwardsPageSixthPart
}

export default aboutUsGetters;
