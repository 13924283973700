<template>
  <div class="RelaxifyButton">
    <v-btn
        v-on="$listeners"
        v-bind="$attrs"
        v-ripple="false"
        :class="color"
    >
      <slot></slot>
    </v-btn>
  </div>
</template>
<script>

export default {
  name: 'RelaxifyButton',
  inheritAttrs: false,
  props: ['color'],
};
</script>

<style lang="scss">
.RelaxifyButton {

  .v-btn {
    font-size: 18px !important;
    font-weight: 500;
    letter-spacing: 0.005em;
    text-transform: none !important;
    line-height: 23px;
    padding: 13px 16px !important;
    border-radius: 5px;
    color: white !important;
    box-shadow: none;
    opacity: 1 !important;
    height: 40px !important;
    filter: contrast(1) !important;
    min-width: 165px !important;

  &::before {
      display: none;
    }

    &.primary {
      background: #FFC726 !important;
    }
  }
}
</style>
