// Constant titles
const about_the_app = 'about-the-app';
const start_page = 'start page';
const methodology = 'METHODOLOGY';
const science = 'THE SCIENCE';
const gamification = 'GAMIFICATION';

// Getters

export const getAboutTheAppData = (pages) => pages.find(item => item.page.name === about_the_app);
export const getAboutTheAppStartPageData  = (content) => content.page.parts.find(item => item.partName === start_page);
export const getAboutTheAppStartPageTitle = (content) => content.title;
export const getAboutTheAppStartPageFirstDesktopDescription  = (content) => content.desktop_description_first_part;
export const getAboutTheAppStartPageSecondDesktopDescription  = (content) => content.desktop_description_second_part;
export const getAboutTheAppStartPageMobileDescription = (content) => content.mobile_description;
export const getAboutTheAppMethodologyData  = (content) => content.page.parts.find(item => item.partName === methodology);
export const getAboutTheAppMethodologyPartName = (content) => content.partName;
export const getAboutTheAppMethodologyTitle = (content) => content.title;
export const getAboutTheAppMethodologyDescription = (content) => content.description;
export const getAboutTheAppMethodologyDesktopBackground  = (content) => content.backgroundImageDesktop;
export const getAboutTheAppMethodologyItems  = (content) => content.methodologies;
export const getAboutTheAppSciencePartData  = (content) => content.page.parts.find(item => item.partName === science);
export const getAboutTheAppSciencePartDataPartName  = (content) => content.partName;
export const getAboutTheAppSciencePartDataTitle  = (content) => content.title;
export const getAboutTheAppSciencePartDataDescription  = (content) => content.description;
export const getAboutTheAppSciencePartDataSecondDescription  = (content) => content.secondDescription;
export const getAboutTheAppSciencePartDataWellBeingElements  = (content) => content.wellBeingElements;
export const getAboutTheAppSciencePartDataButtonTitle  = (content) => content.buttonTitle;
export const getAboutTheAppGamificationData  = (content) => content.page.parts.find(item => item.partName === gamification);
export const getAboutTheAppGamificationPartName  = (content) => content.partName;
export const getAboutTheAppGamificationTitle = (content) => content.title;
export const getAboutTheAppGamificationDescription = (content) => content.description;
export const getAboutTheAppGamificationImage = (content) => content.image;
export const getAboutTheAppGamificationCircleImage = (content) => content.circle_image;
export const getAboutTheAppGamificationElements = (content) => content.elements;
export const getAboutTheAppGamificationButtonTitle = (content) => content.buttonTitle;


const aboutUsGetters = {
    getAboutTheAppData,
    getAboutTheAppStartPageData,
    getAboutTheAppStartPageTitle,
    getAboutTheAppStartPageFirstDesktopDescription,
    getAboutTheAppStartPageSecondDesktopDescription,
    getAboutTheAppStartPageMobileDescription,
    getAboutTheAppMethodologyData,
    getAboutTheAppMethodologyPartName,
    getAboutTheAppMethodologyTitle,
    getAboutTheAppMethodologyDescription,
    getAboutTheAppMethodologyDesktopBackground,
    getAboutTheAppMethodologyItems,
    getAboutTheAppSciencePartData,
    getAboutTheAppSciencePartDataPartName,
    getAboutTheAppSciencePartDataTitle,
    getAboutTheAppSciencePartDataDescription,
    getAboutTheAppSciencePartDataSecondDescription,
    getAboutTheAppSciencePartDataWellBeingElements,
    getAboutTheAppSciencePartDataButtonTitle,
    getAboutTheAppGamificationData,
    getAboutTheAppGamificationPartName,
    getAboutTheAppGamificationTitle,
    getAboutTheAppGamificationDescription,
    getAboutTheAppGamificationImage,
    getAboutTheAppGamificationCircleImage,
    getAboutTheAppGamificationElements,
    getAboutTheAppGamificationButtonTitle
}

export default aboutUsGetters;
