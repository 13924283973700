<template>
  <div>
    <RelaxifyHeader/>
    <div class="RelaxifyHome">
      <div class="RelaxifyHome__start__page">
        <div class="RelaxifyHome__start__page__mobile">

          <h1 class="RelaxifyHome__background-image--title "> {{ homePageStartPartTitle }} </h1>

          <div class="RelaxifyHome__start__page__mobile__image__wrapper">
            <img loading="lazy" class="RelaxifyHome__background-image" alt="Background image"
                 @click="goToFooterContacts('relaxifyFooter')"
                 :src="homePageStartPartBackgroundMobile"/>
          </div>

          <div class="RelaxifyHome__background-image--description"> {{ homePageStartPartDescription }}</div>

          <!--          // Remove contact sales button -->

          <!--          <a href="https://onelink.to/x3rfwb">-->
          <!--            <RelaxifyButton color="primary" class="RelaxifyHome__try-for-free-button"> {{-->
          <!--                homePageStartPartButtonTitle-->
          <!--              }}-->
          <!--            </RelaxifyButton>-->
          <!--          </a>-->
        </div>
        <div class="RelaxifyHome__start__page__desktop">
          <div class="RelaxifyHome__start__page__desktop__left__side">
            <h1 class="RelaxifyHome__background-image--title "> {{ homePageStartPartTitle }} </h1>
            <div class="RelaxifyHome__start__page__desktop__circle__and__desc">
              <img loading="lazy" :src="yellowCircle" alt="first-yellow-image"/>
              <div class="RelaxifyHome__background-image--description"> {{ homePageStartPartDescription }}</div>
            </div>
            <!--            <RelaxifyButton color="primary" @click="goToFooterContacts('relaxifyFooter')"-->
            <!--                            class="RelaxifyHome__try-for-free-button">-->
            <!--              {{ homePageStartPartButtonTitle }}-->
            <!--            </RelaxifyButton>-->
            <!--            <img class="RelaxifyHome__start__page__desktop__first__yellow" loading="lazy" :src="yellowCircle"-->
            <!--                 alt="first background"/>-->
          </div>
          <div class="RelaxifyHome__start__page__desktop__right__side">
            <img class="decorative-image" :src="homePageStartPartBackground" loading="lazy"
                 alt="first-background-image"/>
            <img class="main-image" :src="homePageStartPartSecondBackground" loading="lazy"
                 alt="second-background-image"/>
            <img class="first-white-dot" :src="yellowCircleWhiteDot" loading="lazy"
                 alt="second-background-image"/>
            <img class="second-white-dot" :src="yellowCircleWhiteDot" loading="lazy"
                 alt="third-background-image"/>
          </div>


          <!--          Old version-->

          <!--          <div class="RelaxifyHome__start__page__desktop__image__wrapper">-->
          <!--            <img loading="lazy" :src="homePageStartPartBackground" @click="goToFooterContacts('relaxifyFooter')"-->
          <!--                 alt="Background image"/>-->
          <!--          </div>-->
        </div>
      </div>

      <!--      //HR Solutions-->
      <div class="RelaxifyHome__hr__solutions">
        <img class="RelaxifyHome__hr__solutions__yellow__dot" :src="yellowCircle" loading="lazy"
             alt="third-background-image"/>
        <div class="RelaxifyHome__hr__solutions__labels">
          <div class="RelaxifyHome__hr__solutions page-part-name">
            <p class="mobile"> {{ hrSolutionMobileLabel }} </p>
            <p class="desktop"> {{ hrSolutionDesktopLabel }} </p>
          </div>
          <div class="RelaxifyHome__hr__solutions__elements__container">
            <div v-for="(element, index) in hrSolutionElements" :key="index"
                 class="RelaxifyHome__hr__solutions__element">
              <div class="header">
                <img loading="lazy" :src="element.image" :alt="element.name"/>
                <h3 class="RelaxifyHome__hr__solutions__element__title">{{ element.title }}</h3>
              </div>
              <div class="RelaxifyHome__hr__solutions__element__image__and__text">
                <h3 class="RelaxifyHome__hr__solutions__element__title">{{ element.title }}</h3>
                <p class="RelaxifyHome__hr__solutions__element__description">
                  {{ element.description }}
                </p>
              </div>
            </div>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5R_GrrcJyNgrFOlZmpnIyrqLrDXo43C_jQTlXLdW_RnwzIw/viewform">
              <RelaxifyButton color="primary">
                {{ hrSolutionButtonTitle }}
              </RelaxifyButton>
            </a>
          </div>
        </div>
      </div>

      <div class="RelaxifyHome__benefits">
        <img class="RelaxifyHome__benefits__yellow__dot" :src="yellowCircle" loading="lazy"
             alt="fourth-background-image"/>
        <div class="RelaxifyHome__benefits__information__labels">
          <div class="RelaxifyHome__benefits page-part-name"> {{ benefitsName }}</div>
          <h2 class="RelaxifyHome__benefits__title"> {{ benefitsTitle }} </h2>
        </div>
        <div class="RelaxifyHome__benefits--items">
          <div class="RelaxifyHome__benefits--item" v-for="(item, index) in benefitsItems" v-bind:key="index">
            <div class="RelaxifyHome__benefits--image-and-title">
              <div class="benefitImage"><img loading="lazy" :src="item.image" :alt="item.title"/></div>

              <span class="RelaxifyHome__benefits--item-title"> {{ item.title }} </span>
            </div>
            <div class="RelaxifyHome__benefits__subtitle" v-for="(subtitle, index) in item.subElements"
                 v-bind:key="index">
              <img loading="lazy" :src="yellowCircle" :alt="item.title"/>
              <div class="RelaxifyHome__benefits__subtitle__text"> {{ subtitle }}</div>
            </div>
          </div>
        </div>

        <div class="RelaxifyHome__benefits__second__part">
          <div class="RelaxifyHome__benefits__second__part__item"
               v-for="(item, index) in benefitsSecondPartElements" v-bind:key="index">
            <div class="RelaxifyHome__benefits__second__part__item__title" v-html="item.title"/>
            <div class="RelaxifyHome__benefits__second__part__item__desc" v-html="item.desc"/>
            <div class="RelaxifyHome__benefits__second__part__item__elements" v-for="(item, index) in item.elements"
                 :key="index">
              <div class="RelaxifyHome__benefits__second__part__item__element">
                <img loading="lazy" :src="yellowCircle" :alt="item.title"/>
                <div class="RelaxifyHome__benefits__second__part__item__element__title" v-html="item"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--          ROADMAP-->
      <div class="RelaxifyHome__roadmap">
        <img class="RelaxifyHome__roadmap__yellow__dot" :src="yellowCircle" loading="lazy"
             alt="fifth-background-image"/>
        <div class="RelaxifyHome__roadmap page-part-name"> {{ roadMapData.partName }}</div>
        <div class="RelaxifyHome__roadmap__elements">
          <div class="RelaxifyHome__roadmap__element" v-for="(item, index) in roadMapElements"
               v-bind:key="index">
            <div class="RelaxifyHome__roadmap__element__circle"> {{ index + 1 }}</div>
            <div class="RelaxifyHome__roadmap__element__title"> {{ item }}</div>
          </div>
        </div>
      </div>
      <!--              HR BI -->
      <div class="RelaxifyHome__hrbidashboard">
        <img class="RelaxifyHome__hrbidashboard__yellow__dot" :src="yellowCircle" loading="lazy"
             alt="hrdasboard-yellow-dot"/>
        <div class="RelaxifyHome__hrbidashboard__title__and__logo">
          <img loading="lazy" :src="hrBiDashboardTitleImage" :alt="hrBiDashboardElements"/>
          <div class="RelaxifyHome__hrbidashboard__title"> {{ hrBiDashboardLabel }}</div>
        </div>

        <div class="RelaxifyHome__hrbidashboard__text__and__elements">
          <div class="RelaxifyHome__hrbidashboard__text__and__elements__left__side">
            <div class="RelaxifyHome__hrbidashboard__desc"> {{ hrBiDashboardDesc }}</div>
            <div class="RelaxifyHome__hrbidashboard__second__desc">
              <img class="yellow-cirlce" loading="lazy" :src="yellowCircle" :alt="hrBiDashboardSecondDesc"/>
              <div class="RelaxifyHome__hrbidashboard__second__desc__text" v-html="hrBiDashboardSecondDesc"/>
            </div>
            <div class="RelaxifyHome__hrbidashboard__data__sources__elements">
              <div class="RelaxifyHome__hrbidashboard__data__sources__element"
                   v-for="(item, index) in hrBiDashboardDataSourcesLogos" v-bind:key="index">
                <img loading="lazy" :src="item.image" :alt="item.alt"/>
              </div>
            </div>
          </div>
          <img class="RelaxifyHome__hrbidashboard__mobile__image" loading="lazy" :src="hrBiDashboardMobileImage"
               alt="Mobile image"/>
          <div class="RelaxifyHome__hrbidashboard__text__and__elements__right__side">
            <img class="RelaxifyHome__hrbidashboard__desktop__image" loading="lazy" :src="hrBiDashboardDesktopImage"
                 alt="Desktop image"/>
          </div>
        </div>


        <div class="RelaxifyHome__hrbidashboard__text__and__elements">
          <div class="RelaxifyHome__hrbidashboard__elements__container">
            <div class="RelaxifyHome__hrbidashboard__elements" v-for="(item, index) in hrBiDashboardElements"
                 v-bind:key="index">
              <div class="RelaxifyHome__hrbidashboard__element">
                <img class="yellow-cirlce" loading="lazy" :src="yellowCircle" :alt="hrBiDashboardSecondDesc"/>
                <div class="RelaxifyHome__hrbidashboard__element__text" v-html="item"/>
              </div>
            </div>
            <a class="RelaxifyHome__hrbidashboard__elements__desktop__button"
               href="https://docs.google.com/forms/d/e/1FAIpQLSc5R_GrrcJyNgrFOlZmpnIyrqLrDXo43C_jQTlXLdW_RnwzIw/viewform">
              <RelaxifyButton color="primary">
                {{ hrBiDashboardButtonTitle }}
              </RelaxifyButton>
            </a>
          </div>
          <img class="RelaxifyHome__hrbidashboard__mobile__image" loading="lazy"
               :src="hrBiDashboardSecondMobileImage"
               alt="Mobile image"/>
          <img class="RelaxifyHome__hrbidashboard__desktop__image" loading="lazy"
               :src="hrBiDashboardDesktopSecondImage"
               alt="Mobile image"/>
          <a class="RelaxifyHome__hrbidashboard__elements__mobile__button"
             href="https://docs.google.com/forms/d/e/1FAIpQLSc5R_GrrcJyNgrFOlZmpnIyrqLrDXo43C_jQTlXLdW_RnwzIw/viewform">
            <RelaxifyButton color="primary">
              {{ hrBiDashboardButtonTitle }}
            </RelaxifyButton>
          </a>
        </div>
      </div>

      <!--          Employee Survey-->

      <div class="RelaxifyHome__employee__survey">
        <img class="RelaxifyHome__employee__survey__yellow__dot" :src="yellowCircle" loading="lazy"
             alt="hrdasboard-yellow-dot"/>
        <div class="RelaxifyHome__employee__survey__title__and__logo">
          <img loading="lazy" :src="employeeSurveyTitleLogo" :alt="employeeSurveyLabel"/>
          <div class="RelaxifyHome__employee__survey__title"> {{ employeeSurveyLabel }}</div>
          <!--              employee__survey-->
        </div>
        <div class="RelaxifyHome__employee__survey__second__desc"> {{ employeeSurveyDesc }}</div>

        <div class="RelaxifyHome__employee__survey__elements__container">
          <div v-for="(element, index) in employeeSurveyBusinessElements" :key="index"
               class="RelaxifyHome__employee__survey__element">
            <div class="header">
              <img loading="lazy" :src="element.image" :alt="element.name"/>
              <h3 class="RelaxifyHome__employee__survey__element__title">{{ element.title }}</h3>
            </div>
            <div class="RelaxifyHome__hemployee__survey__element__image__and__text">
              <p class="RelaxifyHome__employee__survey__element__description">
                {{ element.desc }}
              </p>
            </div>
          </div>
        </div>

        <div class="RelaxifyHome__employee__survey__second__desc"> {{ employeeSurveySecondDesc }}</div>
        <div class="RelaxifyHome__employee__survey__elements__container">
          <div v-for="(element, index) in employeeSurveyMentalElements" :key="index"
               class="RelaxifyHome__employee__survey__element">
            <div class="header">
              <img loading="lazy" :src="element.image" :alt="element.name"/>
              <h3 class="RelaxifyHome__employee__survey__element__title">{{ element.title }}</h3>
            </div>
            <div class="RelaxifyHome__hemployee__survey__element__image__and__text">
              <p class="RelaxifyHome__employee__survey__element__description">
                {{ element.desc }}
              </p>
            </div>
          </div>
        </div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5R_GrrcJyNgrFOlZmpnIyrqLrDXo43C_jQTlXLdW_RnwzIw/viewform">
          <RelaxifyButton color="primary">
            {{ hrBiDashboardButtonTitle }}
          </RelaxifyButton>
        </a>
      </div>

      <!--          Human Resources Consultancy -->
      <div class="RelaxifyHome__human__resources">
        <img class="RelaxifyHome__human__resources__yellow__dot" :src="yellowCircle" loading="lazy"
             alt="hrdasboard-yellow-dot"/>
        <div class="RelaxifyHome__human__resources__title__and__logo__container">
          <div class="RelaxifyHome__human__resources__title__and__logo">
            <img loading="lazy" :src="humanResourcesLogoImage" :alt="employeeSurveyLabel"/>
            <div class="RelaxifyHome__human__resources__mobile__title"> {{ humanResourcesLabel }}</div>
            <div class="RelaxifyHome__human__resources__title"> {{ humanResourcesDesktopLabel }}</div>
          </div>
          <div class="RelaxifyHome__human__resources__desc"> {{ humanResourcesDesc }}</div>
          <img class="RelaxifyHome__human__resources__main__image" loading="lazy" :src="humanResourcesMainImage"
               alt="main-image"/>
          <div class="RelaxifyHome__human__resources__second__desc"> {{ humanResourcesSecondDesc }}</div>
          <div class="RelaxifyHome__human__resources__third__desc" v-html="humanResourcesThirdDesc"/>

          <div class="RelaxifyHome__human__resources__elements__and__image">
            <div>
              <div class="RelaxifyHome__human__resources__elements" v-for="(item, index) in humanResourcesElements"
                   v-bind:key="index">
                <div class="RelaxifyHome__human__resources__element">
                  <img class="yellow-cirlce" loading="lazy" :src="yellowCircle" :alt="hrBiDashboardSecondDesc"/>
                  <div class="RelaxifyHome__human__resources__element__text" v-html="item"/>
                </div>
              </div>
              <router-link to="/about-us">
                <RelaxifyButton color="primary">
                  {{ humanResourcesButtonTitle }}
                </RelaxifyButton>
              </router-link>
            </div>
            <img class="RelaxifyHome__human__resources__main__desktop__image" loading="lazy"
                 :src="humanResourcesMainDesktopImage" alt="human resources main desktop image"/>
          </div>
        </div>
      </div>

      <!--          RelaxifyApp for your Employees-->
      <div class="RelaxifyHome__app__for__employees">
        <img class="RelaxifyHome__app__for__employees__yellow__dot" :src="yellowCircle" loading="lazy"
             alt="employees-yellow-dot"/>
        <div class="RelaxifyHome__app__for__employees__title__and__logo">
          <img loading="lazy" :src="relaxifyEmpployeeTitleLogo" :alt="relaxifyEmpployeeLabel"/>
          <div class="RelaxifyHome__app__for__employees__title"> {{ relaxifyEmpployeeLabel }}</div>
        </div>
        <div class="RelaxifyHome__app__for__employees__first__part">
          <img class="RelaxifyHome__app__for__employees__main__image first-phones" loading="lazy"
               :src="relaxifyEmpployeeFirstPhones"
               alt="main-image"/>
          <div>
            <div class="RelaxifyHome__app__for__employees__description"> {{ relaxifyEmpployeeDesc }}</div>
            <router-link class="RelaxifyHome__app__for__employees__desktop__button" to="/about-the-app">
              <RelaxifyButton color="primary">
                {{ relaxifyEmpployeeButtonTitle }}
              </RelaxifyButton>
            </router-link>
          </div>
          <img class="RelaxifyHome__app__for__employees__main__image first-phones" loading="lazy"
               :src="relaxifyEmpployeeSecondPhones"
               alt="main-image"/>
        </div>
        <router-link class="RelaxifyHome__app__for__employees__mobile__button" to="/about-the-app">
          <RelaxifyButton color="primary">
            {{ relaxifyEmpployeeButtonTitle }}
          </RelaxifyButton>
        </router-link>
        <div class="RelaxifyHome__app__for__employees__second__part">
          <div class="RelaxifyHome__app__for__employees__second__part__purple__container__and__button">
            <div class="RelaxifyHome__app__for__employees__purple__container">
              <div class="RelaxifyHome__app__for__employees__purple__container__title">
                {{ relaxifyEmpployeePurpleElementsTitle }}
              </div>
              <div class="RelaxifyHome__app__for__employees__purple__container__desc">
                {{ relaxifyEmpployeePurpleElementsDesc }}
              </div>
              <div class="RelaxifyHome__app__for__employees__purple__element"
                   v-for="(item, index) in relaxifyEmpployeePurpleElementsItems" v-bind:key="index">
                <img class="yellow-cirlce" loading="lazy" :src="yellowCircle" :alt="hrBiDashboardSecondDesc"/>
                <div class="RelaxifyHome__app__for__employees__purple__element__text" v-html="item"/>
              </div>
            </div>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5R_GrrcJyNgrFOlZmpnIyrqLrDXo43C_jQTlXLdW_RnwzIw/viewform"
               class="RelaxifyHome__app__for__employees__purple__container__desktop__button">

              <RelaxifyButton color="primary">
                {{ relaxifyEmpployeeSecondButtonTitle }}
              </RelaxifyButton>
            </a>
          </div>
          <div class="RelaxifyHome__app__for__employees__second__part__third__phones__and__stores">
            <img class="RelaxifyHome__app__for__employees__main__image" loading="lazy"
                 :src="relaxifyEmpployeeThirdPhones"
                 alt="main-image-2"/>
            <div class="RelaxifyHome__app__for__employees__second__part__stores">
              <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
                   :src="relaxifyEmpployeeGooglePLayLogo"
                   alt="google-play-logo"/>
              <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
                   :src="relaxifyEmpployeeAppStoreLogo"
                   alt="app-store-logo"/>
            </div>
          </div>
        </div>
        <img class="RelaxifyHome__app__for__employees__second__yellow__dot" :src="yellowCircle" loading="lazy"
             alt="employees-yellow-dot"/>
        <div class="RelaxifyHome__app__for__employees__logos__container">

          <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
               :src="relaxifyEmpployeerelaxifyLogo"
               alt="relaxify-store-logo"/>
          <div class="RelaxifyHome__app__for__employees__logos__container__member">
            {{ relaxifyEmpployeeMemberOfAsString }}
          </div>
          <div class="RelaxifyHome__app__for__employees__logos__container__members">
            <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
                 :src="relaxifyEmpployeeBgAssociationLogo"
                 alt="bg-assosciation-logo"/>
            <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
                 :src="relaxifyEmpployeeBescoLogo"
                 alt="besco-logo"/>
            <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
                 :src="relaxifyEmpployeeEuStartupLogo"
                 alt="eu-startup-logo"/>
          </div>
          <div class="RelaxifyHome__app__for__employees__logos__container__member">
            {{ relaxifyEmpployeeInMediaOfAsString }}
          </div>
          <div class="RelaxifyHome__app__for__employees__logos__container__members">
            <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
                 :src="relaxifyEmpployeeForbesLogo"
                 alt="forbes-logo"/>
            <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
                 :src="relaxifyEmpployeeCapitalLogo"
                 alt="capital-logo"/>
            <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
                 :src="relaxifyEmpployeeBloombergLogo"
                 alt="bloomberg-logo"/>
          </div>
          <div class="RelaxifyHome__app__for__employees__logos__container__member">
            {{ relaxifyEmpployeeAwardsAsString }}
          </div>
          <img class="RelaxifyHome__app__for__employees__logos__container__main__image" loading="lazy"
               :src="relaxifyEmpployeeWinnerLogo"
               alt="bloomberg-logo"/>
        </div>
      </div>
      <div class="RelaxifyHome__last__part">
        <img class="RelaxifyHome__last__part__yellow__dot" :src="yellowCircle" loading="lazy"
             alt="last-part-yellow-dot-image"/>
        <div class="RelaxifyHome__last__part__container">
          <div class="RelaxifyHome__last__part__container__title">
            {{ relaxifyLastPartTitle }}
          </div>
          <div class="RelaxifyHome__last__part__container__elements__and__image">
            <div class="RelaxifyHome__last__part__container__elements__wrapper">
              <div class="RelaxifyHome__last__part__container__elements"
                   v-for="(item, index) in relaxifyLastPartElements"
                   v-bind:key="index">
                <div class="RelaxifyHome__last__part__container__element">
                  <img class="yellow-cirlce" loading="lazy" :src="yellowCircle" :alt="hrBiDashboardSecondDesc"/>
                  <div class="RelaxifyHome__last__part__container__element__text"> {{ item }}</div>
                </div>
              </div>
            </div>
            <img class="RelaxifyHome__last__part__container__image" loading="lazy" :src="relaxifyLastPartImage"
                 alt="take-action-image"/>
          </div>
        </div>
      </div>

      <!--          app__for__employees-->

      <!--        <div class="RelaxifyHome__puzzle">-->
      <!--          <div class="RelaxifyHome__puzzle page-part-name"> {{ puzzleName }}</div>-->
      <!--          <h2 class="RelaxifyHome__puzzle title"> {{ puzzleTitle }}</h2>-->
      <!--          <div class="RelaxifyHome__puzzle description"> {{ puzzleDescription }}</div>-->
      <!--          <div class="RelaxifyHome__puzzle__image">-->
      <!--            <img loading="lazy" :src="puzzleImage" alt="Puzzle"/>-->
      <!--          </div>-->
      <!--          <div class="RelaxifyHome__puzzle__image__desktop">-->
      <!--            <img loading="lazy" :src="puzzleImageDesktop" alt="Puzzle"/>-->
      <!--          </div>-->
      <!--        </div>-->


      <!--          <div class="RelaxifyHome__methodology">-->
      <!--          <div class="RelaxifyHome__methodology__image__wrapper">-->
      <!--            <img loading="lazy" class="RelaxifyHome__methodology__image" :src="methodologyImage" alt="Methodology"/>-->
      <!--          </div>-->
      <!--          <div class="RelaxifyHome__methodology&#45;&#45;content">-->
      <!--            <div class="RelaxifyHome__methodology page-part-name"> {{ methodologyName }}</div>-->
      <!--            <h2 class="RelaxifyHome__methodology title"> {{ methodologyTitle }}</h2>-->
      <!--            <div class="RelaxifyHome__methodology description"> {{ methodologyDescription }}</div>-->
      <!--            <div class="RelaxifyHome__methodology description description__desktop"> {{-->
      <!--                methodologyDescriptionForDesktop-->
      <!--              }}-->
      <!--            </div>-->
      <!--            <RelaxifyButton color="primary" class="RelaxifyHome__methodology__button">-->
      <!--              <router-link to="/about-the-app/">-->
      <!--                {{ methodologyButtonTitle }}-->
      <!--              </router-link>-->
      <!--            </RelaxifyButton>-->
      <!--          </div>-->
      <!--        </div>-->


      <!--          <div class="RelaxifyHome__user-testimonials">-->
      <!--            <div class="RelaxifyHome__user-testimonials page-part-name"> {{ userTestimonialsName }}</div>-->
      <!--            <h2 class="RelaxifyHome__user-testimonials title"> {{ userTestimonialsTitle }}</h2>-->
      <!--            <div class="RelaxifyHome__user-testimonials description"> {{ userTestimonialsDescription }}</div>-->
      <!--            <div class="RelaxifyHome__carousel-list">-->
      <!--              <RelaxifyCarousel-->
      <!--                  ref="relaxifyCarousel"-->
      <!--                  :settings="carouselSettings"-->
      <!--                  :carouselLength="userTestimonialsReviews.length"-->
      <!--              >-->
      <!--                <slide v-for="(review, i) in userTestimonialsReviews"-->
      <!--                       :key="i"-->
      <!--                       :index="i">-->
      <!--                  <RelaxifyCarouselBox-->
      <!--                      :review="review"-->
      <!--                      :key="i"-->
      <!--                      :is-user-reviews="true"-->
      <!--                  ></RelaxifyCarouselBox>-->
      <!--                </slide>-->

      <!--              </RelaxifyCarousel>-->
      <!--            </div>-->

      <!--        </div>-->
      <!--          <div class="RelaxifyHome__numbers">-->
      <!--            <div class="RelaxifyHome__numbers__mobile">-->
      <!--              <div class="RelaxifyHome__numbers page-part-name"> {{ numbersName }}</div>-->
      <!--              <div class="RelaxifyHome__numbers title"> {{ numbersTitle }}</div>-->
      <!--              <div class="RelaxifyHome__numbers description"> {{ numbersDescription }}</div>-->
      <!--              <div class="RelaxifyHome__numbers-items">-->
      <!--                <div class="RelaxifyHome__numbers-item" v-for="(item, index)  in numbersItems" v-bind:key=index>-->
      <!--                  <div class="RelaxifyHome__numbers&#45;&#45;image-and-title">-->
      <!--                    <img loading="lazy" :src="item.image" :alt="item.altTag"/>-->
      <!--                    <div class="RelaxifyHome__numbers-value"> {{ item.number }}</div>-->
      <!--                    <div class="RelaxifyHome__numbers-description"> {{ item.description }}</div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <a href="https://onelink.to/x3rfwb">-->
      <!--                <RelaxifyButton class="RelaxifyHome__numbers-button" color="primary"> {{-->
      <!--                    $t('RelaxifyButton.title')-->
      <!--                  }}-->
      <!--                </RelaxifyButton>-->
      <!--              </a>-->
      <!--            </div>-->
      <!--            <div class="RelaxifyHome__numbers__desktop">-->
      <!--              <div class="RelaxifyHome__numbers page-part-name"> {{ numbersName }}</div>-->
      <!--              <div class="RelaxifyHome__numbers__desktop__elements">-->
      <!--                <div class="RelaxifyHome__numbers__desktop__information__labels">-->
      <!--                  <h2 class="RelaxifyHome__numbers title"> {{ numbersTitle }}</h2>-->
      <!--                  <div class="RelaxifyHome__numbers description"> {{ numbersDescription }}</div>-->
      <!--                  <RelaxifyButton class="RelaxifyHome__numbers-button" @click="goToFooterContacts('relaxifyFooter')"-->
      <!--                                  color="primary"> {{-->
      <!--                      $t('RelaxifyButton.title')-->
      <!--                    }}-->
      <!--                  </RelaxifyButton>-->
      <!--                </div>-->
      <!--                <div class="RelaxifyHome__numbers-items">-->
      <!--                  <div class="RelaxifyHome__numbers-item" v-for="(item, index)  in numbersItems" v-bind:key=index>-->
      <!--                    <div class="RelaxifyHome__numbers__image__and__title">-->
      <!--                      <img loading="lazy" :src="item.image" :alt="item.altTag"/>-->
      <!--                      <div class="RelaxifyHome__numbers__item__labels">-->
      <!--                        <div class="RelaxifyHome__numbers-value"> {{ item.number }}</div>-->
      <!--                        <div class="RelaxifyHome__numbers-description"> {{ item.description }}</div>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->

      <!--        </div>-->
      <!--          <div class="RelaxifyHome__articles-list">-->
      <!--            <div class="RelaxifyHome__articles-list__mobile">-->
      <!--              <div class="RelaxifyHome__articles page-part-name"> {{ articlesName }}</div>-->
      <!--              <h2 class="RelaxifyHome__articles title"> {{ articlesTitle }}</h2>-->
      <!--              <div class="RelaxifyHome__articles description"> {{ articlesDescription }}</div>-->
      <!--              <div class="RelaxifyHome__articles__item__wrapper">-->
      <!--                <div @click="openTheArticle(articleItem.redirectUrl)" class="RelaxifyHome__articles__item"-->
      <!--                     :style="{backgroundImage: 'url(' + articleItem.background_image + ')', backgroundPosition: 'center center'}">-->
      <!--                  <div class="RelaxifyHome__articles__item__title"> {{ articleItem.title }}</div>-->
      <!--                  <div class="RelaxifyHome__articles__item__description"> {{ articleItem.description }}</div>-->
      <!--                </div>-->
      <!--              </div>-->

      <!--              <a href="https://relaxifyapp.com/blog/">-->
      <!--                <RelaxifyButton color="primary"> {{ $t('RelaxifyButton.see-all-articles') }}</RelaxifyButton>-->
      <!--              </a>-->
      <!--            </div>-->
      <!--            <div class="RelaxifyHome__articles-list__desktop">-->
      <!--              <div class="RelaxifyHome__articles-list__desktop__left__side">-->
      <!--                <div class="RelaxifyHome__articles page-part-name"> {{ articlesName }}</div>-->
      <!--                <h2 class="RelaxifyHome__articles title"> {{ articlesTitle }}</h2>-->
      <!--                <div class="RelaxifyHome__articles description"> {{ articlesDescription }}</div>-->
      <!--                <a href="https://relaxifyapp.com/blog/">-->
      <!--                  <RelaxifyButton color="primary"> {{ $t('RelaxifyButton.see-all-articles') }}</RelaxifyButton>-->
      <!--                </a>-->
      <!--              </div>-->
      <!--              <div class="RelaxifyHome__articles-list__desktop__right__side">-->
      <!--                <div class="RelaxifyHome__articles-list__desktop__main__article"-->
      <!--                     :style="{backgroundImage: 'url(' + articleItem.background_image + ')', backgroundPosition: 'center center'}">-->
      <!--                  <div @click="openTheArticle(articleItem.redirectUrl)"-->
      <!--                       class="RelaxifyHome__articles-list__desktop__main__article__title"> {{ articleItem.title }}-->
      <!--                  </div>-->
      <!--                  <div @click="openTheArticle(articleItem.redirectUrl)"-->
      <!--                       class="RelaxifyHome__articles-list__desktop__main__article__description"> {{-->
      <!--                      articleItem.description-->
      <!--                    }}-->
      <!--                  </div>-->

      <!--                  <div class="RelaxifyHome__articles-list__desktop__next__articles">-->
      <!--                    <div class="RelaxifyHome__articles-list__desktop__next__article"-->
      <!--                         v-for="(article, index) in nextArticles"-->
      <!--                         v-bind:key="index">-->
      <!--                      <div class="RelaxifyHome__articles-list__desktop__next__article__title"> {{ article.title }}</div>-->
      <!--                      <div class="RelaxifyHome__articles-list__desktop__next__article__description"> {{-->
      <!--                          article.description-->
      <!--                        }}-->
      <!--                      </div>-->
      <!--                      <div class="RelaxifyHome__articles-list__desktop__next__article__read__more">-->
      <!--                        <div @click="openTheArticle(article.redirectUrl)"> Read more</div>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->

      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->

      <!--          <div class="RelaxifyHome__media__and__collaboration">-->
      <!--              <div class="RelaxifyHome__media">-->
      <!--                <div class="RelaxifyHome__media page-part-name"> {{ mediaDataPartName }}</div>-->
      <!--                <h2 class="RelaxifyHome__media title"> {{ mediaTitle }}</h2>-->
      <!--                <div class="RelaxifyHome__media__elements">-->
      <!--                  <div v-for="(logo, index) in mediaLogos" v-bind:key="index" class="RelaxifyHome__media__element">-->
      <!--                    <img loading="lazy" :src="logo.image" :alt="logo.alt"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->

      <!--              <div class="RelaxifyHome__collaboration">-->
      <!--                <div class="RelaxifyHome__collaboration page-part-name"> {{ collaborationDataPartName }}</div>-->
      <!--                <h2 class="RelaxifyHome__collaboration title"> {{ collaborationTitle }}</h2>-->
      <!--                <div class="RelaxifyHome__collaboration__elements">-->
      <!--                  <div v-for="(logo, index) in collaborationLogos" v-bind:key="index"-->
      <!--                       class="RelaxifyHome__collaboration__element">-->
      <!--                    <img loading="lazy" :src="logo.image" :alt="logo.alt"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <RelaxifyFooter/>
    </div>
  </div>
</template>

<script>
import RelaxifyHeader from "@/components/RelaxifyHeader";
import RelaxifyButton from "@/components/RelaxifyButton";
import reviews from "@/reviews";
import RelaxifyFooter from "@/components/RelaxifyFooter";
import resources from "@/resources";
import {
  getHomePageBenefitsData,
  getHomePageBenefitsDescription,
  getHomePageBenefitsItems,
  getHomePageBenefitsTitle,
  getHomePageData,
  getHomePageMethodologyButtonTitle,
  getHomePageMethodologyData,
  getHomePageMethodologyDescription,
  getHomePageMethodologyImage,
  getHomePageMethodologyTitle,
  getHomePagePuzzleData,
  getHomePagePuzzleDescription,
  getHomePagePuzzleImage,
  getHomePagePuzzleImageDesktop,
  getHomePagePuzzleTitle,
  getHomePageStartPartBackground,
  getHomePageStartPartButtonTitle,
  getHomePageStartPartData,
  getHomePageStartPartDescription,
  getHomePageStartPartTitle,
  getHomePageUserTestimonialsData,
  getHomePageUserTestimonialsDescription,
  getHomePageUserTestimonialsReviews,
  getHomePageUserTestimonialsTitle,
  getHomePageNumbersData,
  getHomePageNumbersTitle,
  getHomePageNumbersDescription,
  getHomePageNumbersItems,
  getHomePageArticlesTitle,
  getHomePageArticlesDescription,
  getHomePageArticlesItems,
  getHomePageArticlesData,
  getHomePageMethodologyDescriptionForDesktop,
  getHomePageStartPartBackgroundMobile,
  getHomePageMediaData,
  getHomePageCollaborationData,
  getHomePageMediaTitle,
  getHomePageMediaLogos,
  getHomePageCollaborationTitle,
  getHomePageCollaborationLogos,
  getHomePageHrSolutionData,
  getHomePageRoadmapElements,
  getHomePageRoadmapData,
  getHomePageHrBiDashboardData,
  getHomePageEmployeeSurveyData,
  getHomePageHumanResourcesData,
  getHomePageRelaxifyForEmployeesData, getHomePageLastPartData
} from "@/getters/homePageGetters";
import {computed, watch} from "vue";
import {goToFooterContacts} from "@/helpers/helpers";

export default {
  name: 'RelaxifyHome',
  components: {
    RelaxifyFooter,
    RelaxifyButton,
    RelaxifyHeader,
  },
  metaInfo() {
    return {
      title: 'Top Well Being App on the Market - Mental Fitness Apps | RelaxifyApp',
      meta: [
        {
          name: 'description',
          content: 'Relaxif yApp is a wellbeing app that focuses on your mental fitness and helps you remain calm and healthy. Science, technology, and gamification come together to make youfeel better and do better every next day. Start your journey now and do your mental fitness on the go!'
        },
        {
          name: 'robots',
          content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
        },
        {
          property: 'fb:app_id',
          content: '833032110773133'
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:title',
          content: 'Top Well Being App on the Market - Mental Fitness Apps | RelaxifyApp'
        },
        {
          property: 'og:site_name',
          content: 'RelaxifyApp'
        },
      ],
      link: [
        {rel: 'canonical', href: window.location.href}
      ]
    }
  },
  setup() {
    // const {isMobileTrayOpen} = useMobileTray;

    const currentWindowWidth = computed(() => window.innerWidth);

    const homePageData = getHomePageData(resources);

    const homePageStartData = getHomePageStartPartData(homePageData);
    const homePageStartPartTitle = getHomePageStartPartTitle(homePageStartData);
    const homePageStartPartDescription = getHomePageStartPartDescription(homePageStartData);
    const homePageStartPartButtonTitle = getHomePageStartPartButtonTitle(homePageStartData);
    const homePageStartPartBackground = getHomePageStartPartBackground(homePageStartData);
    const homePageStartPartSecondBackground = homePageStartData.backgroundSecondImage;
    const homePageStartPartBackgroundMobile = getHomePageStartPartBackgroundMobile(homePageStartData);

    const hrSolutionData = getHomePageHrSolutionData(homePageData);
    const hrSolutionDesktopLabel = hrSolutionData.desktopLabel;
    const hrSolutionMobileLabel = hrSolutionData.mobileLabel;
    const hrSolutionButtonTitle = hrSolutionData.buttonTitle;
    const hrSolutionElements = hrSolutionData.elements;

    const benefitsData = getHomePageBenefitsData(homePageData);
    const benefitsName = benefitsData.partName;
    const benefitsTitle = getHomePageBenefitsTitle(benefitsData);
    const benefitsDescription = getHomePageBenefitsDescription(benefitsData);
    const benefitsItems = getHomePageBenefitsItems(benefitsData);
    const yellowCircle = benefitsData.yellowCircle;
    const yellowCircleWhiteDot = require('../../public/footer/yellow-circle.svg');
    const benefitsSecondPartElements = benefitsData.secondPart;

    const roadMapData = getHomePageRoadmapData(homePageData);
    const roadMapElements = getHomePageRoadmapElements(roadMapData);

    const hrBiDashboardData = getHomePageHrBiDashboardData(homePageData);
    const hrBiDashboardLabel = hrBiDashboardData.partName;
    const hrBiDashboardTitleImage = hrBiDashboardData.logoImage;
    const hrBiDashboardDesc = hrBiDashboardData.desc;
    const hrBiDashboardSecondDesc = hrBiDashboardData.secondDesc;
    const hrBiDashboardMobileImage = hrBiDashboardData.mobileImage;
    const hrBiDashboardSecondMobileImage = hrBiDashboardData.mobileSecondImage;
    const hrBiDashboardDesktopImage = hrBiDashboardData.desktopImage;
    const hrBiDashboardDesktopSecondImage = hrBiDashboardData.desktopSecondImage;
    const hrBiDashboardElements = hrBiDashboardData.elements;
    const hrBiDashboardButtonTitle = hrBiDashboardData.buttonTitle;
    const hrBiDashboardDataSourcesLogos = hrBiDashboardData.dataSourceElements;

    const employeeSurveyData = getHomePageEmployeeSurveyData(homePageData);
    const employeeSurveyLabel = employeeSurveyData.partName;
    const employeeSurveyTitleLogo = employeeSurveyData.logoImage;
    const employeeSurveyDesc = employeeSurveyData.desc;
    const employeeSurveySecondDesc = employeeSurveyData.secondDesc;
    const employeeSurveyBusinessElements = employeeSurveyData.businessMetricsElements;
    const employeeSurveyMentalElements = employeeSurveyData.mentalHealthMetricsElements;
    const employeeSurveyButtonTitle = employeeSurveyData.buttonTitle;

    const humanResourcesData = getHomePageHumanResourcesData(homePageData);
    const humanResourcesLabel = humanResourcesData.partName;
    const humanResourcesDesktopLabel = humanResourcesData.desktopPartName;
    const humanResourcesLogoImage = humanResourcesData.logoImage;
    const humanResourcesDesc = humanResourcesData.desc;
    const humanResourcesSecondDesc = humanResourcesData.secondDesc;
    const humanResourcesThirdDesc = humanResourcesData.thirdDesc;
    const humanResourcesElements = humanResourcesData.elements;
    const humanResourcesButtonTitle = humanResourcesData.buttonTitle;
    const humanResourcesMainImage = humanResourcesData.mainImage;
    const humanResourcesMainDesktopImage = humanResourcesData.mainDesktopImage;

    const relaxifyEmployyesData = getHomePageRelaxifyForEmployeesData(homePageData);
    const relaxifyEmpployeeLabel = relaxifyEmployyesData.partName;
    const relaxifyEmpployeeTitleLogo = relaxifyEmployyesData.logoImage;
    const relaxifyEmpployeeFirstPhones = relaxifyEmployyesData.firstPhones;
    const relaxifyEmpployeeSecondPhones = relaxifyEmployyesData.secondPhones;
    const relaxifyEmpployeeThirdPhones = relaxifyEmployyesData.thirdPhones;
    const relaxifyEmpployeeDesc = relaxifyEmployyesData.desc;
    const relaxifyEmpployeeButtonTitle = relaxifyEmployyesData.buttonTitle;
    const relaxifyEmpployeeSecondButtonTitle = relaxifyEmployyesData.secondButtonTitle;
    const relaxifyEmpployeePurpleElements = relaxifyEmployyesData.purpleElements;
    const relaxifyEmpployeePurpleElementsTitle = relaxifyEmpployeePurpleElements.title;
    const relaxifyEmpployeePurpleElementsDesc = relaxifyEmpployeePurpleElements.desc;
    const relaxifyEmpployeePurpleElementsItems = relaxifyEmpployeePurpleElements.elements;
    const relaxifyEmpployeeGooglePLayLogo = relaxifyEmpployeePurpleElements.googlePlayLogo;
    const relaxifyEmpployeeAppStoreLogo = relaxifyEmpployeePurpleElements.appStoreLogo;
    const relaxifyEmpployeerelaxifyLogo = relaxifyEmpployeePurpleElements.relaxifyLogo;
    const relaxifyEmpployeeBgAssociationLogo = relaxifyEmpployeePurpleElements.bgAssocicationForPeopleManagementLogo;
    const relaxifyEmpployeeBescoLogo = relaxifyEmpployeePurpleElements.bescoLogo;
    const relaxifyEmpployeeEuStartupLogo = relaxifyEmpployeePurpleElements.euStartupLogo;
    const relaxifyEmpployeeForbesLogo = relaxifyEmpployeePurpleElements.forbesLogo;
    const relaxifyEmpployeeCapitalLogo = relaxifyEmpployeePurpleElements.capitalLogo;
    const relaxifyEmpployeeBloombergLogo = relaxifyEmpployeePurpleElements.bloombergLogo;
    const relaxifyEmpployeeWinnerLogo = relaxifyEmpployeePurpleElements.winnerLogo;


    const relaxifyEmpployeeMemberOfAsString = relaxifyEmpployeePurpleElements.memberOf;
    const relaxifyEmpployeeInMediaOfAsString = relaxifyEmpployeePurpleElements.inMedia;
    const relaxifyEmpployeeAwardsAsString = relaxifyEmpployeePurpleElements.awards;

    const relaxifyLastPartData = getHomePageLastPartData(homePageData);
    const relaxifyLastPartTitle = relaxifyLastPartData.title;
    const relaxifyLastPartElements = relaxifyLastPartData.elements;
    const relaxifyLastPartImage = relaxifyLastPartData.image;


    const puzzleData = getHomePagePuzzleData(homePageData);
    const puzzleName = puzzleData.partName;
    const puzzleTitle = getHomePagePuzzleTitle(puzzleData);
    const puzzleDescription = getHomePagePuzzleDescription(puzzleData);
    const puzzleImage = getHomePagePuzzleImage(puzzleData);
    const puzzleImageDesktop = getHomePagePuzzleImageDesktop(puzzleData);

    const methodologyData = getHomePageMethodologyData(homePageData);
    const methodologyName = methodologyData.partName;
    const methodologyTitle = getHomePageMethodologyTitle(methodologyData);
    const methodologyDescription = getHomePageMethodologyDescription(methodologyData);
    const methodologyDescriptionForDesktop = getHomePageMethodologyDescriptionForDesktop(methodologyData);
    const methodologyImage = getHomePageMethodologyImage(methodologyData);
    const methodologyButtonTitle = getHomePageMethodologyButtonTitle(methodologyData);

    const userTestimonialsData = getHomePageUserTestimonialsData(homePageData);
    const userTestimonialsName = userTestimonialsData.partName;
    const userTestimonialsTitle = getHomePageUserTestimonialsTitle(userTestimonialsData);
    const userTestimonialsDescription = getHomePageUserTestimonialsDescription(userTestimonialsData);
    const userTestimonialsReviews = getHomePageUserTestimonialsReviews(userTestimonialsData);

    const numbersData = getHomePageNumbersData(homePageData);
    const numbersName = numbersData.partName;
    const numbersTitle = getHomePageNumbersTitle(numbersData);
    const numbersDescription = getHomePageNumbersDescription(numbersData);
    const numbersItems = getHomePageNumbersItems(numbersData);

    const articlesData = getHomePageArticlesData(homePageData);
    const articlesName = articlesData.partName;
    const articleItem = articlesData.items[0];
    const articlesTitle = getHomePageArticlesTitle(articlesData);
    const articlesDescription = getHomePageArticlesDescription(articlesData);
    const articlesItems = getHomePageArticlesItems(articlesData);
    const nextArticles = articlesData.nextArticles;

    const mediaData = getHomePageMediaData(homePageData);
    const mediaDataPartName = mediaData.partName;
    const mediaTitle = getHomePageMediaTitle(mediaData);
    const mediaLogos = getHomePageMediaLogos(mediaData);

    const collaborationData = getHomePageCollaborationData(homePageData);
    const collaborationDataPartName = collaborationData.partName;
    const collaborationTitle = getHomePageCollaborationTitle(collaborationData);
    const collaborationLogos = getHomePageCollaborationLogos(collaborationData);

    const openTheArticle = (redirectUrl) => {
      window.open(redirectUrl.toString());
    }

    const carouselSettings = {
      itemsToShow: 1,
      wheelControl: false,
      breakpoints: {
        320: {
          itemsToShow: 1.2,
          centerMode: true,
        },
        360: {
          itemsToShow: 1.15,
          centerMode: true,
        },
        390: {
          itemsToShow: 1.25,
          centerMode: true,
        },
        414: {
          itemsToShow: 1.3,
          infiniteScroll: true,
          centerMode: true,
        },
        600: {
          itemsToShow: 1.9,
        },
        620: {
          itemsToShow: 2,
        },
        650: {
          itemsToShow: 2.1,
        },
        680: {
          itemsToShow: 2.2,
        },
        700: {
          itemsToShow: 2.3,
        },
        768: {
          itemsToShow: 1.7,
          centerMode: true,
          infiniteScroll: true
        },
        800: {
          itemsToShow: 2.6,
        },
        850: {
          itemsToShow: 2.8,
        },
        900: {
          itemsToShow: 2.9,
        },
        950: {
          itemsToShow: 3.1,
        },
        1000: {
          itemsToShow: 3.25,
        },
        1050: {
          itemsToShow: 3.4,
        },
        1100: {
          itemsToShow: 3.5,
        },
        1150: {
          itemsToShow: 3.6,
        },
        1200: {
          itemsToShow: 3.7,
        },
        1250: {
          itemsToShow: 3.9,
        },
        1300: {
          itemsToShow: 4.1,
        },
        1350: {
          itemsToShow: 4.3,
        },
        1400: {
          itemsToShow: 4.5,
        },
        1450: {
          itemsToShow: 4.75,
        },
        1500: {
          itemsToShow: 4.8,
        },
        1550: {
          itemsToShow: 5,
        },
        1600: {
          itemsToShow: 5,
        },
      }
    }

    watch(currentWindowWidth, (newCurrentWidth) => {
      if (currentWindowWidth !== newCurrentWidth) {
        currentWindowWidth.value = newCurrentWidth;
      }
    })

    return {
      reviews,
      carouselSettings,
      homePageStartData,
      homePageStartPartTitle,
      homePageStartPartDescription,
      homePageStartPartButtonTitle,
      homePageStartPartBackground,
      benefitsName,
      benefitsTitle,
      benefitsDescription,
      benefitsItems,
      puzzleName,
      puzzleTitle,
      puzzleDescription,
      puzzleImage,
      puzzleImageDesktop,
      methodologyName,
      methodologyTitle,
      methodologyDescription,
      methodologyImage,
      methodologyButtonTitle,
      userTestimonialsName,
      userTestimonialsTitle,
      userTestimonialsDescription,
      userTestimonialsReviews,
      numbersName,
      numbersTitle,
      numbersDescription,
      articlesName,
      articleItem,
      numbersItems,
      articlesTitle,
      articlesDescription,
      articlesItems,
      methodologyDescriptionForDesktop,
      homePageStartPartBackgroundMobile,
      currentWindowWidth,
      goToFooterContacts,
      openTheArticle,
      nextArticles,
      mediaData,
      mediaTitle,
      mediaLogos,
      mediaDataPartName,
      collaborationData,
      collaborationDataPartName,
      collaborationTitle,
      collaborationLogos,
      hrSolutionDesktopLabel,
      hrSolutionMobileLabel,
      hrSolutionElements,
      hrSolutionButtonTitle,
      yellowCircle,
      benefitsSecondPartElements,
      roadMapElements,
      roadMapData,
      hrBiDashboardLabel,
      hrBiDashboardDesc,
      hrBiDashboardSecondDesc,
      hrBiDashboardMobileImage,
      hrBiDashboardSecondMobileImage,
      hrBiDashboardDesktopImage,
      hrBiDashboardDesktopSecondImage,
      hrBiDashboardElements,
      hrBiDashboardTitleImage,
      hrBiDashboardDataSourcesLogos,
      hrBiDashboardButtonTitle,
      employeeSurveyLabel,
      employeeSurveyDesc,
      employeeSurveySecondDesc,
      employeeSurveyBusinessElements,
      employeeSurveyMentalElements,
      employeeSurveyButtonTitle,
      employeeSurveyTitleLogo,
      humanResourcesLabel,
      humanResourcesLogoImage,
      humanResourcesDesc,
      humanResourcesSecondDesc,
      humanResourcesThirdDesc,
      humanResourcesElements,
      humanResourcesButtonTitle,
      humanResourcesMainImage,
      relaxifyEmpployeeLabel,
      relaxifyEmpployeeTitleLogo,
      relaxifyEmpployeeFirstPhones,
      relaxifyEmpployeeSecondPhones,
      relaxifyEmpployeeThirdPhones,
      relaxifyEmpployeeDesc,
      relaxifyEmpployeeButtonTitle,
      relaxifyEmpployeePurpleElementsTitle,
      relaxifyEmpployeePurpleElementsDesc,
      relaxifyEmpployeePurpleElementsItems,
      relaxifyEmpployeeGooglePLayLogo,
      relaxifyEmpployeeAppStoreLogo,
      relaxifyEmpployeerelaxifyLogo,
      relaxifyEmpployeeBgAssociationLogo,
      relaxifyEmpployeeBescoLogo,
      relaxifyEmpployeeEuStartupLogo,
      relaxifyEmpployeeForbesLogo,
      relaxifyEmpployeeCapitalLogo,
      relaxifyEmpployeeBloombergLogo,
      relaxifyEmpployeeWinnerLogo,
      relaxifyEmpployeeMemberOfAsString,
      relaxifyEmpployeeInMediaOfAsString,
      relaxifyEmpployeeAwardsAsString,
      relaxifyLastPartTitle,
      relaxifyLastPartElements,
      relaxifyLastPartImage,
      relaxifyEmpployeeSecondButtonTitle,
      homePageStartPartSecondBackground,
      yellowCircleWhiteDot,
      humanResourcesDesktopLabel,
      humanResourcesMainDesktopImage
    }
  }
}
</script>

<style lang="scss">
.RelaxifyHome {

  a {
    text-decoration: none;
  }

  .page-part-name {
    @media (max-width: 768px) {
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }

    @media (min-width: 1440px) {
      font-size: 20px;
    }

    color: #5358C2;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    letter-spacing: 0.14em;
    margin: 30px 20px 10px 20px;
    text-align: start;
  }

  &__hr__solutions {
    @media (min-width: 1024px) {
      padding-bottom: 80px;
    }
    position: relative;

    button {
      width: 100%;
    }

    &__yellow__dot {
      position: absolute;
      display: none;
      width: 50px;
      height: 50px;

      @media (min-width: 1024px) {
        display: block;
        top: -10px;
        right: 7px;
      }

      @media (min-width: 1440px) {
        width: 75px;
        height: 75px;
      }
    }

    .mobile {
      @media (max-width: 1023px) {
        display: block;
      }
      display: none;
    }

    .desktop {
      display: none;

      @media (min-width: 1024px) {
        display: block
      }

      @media (min-width: 1440px) {
        margin-left: 240px;
        font-size: 20px;
      }
    }

    &__elements {
      &__title {
        text-align: center; /* Center the text */
        margin: 30px 20px 20px 20px; /* Center the box */
        color: #193566;
        font-size: 24px;
        font-weight: bold;

        @media (min-width: 1024px) {
          text-align: left;
        }

        @media (min-width: 1440px) {
          margin-left: 177px;

        }
        @media (min-width: 1750px) {
          margin-left: 250px;
        }
      }

      &__container {
        margin: 0 20px;

        @media (min-width: 1024px) {
          display: flex;
          justify-content: center;
          a {
            display: none;
          }
          img {
            width: 50px;
            height: 50px;
          }
        }

        @media (min-width: 1440px) {
          margin: 0 260px;
          justify-content: space-between;
        }
      }
    }

    &__element {
      display: flex;
      flex-direction: column;
      background-color: #ffffff; /* Background color */
      border-radius: 10px; /* Rounded corners */
      padding: 20px; /* Space inside the container */
      max-width: 1182px;
      box-shadow: 0px 0px 10px -2px #2993FF;
      margin: 20px auto; /* Center the box horizontally */

      &__image__and__text {
        @media (min-width: 1024px) {
          margin-left: 20px;

        }

        h3 {
          display: none;
          @media (min-width: 1024px) {
            //display: block;
          }
        }
      }

      @media (min-width: 1024px) {
        //flex-direction: row;
        //align-items: flex-start;
        //background-color: #f2f2f2;
        //box-shadow: unset;
        //border-radius: 30px;
        //margin-bottom: 30px;
        max-width: 227px;
        margin: 20px 10px;
      }
      @media (min-width: 1440px) {
        //margin: 20px 150px;
        max-width: 272px;
      }

      &__title {
        font-size: 24px;
        font-weight: bold;
        color: #193566; /* Darker text color */
        margin-top: unset;
        margin-left: 10px;

        @media (min-width: 1024px) {
          margin-left: 20px;
          font-size: 22px;
        }

        @media (min-width: 1440px) {
          margin-left: 20px;
          font-size: 24px;
        }
      }

      &__description {
        font-size: 18px;
        color: #193566; /* Adjusted for readability */
        line-height: 1.4;

        @media (min-width: 1440px) {
          font-size: 20px;
        }
      }

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 15px; /* Space between icon/title and description */

        @media (min-width: 1024px) {
          max-height: 58px;
          margin-bottom: 20px;
        }

        @media (min-width: 1440px) {
          max-height: 92px;
        }
      }

      .icon {
        width: 40px; /* Icon size */
        height: 40px;
        margin-right: 15px; /* Space between the icon and the title */
      }
    }
  }

  .RelaxifyHome__hr__solutions.page-part-name {
    @media (min-width: 1024px) {
      font-size: 18px;
      margin-left: 30px;
      margin-bottom: 30px;
      padding-bottom: unset;
    }
  }

  .title {
    color: #193566;
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
    margin: unset;
    text-align: initial;
    padding: 3px 20px;
  }

  .description {
    color: #193566;
    font-size: 18px;
    text-align: start;
    margin: 3px 20px;
    font-weight: 400;
    line-height: 150%;
  }

  &__background-image {
    height: 195px;
  }

  &__background-image--title {
    @media (min-width: 1024px) {
      text-align: unset;
      margin-bottom: 50px;
      font-size: 28px;
    }

    @media (min-width: 1440px) {
      font-size: 32px;
    }

    @media (max-width: 1022px) {
      padding: 50px 0 20px 0;
      text-align: left;
      font-size: 24px;
      line-height: 145%;
      max-width: 400px;
    }

    @media (min-width: 1440px) {

    }

    color: #FFFFFF;
    font-size: 32px;
    text-align: center;
    //margin: 40px 70px 23px 70px;
    font-weight: 700;
    line-height: 120%;
  }

  &__background-image--description {
    @media (min-width: 1024px) {
      //margin: 20px 0 40px 30px;
      margin-bottom: unset;
      text-align: unset;
      color: white;
    }

    @media (min-width: 1366px) {
      //margin: 20px 0 40px 30px;
      margin-top: unset;
      font-size: 24px;
    }

    color: #193566;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
  }


  &__start__page {
    background: linear-gradient(to bottom, #5358C2 0%, #FFFFFF 100%);
  }

  &__start__page__mobile {
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image__wrapper {
      img {
        max-width: 100%;
        display: block;
      }
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  &__start__page__desktop {
    @media (min-width: 1024px) {
      //margin: 120px 0 90px 30px;
      display: flex;
      padding: 70px 20px;
      flex-direction: row;
    }

    @media (min-width: 1024px) {
      padding: 135px 20px;
    }

    &__circle__and__desc {
      display: flex;
      align-items: flex-start;

      img {
        margin-right: 20px;
        margin-left: -50px;
        width: 30px;
        height: 30px;
      }
    }

    &__first__yellow {
      position: absolute;
      top: 20px
    }

    @media (min-width: 1440px) {
      //margin: 175px 0 150px 150px;
    }
    display: none;

    &__left__side {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 400px;
      position: relative;
      background-color: rgba(25, 53, 102, 0.47);
      padding: 50px 60px;

      @media (min-width: 1366px) {
        width: 44%;
        margin-left: 240px;
      }
    }

    &__right__side {
      display: flex;
      align-items: self-end;
      margin-left: 33px;
      width: 50%;
      position: relative;

      @media (min-width: 1366px) {
        width: 62%;
      }

      .main-image {
        width: 370px; /* Set appropriate width */
        position: relative;
        z-index: 1;
        height: 227px;

        @media (min-width: 1366px) {
          width: 450px;
          height: 290px;
        }
      }

      .decorative-image {
        width: 370px; /* Smaller size for decoration */
        position: absolute;
        right: 3px;
        top: 0;
        z-index: 2;

        @media (min-width: 1366px) {
          width: 470px;
          right: 236px;
          height: 330px;
          top: -18px;
        }
      }

      .first-white-dot {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 0;

        @media (min-width: 1440px) {
          width: 75px;
          height: 75px;
        }
      }

      .second-white-dot {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 301px;
        right: 34px;

        @media (min-width: 1024px) {
          right: 245px;
          top: 330px;
        }

        @media (min-width: 1440px) {
          width: 75px;
          height: 75px;
        }
      }
    }

    &__image__wrapper {
      cursor: pointer;
      @media (min-width: 1440px) {
        margin-left: 100px;
      }

      @media (min-width: 1024px) {
        img {
          height: 400px;
          margin-left: 100px;
        }
      }
    }
  }


  .RelaxifyButton.RelaxifyHome__try-for-free-button {
    @media (min-width: 1024px) {
      margin-left: 30px;
    }

    @media (min-width: 1440px) {
      width: 366px;
      height: 56px;
    }

    margin: 0 20px;

    button {
      width: 100%;
    }
  }

  .RelaxifyLayer {
    height: 280px !important;
    max-width: 1531px !important;
    margin: 0 auto !important;


    .hooper {
      height: auto !important;
      max-height: 350px !important;
    }

    .hooper-list {
      overflow: hidden !important;
    }

    .hooper-pagination {
      display: none;
    }

    .hooper-prev,
    .hooper-next {
      top: 0;
      width: 49px;
      height: 49px;
      position: relative;
      transform: none;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 7px;
    }

    &__navigation {
      margin-top: 5px !important;

      &-left,
      &-right {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__benefits {
    padding-bottom: 20px;
    position: relative;
    background-color: rgba(236, 240, 243, 0.7);
    @media (min-width: 1440px) {
      margin-bottom: 75px;
    }

    &__yellow__dot {
      display: none;
      @media (min-width: 1024px) {
        display: block;
        width: 50px;
        height: 50px;
        left: 7px;
        position: absolute;
        top: -39px;
        z-index: 2;
      }

      @media (min-width: 1440px) {
        width: 75px;
        height: 75px;
      }
    }

    &__title {
      color: #193566;
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
      margin: unset;
      text-align: initial;
      padding: 20px;

      @media (min-width: 1440px) {
        margin-left: 125px;
      }
    }

    &__information__labels {
      @media (max-width: 1023px) {
        .RelaxifyHome__benefits.title {
          text-align: center !important;
        }
      }

      @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 10px;
      }

      @media (min-width: 1440px) {
        margin-left: 135px;
      }
    }

    &__name {
      color: #718EC1;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
      margin: 30px 20px 10px 14px;
      text-align: start;
    }

    &--items {
      @media (min-width: 1024px) {
        display: flex;
      }

      @media (min-width: 1440px) {
        margin: 0 165px;
      }

      margin: 0 20px 50px 20px;;
    }

    &--item {
      @media (min-width: 1024px) {
        box-shadow: unset;
        border-right: 4px solid #ECF0F3;
        background: transparent;

        &:last-child {
          border-right: unset;
        }
      }

      @media (min-width: 1440px) {
        max-width: 600px;
      }

      @media (min-width: 1840px) {
        max-width: 610px;
      }

      @media (min-width: 1869px) {
        max-width: 620px;
      }

      @media (min-width: 1900px) {
        max-width: 625px;
      }

      @media (min-width: 1915px) {
        max-width: 630px;
      }

      @media (min-width: 1930px) {
        max-width: 635px;
      }

      @media (min-width: 1945px) {
        max-width: 640px;
      }

      display: flex;
      flex-direction: column;
      background-color: #ffffff; /* Background color */
      border-radius: 10px; /* Rounded corners */
      padding: 20px; /* Space inside the container */
      max-width: 1182px;
      box-shadow: 0px 0px 10px -2px #2993FF;
      margin: 20px auto; /* Center the box horizontally */
      height: 355px;
    }

    &--image-and-title {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        @media (min-width: 1440px) {
          width: 115px;
          height: 103px;
        }
        width: 75px;
        height: 67px;
      }

      .benefitImage {
        position: absolute;
        top: 0;
        right: -20px;

        img {
          height: 95px;
          width: 95px;
        }
      }
    }

    &--item-title {
      @media (min-width: 1440px) {
        font-size: 32px;
        max-width: 422px;
        line-height: 42px;
      }

      color: #193566;
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      text-align: initial;
      max-width: 190px;
      padding: 3px 14px;
      margin-left: 5px;
    }

    &--item-description {
      @media (min-width: 1440px) {
        font-size: 20px;
        max-width: 440px;
      }
      color: #193566;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      text-align: start;
      margin: 20px 0 60px 0;
    }

    &__subtitle {
      display: flex;
      align-items: center;
      margin-top: 35px;

      img {
        width: 30px;
        height: 30px;
      }

      &__text {
        color: #193566;
        font-size: 20px;
        max-width: 232px;
        margin-left: 20px;
      }
    }

    &__second__part {
      @media (min-width: 1024px) {
        display: flex;
        margin: 0 40px;
      }

      @media (min-width: 1440px) {
        margin: 50px 135px 0 135px;
      }
      margin: 0 20px;

      &__item {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        padding: 20px;
        max-width: 1182px;
        box-shadow: 0px 0px 10px -2px #2993FF;
        margin: 20px auto 50px auto;

        @media (min-width: 1024px) {
          flex: 1;
          &:first-child {
            margin-right: 20px;
          }
        }

        @media (min-width: 1440px) {
          max-width: 530px;
        }

        &__title {
          min-height: 107px;
          background: #5358C2;
          font-size: 24px;
          text-align: center;
          color: white;
          padding: 20px;
          margin-bottom: 20px;
        }

        &__desc {
          font-size: 20px;
          color: #193566;
          padding: 10px 20px 20px 50px;
        }

        &__element {
          display: flex;
          margin-bottom: 30px;

          &__title {
            margin-left: 20px;
            font-size: 20px;
            color: #193566;
          }

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .RelaxifyHome__benefits.title {
    @media (min-width: 1024px) {
      text-align: center;
      max-width: 404px;
      margin-bottom: 70px;
    }

    @media (min-width: 1440px) {
      font-size: 48px;
      max-width: 685px;
      margin-bottom: 110px;
    }
  }

  .RelaxifyHome__benefits.description {
    @media (min-width: 1024px) {
      display: none;
    }

    margin: 20px 20px 60px 20px;
  }

  .RelaxifyHome__benefits.page-part-name {
    margin-top: 80px;
    padding-top: 20px;

    @media (min-width: 1024px) {
      margin-top: unset;
    }
    @media (min-width: 1440px) {
      margin-left: 145px;
      padding-top: 40px;
    }
  }

  &__puzzle {
    @media (min-width: 1440px) {
      margin-bottom: 70px;
    }

    align-items: center;
    display: flex;
    flex-direction: column;

    img {
      margin-top: 50px;
    }

    &__image {
      @media (min-width: 1024px) {
        display: none;
      }
    }

    &__image__desktop {
      @media (min-width: 1024px) {
        margin: 30px 0 50px 0;
        display: block;
        img {
          width: 660px;
        }
      }

      @media (min-width: 1440px) {
        img {
          width: 700px;
        }
      }


      display: none;
    }
  }

  .RelaxifyHome__puzzle.title {

    @media (min-width: 1440px) {
      font-size: 48px;
    }
    margin-bottom: 25px;
  }

  .RelaxifyHome__puzzle.subtitle {
    margin-bottom: 23px;
    width: 367px;
  }

  .RelaxifyHome__puzzle.description {

    @media (min-width: 768px) {
      max-width: unset;
    }

    @media (min-width: 1024px) {
      max-width: 685px;
    }

    @media (min-width: 1440px) {
      font-size: 20px;
      max-width: 755px;
    }

    text-align: center;
    max-width: 367px;
  }

  &__methodology {

    @media (min-width: 768px) {
      align-items: center;
    }

    @media (min-width: 1024px) {
      display: flex;
      flex-direction: row;
      margin: 20px 70px 0 83px;
      padding-bottom: 100px;
    }

    @media (min-width: 1440px) {
      align-items: unset;
      margin-left: 211px;
      padding-bottom: 150px;
    }

    @media (min-width: 1920px) {
      margin-left: 222px;
    }

    display: flex;
    flex-direction: column;

    &--content {
      margin-bottom: 20px;
    }

    img {
      @media (min-width: 1440px) {
        width: 532px;
        height: 536px;
      }
    }

    &__image {

      &__wrapper {
        display: flex;
        justify-content: center;

        @media (min-width: 1024px) {
          display: block;
          justify-content: unset;
        }
      }

      @media (min-width: 1024px) {
        margin: unset;
      }
      margin: 85px 14px 0 14px;
    }

    &__button {
      @media (min-width: 1024px) {
        margin-bottom: unset;
      }

      display: flex;
      margin: 60px 20px 90px 20px;

      button {
        @media (min-width: 1024px) {
          width: 366px;
          height: 56px !important;
        }

        @media (min-width: 1440px) {
          height: 56px !important;
        }

        width: 100%;
      }
    }
  }

  .RelaxifyHome__methodology.page-part-name {
    @media (min-width: 1024px) {
      margin-top: unset;
      padding-bottom: unset;
      max-width: 500px;
    }
  }

  .RelaxifyHome__methodology.title {

    @media (max-width: 480px) {
      max-width: 330px;
    }

    @media (max-width: 1023px) {
      text-align: center;
      padding: 3px 0 0 0 !important;
      margin: 0 auto 35px auto;
    }

    @media (min-width: 1024px) {
      max-width: 490px;
      padding-right: unset;
    }

    @media (min-width: 1440px) {
      font-size: 48px;
      margin-top: 20px;
      max-width: 850px;
      margin-bottom: 30px;
    }
  }

  .RelaxifyHome__methodology.description {
    @media (min-width: 768px) {
      width: unset;
    }

    @media (max-width: 1023px) {
      text-align: center;
      padding: unset !important;
      margin: 0 auto;
    }

    @media (min-width: 1024px) {
      padding-bottom: unset;
    }

    @media (min-width: 1440px) {
      display: none;
    }

    margin-top: 35px;
    width: 355px;
  }

  .RelaxifyHome__methodology.description__desktop {
    @media (min-width: 1440px) {
      font-size: 20px;
      max-width: 700px;
      display: block;
    }

    display: none;
  }

  &__user-testimonials {
    @media (min-width: 1440px) {
      padding-top: 50px;
      padding-bottom: 10px;
    }

    padding-top: 30px;
    background: #E5E5E5;
  }

  &__user-testimonial.subtitle {
    max-width: 242px;
    margin-bottom: 20px;
  }

  .RelaxifyHome__user-testimonials.page-part-name {
    @media(min-width: 1024px) {
      text-align: center;
      margin: unset;
    }
  }

  .RelaxifyHome__user-testimonials.title {
    @media(min-width: 768px) {
      width: unset;
    }

    @media (max-width: 1023px) {
      text-align: center;
      padding: 3px 0 0 0 !important;
      margin: 0 auto;
    }

    @media(min-width: 1024px) {
      text-align: center;
      margin: 10px 0 0 0;
    }

    @media(min-width: 1440px) {
      font-size: 48px;
    }

    width: 310px;
  }

  &__user-testimonials.description {
    @media(min-width: 768px) {
      width: unset;
    }

    @media (max-width: 1023px) {
      text-align: center;
      padding: unset !important;
      margin: 30px auto 0 auto;
    }

    @media(min-width: 1024px) {
      margin: 15px auto 0 auto;
      max-width: 470px;
      padding: unset;
      text-align: center;
    }
    @media(min-width: 1024px) {
      font-size: 20px;
      max-width: 538px;
      margin-bottom: 130px;
    }

    width: 338px;
    margin: 0 20px 50px 20px;
  }

  &__roadmap {
    position: relative;
    display: flex;
    flex-direction: column;

    &__yellow__dot {
      display: none;
      @media (min-width: 1024px) {
        display: block;
        position: absolute;
        top: 0;
        right: 7px;
        width: 50px;
        height: 50px;
      }

      @media (min-width: 1440px) {
        width: 75px;
        height: 75px;
      }
    }

    &__elements {
      --row-gap: 2rem;
      --line-w: 0.25rem;
      display: grid;
      grid-template-columns: var(--line-w) 1fr;
      grid-auto-columns: max-content;
      column-gap: var(--col-gap);
      list-style: none;
      margin-inline: auto;
      margin-top: 30px;
      margin-left: 20px;

      @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-inline: auto;
        margin-top: 30px;
        border-bottom: 4px solid #E1E1E1;
        width: 90%;
      }

      @media (min-width: 1024px) {
        margin-top: 50px;
        width: 80%;
      }

      &::before {
        content: "";
        grid-column: 1;
        grid-row: 1 / span 20;
        background: rgb(225, 225, 225);
        border-radius: calc(var(--line-w) / 2);

        //@media (min-width: 1024px) {
        //  content: "";
        //  position: absolute;
        //  top: 50%;
        //  left: 0;
        //  right: 0;
        //  height: var(--line-w);
        //  background: #e1e1e1;
        //  border-radius: calc(var(--line-w) / 2);
        //  z-index: 1;
        //}
      }
    }

    &__element {
      display: flex;
      align-items: center;
      padding: 20px;
      margin-bottom: 20px;
      position: relative;

      @media (min-width: 1024px) {
        //display: flex;
        //flex-direction: column;
        //align-items: center;
        //padding: 20px;
        //position: relative;
        //background-color: white;
        //z-index: 2;
      }

      //&:nth-child(4) {
      //  &::before {
      //    display: none !important;
      //  }
      //}

      &__circle {
        @media (min-width: 1024px) {
          margin-right: 25px;
        }

        min-width: 38px;
        min-height: 38px;
        border-radius: 50%;
        background: #FFC726;
        text-align: center;
        font-size: 24px;
        color: #193566;
        font-weight: 700;
        z-index: 2;
        margin-right: 40px;
      }

      &__title {
        font-size: 20px;
        font-weight: 600;
        color: #193566;

        @media (min-width: 1440px) {
          font-size: 22px;
        }
      }
    }
  }

  .RelaxifyHome__roadmap.page-part-name {
    @media (min-width: 1440px) {
      margin-left: 280px;
    }
  }

  &__hrbidashboard {
    color: #193566;
    margin: 40px 20px;
    position: relative;

    @media (min-width: 1024px) {
      margin: 40px 80px;
    }

    @media (min-width: 1440px) {
      margin: 100px 280px 100px 280px;
    }

    &__yellow__dot {
      display: none;
      position: absolute;

      @media (min-width: 1024px) {
        display: block;
        width: 50px;
        height: 50px;
        left: -80px;
        top: 73px;
      }

      @media (min-width: 1440px) {
        left: -275px;
      }

      @media (min-width: 1440px) {
        width: 75px;
        height: 75px;
      }
    }

    &__data__sources__elements {
      margin: 25px 40px;
      display: none;
      flex-wrap: wrap;
      @media (min-width: 768px) {
        flex-wrap: unset;
        display: flex;
        align-items: center;
        margin: 50px 20px;
      }

      @media (min-width: 1024px) {
      }
    }

    &__text__and__elements {
      @media (min-width: 1024px) {
        display: flex;
        &:nth-of-type(3) {
          flex-direction: row-reverse;
          margin-top: 50px;

          .RelaxifyHome__hrbidashboard__desktop__image {
            height: 300px;
          }

          .RelaxifyHome__hrbidashboard__elements__container {
            width: 400px;
          }
        }
      }

      @media (min-width: 1280px) {
        align-items: center;
        &:nth-of-type(3) {
          .RelaxifyHome__hrbidashboard__desktop__image {
            height: unset;
          }
        }
      }

      @media (min-width: 1440px) {
        &:nth-of-type(3) {
          .RelaxifyHome__hrbidashboard__desktop__image {
            font-size: 20px;

            img {
              width: 700px;
              height: 417px;
            }
          }
        }
      }

      @media (min-width: 1440px) {
        align-items: flex-start;
        justify-content: space-between;
      }


      &__left__side {
        @media (min-width: 1440px) {
          width: 400px;
        }
      }

      &__right__side {
        @media (min-width: 1440px) {
          img {
          }
        }
      }
    }

    .yellow-cirlce {
      width: 30px;
      height: 30px;
    }


    &__data__sources__element {
      flex: 1 1 44%;
      @media (min-width: 1024px) {
        flex: unset;
        margin-right: 20px;
      }
    }

    &__title__and__logo {
      display: flex;
      margin-bottom: 50px;
      align-items: center;
      padding-top: 30px;

      @media (min-width: 1440px) {
        img {
          width: 70px;
          height: 70px;
        }
      }
    }

    &__title {
      font-size: 32px;
      font-weight: 600;
      margin-left: 15px;

      @media (min-width: 1440px) {
        font-size: 56px;
        margin-left: 30px;
      }
    }

    &__desc {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 30px;

      @media (min-width: 1440px) {
        font-size: 24px;
      }
    }

    &__second__desc {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;

      @media (min-width: 1440px) {
        margin-top: 60px;
        margin-bottom: 77px;
      }

      &__text {
        font-size: 18px;
        margin-left: 10px;

        @media (min-width: 1440px) {
          font-size: 20px;
        }
      }
    }

    &__mobile__image {
      display: flex;
      margin: 0 auto 40px auto;
      @media (min-width: 1024px) {
        display: none;
      }
    }

    &__desktop__image {
      display: none;

      @media (min-width: 1024px) {
        display: block;
        margin: 0 auto 40px 20px;
        width: 435px;
        //height: 415px;

        img {
          height: 415px;
        }
      }

      @media (min-width: 1360px) {
        img {
          width: 700px;
          height: 415px;
        }
      }

      @media (min-width: 1440px) {
        width: unset;
      }
    }

    &__element {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;

      &__text {
        margin-left: 10px;
        font-size: 18px;

        @media (min-width: 1440px) {
          font-size: 20px;
        }
      }
    }

    button {
      width: 100%;
    }

    &__elements__container {
      @media (min-width: 1024px) {
        padding: 0 30px 30px 30px;
      }

      @media (min-width: 1366px) {
        //max-width: 390px;
      }
    }

    &__elements__mobile__button {
      @media (min-width: 1024px) {
        display: none;
      }
    }

    &__elements__desktop__button {
      @media (min-width: 1024px) {
        display: block;
      }
      display: none;
    }
  }


  &__employee__survey {
    position: relative;
    margin: 40px 20px;
    color: #193566;


    &__yellow__dot {
      position: absolute;
      top: -18px;
      width: 50px;
      height: 50px;
      right: -74px;
      display: none;

      @media (min-width: 1024px) {
        display: block;
      }

      @media (min-width: 1440px) {
        width: 75px;
        height: 75px;
        right: -277px;
      }
    }

    @media (min-width: 1024px) {
      margin: 40px 80px;
    }

    @media (min-width: 1440px) {
      margin: 100px 280px 100px 280px;
    }

    &__title__and__logo {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      padding-top: 30px;

      @media (min-width: 1440px) {
        img {
          width: 70px;
          height: 70px;
        }
      }
    }

    &__title {
      font-size: 32px;
      font-weight: 600;
      margin-left: 15px;

      @media (min-width: 1440px) {
        font-size: 56px;
        margin-left: 30px;
      }
    }

    &__second__desc {
      color: #5358C2;
      font-size: 14px;
      margin-top: 50px;
      margin-left: 40px;
      letter-spacing: 3px;

      @media (min-width: 1024px) {
        text-align: center;
        font-size: 26px;
        margin-bottom: 30px;
        font-weight: 700;
        color: #193566;
      }

      @media (min-width: 1024px) {
        font-size: 32px;
      }
    }

    &__elements__container {
      margin: 0 20px 50px 20px;

      @media (min-width: 1024px) {
        display: flex;
        margin-bottom: unset;
      }
    }

    &__element {
      display: flex;
      flex-direction: column;
      background-color: #5358C2; /* Background color */
      border-radius: 10px; /* Rounded corners */
      padding: 20px; /* Space inside the container */
      max-width: 1182px;
      margin: 20px auto; /* Center the box horizontally */

      &__image__and__text {
        @media (min-width: 1024px) {
          margin-left: 20px;
        }

        h3 {
          display: none;
          @media (min-width: 1024px) {
            display: block;
          }
        }
      }

      .first-phones {
        width: 350px;
      }

      @media (min-width: 1024px) {
        //flex-direction: row;
        //align-items: flex-start;
        //background-color: #f2f2f2;
        //box-shadow: unset;
        //border-radius: 30px;
        //margin-bottom: 30px;
        margin-right: 20px;
        min-width: 280px;
        margin-bottom: 70px;
      }
      @media (min-width: 1440px) {
        max-width: 300px;
        height: 300px;
      }

      &__title {
        font-size: 24px;
        font-weight: 500;
        color: white; /* Darker text color */
        margin-top: unset;
        margin-left: 10px;

        @media (min-width: 1024px) {
          margin-left: 15px;
        }
      }

      &__description {
        font-size: 18px;
        color: white; /* Adjusted for readability */
        line-height: 1.4;
      }

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 15px; /* Space between icon/title and description */
        margin-bottom: 35px;
      }

      .icon {
        width: 40px; /* Icon size */
        height: 40px;
        margin-right: 15px; /* Space between the icon and the title */
      }
    }

    button {
      width: 100%;

      @media (min-width: 1024px) {
        width: 377px;
      }
    }

    .RelaxifyButton {
      @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        margin-bottom: 70px;
      }

      @media (min-width: 1440px) {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 70px;
        margin-right: 40px;
      }
    }
  }

  &__human__resources {
    background: #ECF0F3;
    position: relative;
    color: #193566;
    @media (min-width: 1024px) {
      padding-bottom: 50px;
    }

    &__yellow__dot {
      position: absolute;
      top: -48px;
      left: 7px;
      width: 50px;
      height: 50px;
      display: none;

      @media (min-width: 1024px) {
        display: block;
      }

      @media (min-width: 1440px) {
        width: 75px;
        height: 75px;
      }
    }

    &__elements__and__image {
      @media (min-width: 1024px) {
        display: flex;
        align-items: flex-start;

        img {
          width: 50%;
          margin-left: 20px;
        }

        .RelaxifyButton {
          width: 330px;
        }
      }
    }


    &__title__and__logo {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      padding-top: 30px;

      @media (min-width: 1440px) {
        padding-top: 70px;
        img {
          width: 70px;
          height: 70px;
        }
      }
    }

    &__title__and__logo__container {
      margin: 0 20px;
      @media (min-width: 1024px) {
        margin: 40px 80px;
      }

      @media (min-width: 1440px) {
        margin: 100px 280px 100px 280px;
      }
    }

    &__mobile__title {
      font-size: 32px;
      font-weight: 600;
      margin-left: 15px;
      @media (min-width: 1024px) {
        display: none;
      }
    }

    &__title {
      font-size: 32px;
      display: none;
      font-weight: 600;
      margin-left: 15px;
      @media (min-width: 1024px) {
        display: block;
        font-size: 32px;
        font-weight: 600;
        margin-left: 15px;
      }
      @media (min-width: 1440px) {
        font-size: 56px;
        margin-left: 30px;
      }
    }

    &__desc, &__second__desc {
      color: #193566;
      font-size: 18px;
      margin-top: 30px;

      @media (min-width: 1440px) {
        font-size: 20px;
      }
    }

    &__third__desc {
      margin: 60px 0 45px 40px;
      font-size: 20px;
    }

    &__main__image {
      display: flex;
      margin: 25px auto 0;

      @media (min-width: 1024px) {
        display: none;
      }
    }

    &__main__desktop__image {
      display: none;
      @media (min-width: 1024px) {
        display: block;
      }
    }

    &__element {

      .yellow-cirlce {
        height: 30px;
        width: 30px;
      }

      display: flex;
      padding-bottom: 40px;

      &__text {
        font-size: 20px;
        font-weight: 600;
        margin-left: 20px;
      }

    }

    .RelaxifyButton {
      margin-bottom: 20px;

      @media (min-width: 1440px) {
        margin-top: 30px;
      }

      button {
        width: 100%;
      }
    }
  }

  &__app__for__employees {
    position: relative;

    &__yellow__dot {
      @media (min-width: 1024px) {
        position: absolute;
        top: -25px;
        right: -222px;
        width: 50px;
        display: block;
      }

      @media (min-width: 1180px) {
        top: -60px;
        right: -78px;
      }

      @media (min-width: 1440px) {
        width: 75px;
        height: 75px;
        right: -278px;
        top: -74px;
      }
      display: none;
    }

    &__second__yellow__dot {
      @media (min-width: 1024px) {
        position: absolute;
        top: 1090px;
        left: -70px;
        width: 50px;
        display: block;
      }

      @media (min-width: 1440px) {
        width: 75px;
        height: 75px;
        top: 1290px;
        left: -276px;
      }

      display: none;
    }

    @media (min-width: 1024px) {
      margin: 40px 80px;
    }

    @media (min-width: 1440px) {
      margin: 50px 280px 100px 280px;
    }


    &__main__image {
      display: flex;
      margin: 0 auto;
    }

    &__first__part {
      @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        margin-top: 30px;
      }

      @media (min-width: 1440px) {
        align-items: center;
        margin-bottom: 100px;
      }
    }

    &__second__part {
      @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;

        &__third__phones__and__stores {
          @media (min-width: 1024px) {
            .RelaxifyHome__app__for__employees__main__image {
              width: 450px;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        &__purple__container__and__button {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      @media (min-width: 1440px) {
        justify-content: space-around;
        margin-bottom: 70px;
      }

      &__stores {
        margin-top: 20px;
        @media (min-width: 1024px) {
          margin-top: 65px;
        }
      }
    }

    &__mobile__button {
      @media (min-width: 1024px) {
        display: none;
      }
    }

    &__desktop__button {
      display: none;
      @media (min-width: 1024px) {
        display: block;
      }
    }

    color: #193566;
    margin: 30px 20px 0 20px;

    &__title__and__logo {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      padding-top: 30px;

      @media (min-width: 1440px) {
        margin-bottom: 70px;
        img {
          width: 70px;
          height: 70px;
        }
      }
    }

    &__title {
      font-size: 32px;
      font-weight: 600;
      margin-left: 15px;

      @media (min-width: 1440px) {
        font-size: 56px;
        margin-left: 30px;
      }
    }

    &__description {
      color: #193566;
      font-size: 18px;
      margin: 20px 0;
      text-align: center;

      @media (min-width: 1440px) {
        font-size: 20px;
        margin-bottom: 80px;
      }
    }

    &__purple__container {
      @media (min-width: 1024px) {
        margin-left: 50px;
      }

      @media (min-width: 1440px) {
        width: 485px;
        height: 461px;
      }
      color: white;
      margin-top: 40px;
      margin-bottom: 40px;
      background: #5358C2;
      border: 1px solid #FFC726;
      border-radius: 20px;
      padding: 20px;
      font-size: 18px;

      &__desktop__button {
        display: none;

        @media (min-width: 1024px) {
          display: block;
          width: 300px;
          margin-left: 50px;
        }

        @media (min-width: 1440px) {
          display: block;
          width: 300px;
          margin-left: unset;
        }
      }

      &__title {
        margin: 5px 30px 30px 40px;
        font-weight: 600;
        font-size: 24px;
      }

      &__desc {
        margin-left: 40px;
        margin-bottom: 25px;

        @media (min-width: 1440px) {
          font-size: 20px;
        }
      }
    }

    &__purple__element {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      img {
        height: 30px;
        width: 30px;
      }

      &__text {
        margin-left: 10px;
      }
    }

    .first-phones {
      width: 350px;

      @media (min-width: 1440px) {
        width: unset;
      }
    }

    &__logos__container {
      margin: 40px auto 30px auto;
      text-align: center;

      &__main__image {
        display: flex;
        margin: 0 auto 20px auto;
        @media (min-width: 1024px) {
          margin: 10px;
        }
      }

      &__member {
        font-size: 18px;
        letter-spacing: 1px;
        margin-bottom: 20px;

        @media (min-width: 1024px) {
          font-size: 20px;
          margin-top: 25px;
        }
      }

      &__members {
        @media (min-width: 1024px) {
          display: flex;
          justify-content: center;
        }
      }
    }

    @media (min-width: 1024px) {
      /* Target the first <img> element with the specified class */
      .RelaxifyHome__app__for__employees__logos__container .RelaxifyHome__app__for__employees__logos__container__main__image:first-of-type {
        margin: 0 auto;
      }

      /* Target the last <img> element with the specified class */
      .RelaxifyHome__app__for__employees__logos__container .RelaxifyHome__app__for__employees__logos__container__main__image:last-of-type {
        margin: 0 auto;
      }

    }


    button {
      width: 100%;
    }
  }

  &__last__part {
    color: #193566;
    background: #ECF0F3;
    position: relative;

    &__yellow__dot {
      display: none;

      @media (min-width: 1024px) {
        position: absolute;
        display: block;
        top: 491px;
        right: 0;
        width: 50px;
        height: 50px;
      }

      @media (min-width: 1180px) {
        top: 442px;
        right: 0;
      }

      @media (min-width: 1440px) {
        position: absolute;
        display: block;
        top: 672px;
        right: 1px;
        width: 75px;
        height: 75px;
      }
    }

    @media (min-width: 1024px) {
      padding: 40px 80px;
    }

    &__container {
      margin: 0 20px;

      @media (min-width: 1440px) {
        margin: 60px 200px 100px 200px;
      }

      &__title {
        font-size: 32px;
        font-weight: 600;
        margin-left: 15px;
        padding-top: 30px;
        margin-bottom: 35px;

        @media (min-width: 1440px) {
          font-size: 56px;
          padding-top: unset;
        }
      }

      &__elements {
        margin-top: 30px;

        @media (min-width: 1024px) {
          margin-top: unset;
        }

        &__wrapper {
          @media (min-width: 1024px) {
            display: flex;
            flex-direction: column;
            margin-left: 35px;
          }
        }
      }

      &__element {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &__text {
          font-size: 18px;
          margin-left: 20px;

          @media (min-width: 1440px) {
            font-size: 20px;
          }
        }
      }

      &__image {
        margin: 0 auto 50px auto;
        display: flex;

        @media (min-width: 1024px) {
          width: 450px;
        }

        @media (min-width: 1440px) {
          margin: 0 0 50px 15px;
        }
      }

      &__elements__and__image {
        @media (min-width: 1024px) {
          display: flex;
          flex-direction: row-reverse;
        }
        @media (min-width: 1440px) {
          justify-content: space-between;
        }
      }
    }

  }

  //.RelaxifyHome__roadmap__elements .RelaxifyHome__roadmap__element {
  //  grid-column: 2;
  //  --inlineP: 1.5rem;
  //  margin-inline: var(--inlineP);
  //  grid-row: span 2;
  //  grid-template-rows: min-content min-content min-content;
  //}

  &__carousel-list {
    margin-top: 40px;
    padding-bottom: 30px;
  }

  &__numbers {

    @media (min-width: 1024px) {
      margin-bottom: unset;

    }

    &__mobile {
      @media (min-width: 1024px) {
        display: none;
      }
    }

    &__desktop {
      @media (min-width: 1024px) {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        &__elements {
          display: flex;
          flex-direction: row;
          margin: 0 63px;
        }

        &__information__labels {
          flex: 40%;
        }
      }

      @media (min-width: 1440px) {
        &__elements {
          margin-left: 202px;
        }
      }

      @media (min-width: 1920px) {
        &__elements {
          margin-left: 273px;
        }
      }

      display: none;
    }

    margin-bottom: 90px;

    .RelaxifyButton {
      @media (min-width: 1024px) {
      }

      margin: 3px 20px 60px 20px;
      display: flex;

      button {
        width: 100%;
        height: 56px !important;
      }
    }

    &-items {
      @media (min-width: 1024px) {
        flex: 60%;
        margin-bottom: unset;
      }
      display: flex;
      flex-wrap: wrap;
      padding: 3px 20px;
      margin-bottom: 44px;
      justify-content: space-between;
    }

    &__image__and__title {
      display: flex;
    }

    &__item__labels {
      margin-left: 20px;
      align-items: end;
    }

    &-item {
      @media (min-width: 1024px) {
        flex: 50%;
        min-height: 180px;
      }
      min-height: 275px;
    }

    &-value {
      color: #193566;
      font-size: 36px;
      font-weight: 700;
      line-height: 47px;
      margin-top: 10px;
    }

    &-description {
      color: #193566;
      font-size: 16px;
      font-weight: 400;
      max-width: 140px;
    }
  }

  .RelaxifyHome__numbers .page-part-name {
    @media (min-width: 1024px) {
      margin: 80px 0 20px 83px;
    }

    @media (min-width: 1440px) {
      margin-left: 222px;
    }

    @media (min-width: 1920px) {
      margin-left: 293px;
    }

    margin-top: 100px;
  }

  .RelaxifyHome__numbers.title {
    @media (min-width: 768px) {
      width: unset;
    }

    @media (max-width: 1023px) {
      text-align: center;
      padding: 3px 0 0 0 !important;
      margin: 0 auto;
    }

    @media (min-width: 1024px) {
      width: 390px;
    }

    @media (min-width: 1440px) {
      font-size: 48px;
      width: unset;
    }

    width: 311px;
  }

  .RelaxifyHome__numbers.subtitle {
    width: 296px;
  }

  .RelaxifyHome__numbers.description {
    @media (min-width: 768px) {
      width: unset;
    }

    @media (max-width: 1023px) {
      text-align: center;
      padding: unset !important;
    }

    @media (min-width: 1440px) {
      font-size: 20px;
      max-width: 432px;
    }

    width: 367px;
    margin: 20px 20px 35px 20px;
    margin-top: 28px
  }

  &__articles-list {
    background: #E5E5E5 !important;
    padding-top: 40px;

    &__item {
      @media (min-width: 1440px) {
        background-size: 370px;
      }
    }

    @media (min-width: 1440px) {
      padding-top: 80px;
    }

    @media (min-width: 1024px) {
    }

    &__mobile {
      @media (min-width: 1024px) {
        display: none;
      }
    }

    &__desktop {
      @media (min-width: 1024px) {
        display: flex;
      }
      display: none;

      &__left__side {
        @media (min-width: 1024px) {
          padding: 0 20px;
        }

        @media (min-width: 1250px) {
          width: 50%;
          padding: 0 50px;
        }

        @media (min-width: 1440px) {
          padding-left: 222px;
        }
      }

      &__main__article {
        width: 100%;
        height: 315px;
        display: flex;
        flex-direction: column;

        @media (min-width: 1440px) {
          height: 366px;
          width: 582px;
        }

        &__title {
          cursor: pointer;

          @media (max-width: 1024px) {
            font-size: 20px;
            margin-top: 180px;
          }

          @media (min-width: 1440px) {
            margin-top: 220px;
          }

          font-size: 25px;
          line-height: 120%;
          font-weight: 700;
          margin-top: 155px;
          margin-left: -150px;
          margin-right: 20px;
          color: white;
          align-self: center;
          max-width: 350px;
        }

        &__description {
          margin: 15px 20px 0 -40px;
          color: white;
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 18.2px;
          align-self: center;
          max-width: 460px;
        }
      }

      &__next__articles {
        display: flex;
        flex-direction: row;
        margin-top: 55px;
        justify-content: center;

        @media (min-width: 1440px) {
          margin-top: 60px;
        }
      }

      &__next__article {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media (min-width: 1440px) {
          &:nth-child(1) {
            margin-right: 40px;
          }
        }

        &__title {
          font-size: 20px;
          line-height: 120%;
          font-weight: 700;
          color: #193566;
          max-width: 278px;
          min-height: 72px;
        }

        &__description,
        &__read__more {
          font-weight: 400;
          font-size: 16px;
          line-height: 130%;
          color: #193566;
          margin-top: 25px;
          max-width: 276px;
          min-height: 54px;

          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        &__read__more {
          color: #5358C2;
        }

        //&__description {
        //  margin-top: 25px;
        //}
      }
    }

    .RelaxifyButton {

      @media (max-width: 930px) {
        margin: 25px 60px 40px 60px;
      }
      @media (min-width: 1024px) {
        margin-bottom: 100px;
        button {
          width: 366px !important;
        }
      }

      @media (max-width: 450px) {
        margin: 25px 20px 40px 20px;
      }
      display: flex;
      margin: 25px 0 40px 0;

      button {
        width: 100%;
      }
    }
  }

  &__articles__item {
    width: 100%;
    height: 315px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;

    img {
      margin: 0 20px;
      width: 100%;
    }

    &__wrapper {
      width: 100%;
      display: flex;
    }

    &__title {
      @media (max-width: 390px) {
        font-size: 20px;
        margin-top: 160px;
      }

      @media (width: 393px) {
        font-size: 20px;
        margin-top: 150px;
      }

      @media (min-width: 650px) {
        align-self: center;
        margin-left: -165px;
      }

      font-size: 25px;
      line-height: 120%;
      font-weight: 700;
      margin-top: 145px;
      margin-left: 20px;
      margin-right: 20px;
      color: white;
      max-width: 340px;
    }

    &__description {
      @media (max-width: 390px) {
        font-size: 14px;
      }

      @media (min-width: 650px) {
        margin-left: -155px;
        align-self: center;
      }

      margin: 15px 20px 0 20px;
      color: white;
      font-weight: 400;
      font-size: 16px;
      line-height: 18.2px;
      max-width: 350px;
    }
  }

  .RelaxifyHome__articles.page-part-name {
    @media (min-width: 1024px) {
      margin: unset;
    }
  }

  .RelaxifyHome__articles.title {

    @media (max-width: 1023px) {
      text-align: center;
      padding: 3px 0 0 0 !important;
      margin: 0 auto;
    }

    @media (min-width: 1024px) {
      margin: 10px 0 0 0;
      padding: unset;
    }

    @media (min-width: 1440px) {
      font-size: 48px;
    }
  }

  .RelaxifyHome__articles.subtitle {
    width: 276px;
  }

  .RelaxifyHome__articles.description {

    @media (max-width: 1023px) {
      text-align: center;
    }

    @media (min-width: 1024px) {
      margin: 20px 0 60px 0;
      max-width: 400px;
    }

    @media (min-width: 1250px) {
      font-size: 20px;
      margin-top: 30px;
    }

    @media (min-width: 1440px) {
      font-size: 20px;
      margin-top: 30px;
      max-width: 445px;
    }

    margin-top: 40px;
    margin-bottom: 50px;
  }

  .RelaxifyHome__articles.title {
    @media (min-width: 768px) {
      max-width: 275px;
      margin-top: 15px;
      margin-bottom: 40px;
    }

    @media (min-width: 1250px) {
      font-size: 48px;
      max-width: 415px;
      margin-top: 35px;
    }

    max-width: 311px;
  }

  .v-btn__content {
    color: #193566;
  }

  .RelaxifyButton {
    button {
      @media (min-width: 1440px) {
        height: 56px !important;
        width: 366px;
      }
      height: 50px !important;
    }
  }

  &__media__and__collaboration {
    margin-top: 150px;
    background: white;
    padding-bottom: 40px;
  }

  &__media__elements,
  &__collaboration__elements {
    margin-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;

    @media (max-width: 1023px) {
    }
  }

  &__media__element,
  &__collaboration__element {
    margin-right: 40px;

    @media (max-width: 1023px) {
      margin-right: 15px;
      img {
        width: 113px;
      }
    }
  }

  .RelaxifyHome__media.page-part-name {
    text-align: center;
    padding-top: 80px;
  }

  .RelaxifyHome__media.title,
  .RelaxifyHome__collaboration.title {
    text-align: center;
  }

  .RelaxifyHome__collaboration.page-part-name {
    text-align: center;
  }

  .RelaxifyButton {
    @media (min-width: 1440px) {
      .v-btn__content {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .RelaxifyCarousel {
    .hooper-prev,
    .hooper-next {
      top: -192px !important;
      width: unset;
      height: unset;
      border-radius: unset;
      border: unset;
      display: inline-block;
      color: #193566;

      svg {
        width: 35px;
        height: 35px;
        color: #193566;
      }

      background: unset;
    }

    .hooper-next {

      @media (min-width: 360px) {
        right: -274px !important;
      }

      @media (min-width: 375px) {
        right: -289px !important;
      }

      @media (min-width: 390px) {
        right: -304px !important;
      }

      @media (min-width: 412px) {
        right: -325px !important;
      }

      @media (min-width: 414px) {
        right: -327px !important;
      }

      @media (min-width: 540px) {
        right: -452px !important;
      }

      @media (min-width: 768px) {
        right: -672px !important;
      }

      @media (min-width: 820px) {
        right: -732px !important;
      }

      @media (min-width: 912px) {
        right: -823px !important;
      }

      @media (min-width: 1024px) {
        right: -938px !important;
      }

      @media (min-width: 1280px) {
        right: -1193px !important;
      }

      @media (min-width: 1440px) {
        right: -1355px !important;
      }
      @media (min-width: 1570px) {
        right: -1455px !important;
      }

      right: -326px !important;
    }

    .hooper-prev {

      @media (min-width: 360px) {
        left: -15px
      }

      @media (min-width: 390px) {
        left: -15px
      }

      @media (min-width: 414px) {
        left: -17px
      }

      @media (min-width: 768px) {
        left: -15px
      }

      @media (min-width: 1024px) {
        left: -15px;
      }

      left: -10px;
    }

    path {
      color: #193566;
    }
  }

  .RelaxifyCarouselBox__comment {
    font-style: italic;
  }

  .home__carousel {
    margin-right: unset !important;
  }

  .home__articles {
    margin-right: unset !important;
    color: black

  }
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {

}
</style>
