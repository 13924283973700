import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueI18n from "vue-i18n";
import en from "../lang/en";
import Routes from "@/routes";
import VueRouter from 'vue-router';
import VueMeta from "vue-meta";


Vue.config.productionTip = false

const messages = {
  en,
}
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(VueMeta);


export const router = new VueRouter({
  routes: Routes,
  mode: 'history',
  scrollBehavior() {
    return {x: 0, y: 0}
  },
})

router.beforeEach((to, from, next) => {
  // Save the previous route in the browser's session storage
  sessionStorage.setItem('previousRoute', from.path);

  if (window.location.hash === '#/awards/') {
    window.location.replace('/awards/');
  } else {
    next();
  }

  if (window.location.hash === '#/awards') {
    window.location.replace('/awards/');
  } else {
    next();
  }

  if (window.location.hash === '#awards') {
    window.location.replace('/awards/');
  } else {
    next();
  }
  if (window.location.hash === '#awards/') {
    window.location.replace('/awards/');
  } else {
    next();
  }

  next(); // Continue with the navigation
});


const i18n = new VueI18n({
  locale: 'en',
  messages
})

new Vue({
  vuetify,
  i18n,
  render: h => h(App),
  router
}).$mount('#app')
