<template>
  <div class="RelaxifyAboutTheApp">
    <RelaxifyHeader/>
    <div class="RelaxifyAboutTheApp__first-box">
      <h1 class="RelaxifyAboutTheApp__first-box title "> {{ aboutTheAppStartPageTitle }} </h1>
      <div class="RelaxifyAboutTheApp__first-box description"> {{ aboutTheAppStartPageData.mobile_description }}</div>
      <a href="https://onelink.to/x3rfwb">
        <RelaxifyButton color="primary" class="RelaxifyAboutTheApp__try-for-free-button">
          {{ aboutTheAppStartPageData.buttonTitle }}
        </RelaxifyButton>
      </a>
    </div>
    <div class="RelaxifyAboutTheApp__first-box__desktop">
      <h1 class="RelaxifyAboutTheApp__first-box__desktop title "> {{ aboutTheAppStartPageTitle }} </h1>
      <div class="RelaxifyAboutTheApp__first-box__desktop__description_and_button">
        <div class="RelaxifyAboutTheApp__first-box__desktop description"> {{
            aboutTheAppStartPageFirstDesktopDescription
          }}
        </div>
        <div class="RelaxifyAboutTheApp__first-box__desktop description">
          {{ aboutTheAppStartPageSecondDesktopDescription }}
        </div>
        <RelaxifyButton color="primary" @click="goToFooterContacts('relaxifyFooter')"
                        class="RelaxifyAboutTheApp__try-for-free-button"> {{
            $t('RelaxifyButton.title')
          }}
        </RelaxifyButton>
      </div>
    </div>
    <div class="RelaxifyAboutTheApp__methodology">
      <div class="RelaxifyAboutTheApp__methodology__image__desktop">
        <img loading="lazy" alt="Methodology Background image desktop" :src="methodologyDesktopImage"/>
      </div>
      <div class="RelaxifyAboutTheApp__methodology--label"> {{ methodologyPartName }}</div>
      <h2 class="RelaxifyAboutTheApp__methodology title "> {{ methodologyTitle }} </h2>
      <div class="RelaxifyAboutTheApp__methodology description"> {{ methodologyDescription }}</div>

      <div class="RelaxifyAboutTheApp__methodology__items__mobile">
        <div v-for="(item, index) in methodologyItems" :key=index
             class="RelaxifyAboutTheApp__methodology__item__mobile">
          <div class="RelaxifyAboutTheApp__methodology__item__circle"> {{ index + 1 }}</div>
          <div class="RelaxifyAboutTheApp__methodology__item__title__and__description">
            <h3 :style="{'before:': 'red'}"> {{ item.title }}</h3>
            <div class="RelaxifyAboutTheApp__methodology__item__description"> {{ item.description }}</div>
            <img loading="lazy" width="199" height="168" :src="item.path" :alt="item.title"/>
          </div>
        </div>

      </div>
      <div class="RelaxifyAboutTheApp__methodology__items__desktop">
        <div v-for="(item, index) in methodologyItems" :key=index
             class="RelaxifyAboutTheApp__methodology__item__desktop">
          <div class="RelaxifyAboutTheApp__methodology__item__title__and__description">
            <h3 :style="{'before:': 'red'}"> {{ item.title }}</h3>
            <div class="RelaxifyAboutTheApp__methodology__item__description"> {{ item.description }}</div>
          </div>
          <div class="RelaxifyAboutTheApp__methodology__item__circle"> {{ index + 1 }}</div>
          <img loading="lazy" width="426" height="347" :src="item.path" :alt="item.title"/>
        </div>

      </div>
    </div>
    <div class="RelaxifyAboutTheApp__science">
      <div class="RelaxifyAboutTheApp__science--part-name">
        {{ scienceDataPartName }}
      </div>
      <h2 class="RelaxifyAboutTheApp__science--title title">
        {{ scienceDataTitle }}
      </h2>
      <div class="RelaxifyAboutTheApp__science--description">
        {{ scienceDataDescription }}
      </div>
      <div class="RelaxifyAboutTheApp__science--second-description">
        {{ scienceDataSecondDescription }}
      </div>

      <div class="RelaxifyAboutTheApp__science--wellbeing-elements">
        <div class="RelaxifyAboutTheApp__science--wellbeing-element"
             v-for="(item, index) in scienceDataWellBeingElements" :key="index">
          <div class="RelaxifyAboutTheApp__science--wellbeing-element--image-wrapper">
            <img loading="lazy" :src="item.imagePath" :alt="item.name">
          </div>
          <div class="RelaxifyAboutTheApp__science--wellbeing-element--title"> {{ item.name }}</div>
        </div>
      </div>

      <a v-if="currentWindowWidth <1024" href="https://onelink.to/x3rfwb">
        <RelaxifyButton color="primary" class="RelaxifyAboutTheApp__try-for-free-button"> {{ scienceDataButtonTitle }}
        </RelaxifyButton>
      </a>
      <RelaxifyButton v-else @click="goToFooterContacts('relaxifyFooter')" color="primary"
                      class="RelaxifyAboutTheApp__try-for-free-button">
        {{ scienceDataButtonTitle }}
      </RelaxifyButton>
    </div>
    <div class="RelaxifyAboutTheApp__gamification">
      <div class="RelaxifyAboutTheApp__gamification--name"> {{ gamificationPartName }}</div>
      <h2 class="RelaxifyAboutTheApp__gamification--title title"> {{ gamificationTitle }}</h2>
      <div class="RelaxifyAboutTheApp__gamification--description description"> {{ gamificationDescription }}</div>
      <div class="RelaxifyAboutTheApp__gamification__image__and__elements">
        <div class="RelaxifyAboutTheApp__gamification__image">
          <img loading="lazy" :src="gamificationImage" :alt="gamificationPartName"/>
        </div>
        <div class="RelaxifyAboutTheApp__gamification__elements">
          <div class="RelaxifyAboutTheApp__gamification__element" v-for="(element, index) in gamificationElements"
               :key="index">
            <div class="RelaxifyAboutTheApp__gamification__element--first-row">
              <img loading="lazy" width="37" height="36" :src="gamificationCircleImage" alt="circle image"/>
              <div class="RelaxifyAboutTheApp__first-row--title"> {{ element.title }}</div>
            </div>
            <div class="RelaxifyAboutTheApp__gamification-element--description"> {{ element.description }}</div>
          </div>
          <a v-if="currentWindowWidth < 1024" href="https://onelink.to/x3rfwb">
            <RelaxifyButton color="primary" class="RelaxifyAboutTheApp__try-for-free-button"> {{
                gamificationButtonTitle
              }}
            </RelaxifyButton>
          </a>
          <RelaxifyButton v-else @click="goToFooterContacts('relaxifyFooter')" color="primary"
                          class="RelaxifyAboutTheApp__try-for-free-button">
            {{ gamificationButtonTitle }}
          </RelaxifyButton>
        </div>
      </div>
    </div>
    <RelaxifyFooter/>
  </div>
</template>

<script>
import RelaxifyHeader from "@/components/RelaxifyHeader";
import RelaxifyButton from "@/components/RelaxifyButton";
import RelaxifyFooter from "@/components/RelaxifyFooter";
import resources from "@/resources";
import {
  getAboutTheAppData, getAboutTheAppGamificationButtonTitle,
  getAboutTheAppGamificationCircleImage,
  getAboutTheAppGamificationData,
  getAboutTheAppGamificationDescription,
  getAboutTheAppGamificationElements,
  getAboutTheAppGamificationImage,
  getAboutTheAppGamificationPartName,
  getAboutTheAppGamificationTitle,
  getAboutTheAppMethodologyData,
  getAboutTheAppMethodologyDescription,
  getAboutTheAppMethodologyDesktopBackground,
  getAboutTheAppMethodologyItems,
  getAboutTheAppMethodologyPartName,
  getAboutTheAppMethodologyTitle,
  getAboutTheAppSciencePartData,
  getAboutTheAppSciencePartDataButtonTitle,
  getAboutTheAppSciencePartDataDescription,
  getAboutTheAppSciencePartDataPartName,
  getAboutTheAppSciencePartDataSecondDescription,
  getAboutTheAppSciencePartDataTitle,
  getAboutTheAppSciencePartDataWellBeingElements,
  getAboutTheAppStartPageData,
  getAboutTheAppStartPageFirstDesktopDescription, getAboutTheAppStartPageSecondDesktopDescription,
  getAboutTheAppStartPageTitle,
} from "@/getters/aboutTheAppGetters";
import {goToFooterContacts} from "@/helpers/helpers";
import {computed} from "vue";

export default {
  name: "RelaxifyAboutTheApp.vue",
  components: {RelaxifyFooter, RelaxifyHeader, RelaxifyButton},
  metaInfo() {
    return {
      title: 'Health and Wellbeing App - Personalized Daily Well-being program | RelaxifyApp',
      meta: [
        {
          name: 'description',
          content: 'Are You Ready to Get Better with RelaxifyApp? Take our well-being survey and get a personalized well-being program with daily games, challenges, and weekly targets!'
        },
        {
          name: 'robots',
          content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
        },
        {
          property: 'fb:app_id',
          content: '833032110773133'
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:title',
          content: 'Health and Wellbeing App - Personalized Daily Well-being program | RelaxifyApp'
        },
        {
          property: 'og:site_name',
          content: 'RelaxifyApp'
        }
      ],
      link: [
        {rel: 'canonical', href: window.location.href}
      ]
    }
  },
  setup() {
    const currentWindowWidth = computed(() => window.innerWidth);

    // const getGamificationData = (aboutTheAppData) => {
    //   return aboutTheAppData.page.parts.find(item => item.partName === gamification);
    // }

    const aboutTheAppData = getAboutTheAppData(resources);


    // Start page variables
    const aboutTheAppStartPageData = getAboutTheAppStartPageData(aboutTheAppData);
    const aboutTheAppStartPageTitle = getAboutTheAppStartPageTitle(aboutTheAppStartPageData);
    const aboutTheAppStartPageFirstDesktopDescription = getAboutTheAppStartPageFirstDesktopDescription(aboutTheAppStartPageData);
    const aboutTheAppStartPageSecondDesktopDescription = getAboutTheAppStartPageSecondDesktopDescription(aboutTheAppStartPageData);

    // Methodology part of the page data variables
    const methodologyData = getAboutTheAppMethodologyData(aboutTheAppData);
    const methodologyPartName = getAboutTheAppMethodologyPartName(methodologyData);
    const methodologyTitle = getAboutTheAppMethodologyTitle(methodologyData);
    const methodologyDescription = getAboutTheAppMethodologyDescription(methodologyData);
    const methodologyDesktopImage = getAboutTheAppMethodologyDesktopBackground(methodologyData);
    const methodologyItems = getAboutTheAppMethodologyItems(methodologyData);

    // Science part of the page data variables
    const scienceData = getAboutTheAppSciencePartData(aboutTheAppData);
    const scienceDataPartName = getAboutTheAppSciencePartDataPartName(scienceData);
    const scienceDataTitle = getAboutTheAppSciencePartDataTitle(scienceData);
    const scienceDataDescription = getAboutTheAppSciencePartDataDescription(scienceData);
    const scienceDataSecondDescription = getAboutTheAppSciencePartDataSecondDescription(scienceData);
    const scienceDataWellBeingElements = getAboutTheAppSciencePartDataWellBeingElements(scienceData);
    const scienceDataButtonTitle = getAboutTheAppSciencePartDataButtonTitle(scienceData);

    // Gamification part of the page data variables
    const gamificationData = getAboutTheAppGamificationData(aboutTheAppData);
    const gamificationPartName = getAboutTheAppGamificationPartName(gamificationData);
    const gamificationTitle = getAboutTheAppGamificationTitle(gamificationData);
    const gamificationDescription = getAboutTheAppGamificationDescription(gamificationData);
    const gamificationImage = getAboutTheAppGamificationImage(gamificationData);
    const gamificationCircleImage = getAboutTheAppGamificationCircleImage(gamificationData);
    const gamificationElements = getAboutTheAppGamificationElements(gamificationData);
    const gamificationButtonTitle = getAboutTheAppGamificationButtonTitle(gamificationData);


    return {
      methodologyItems,
      resources,
      aboutTheAppData,
      aboutTheAppStartPageData,
      aboutTheAppStartPageTitle,
      aboutTheAppStartPageFirstDesktopDescription,
      aboutTheAppStartPageSecondDesktopDescription,
      methodologyPartName,
      methodologyTitle,
      methodologyDescription,
      methodologyDesktopImage,
      scienceData,
      scienceDataPartName,
      scienceDataTitle,
      scienceDataDescription,
      scienceDataSecondDescription,
      scienceDataWellBeingElements,
      gamificationData,
      goToFooterContacts,
      currentWindowWidth,
      scienceDataButtonTitle,
      gamificationPartName,
      gamificationTitle,
      gamificationDescription,
      gamificationImage,
      gamificationCircleImage,
      gamificationElements,
      gamificationButtonTitle
    }
  }
}
</script>

<style lang="scss">

.RelaxifyAboutTheApp {

  .title {
    color: #193566;
    font-size: 32px;
    margin: 70px 20px 0 20px;
    max-width: 360px;
    font-weight: 700;
    line-height: 120%;
  }

  .description {
    color: #193566;
    font-size: 18px;
    margin: 35px 20px 50px 20px;
    font-weight: 400;
    line-height: 150%;
    max-width: 370px;
  }

  &__first-box {
    @media (min-width: 1440px) {
      display: none;
    }

    &__desktop {
      @media (min-width: 1440px) {
        display: flex;
        justify-content: center;
        margin-top: 100px;

        &__description_and_button {
          min-width: 585px;
        }
      }

      display: none;
    }

    &__labels {
      @media (min-width: 1440px) {
        display: flex;
      }
    }

    .RelaxifyButton.RelaxifyAboutTheApp__try-for-free-button {
      margin: 0 20px;

      button {
        width: 100%;
      }
    }
  }

  .RelaxifyAboutTheApp__first-box__desktop.title {
    @media (min-width: 1440px) {
      font-size: 56px;
      line-height: 110%;
      max-width: 510px;
      margin-top: unset;
      margin-left: unset;
      margin-right: 100px;
    }
  }

  .RelaxifyAboutTheApp__first-box__desktop.description {
    @media (min-width: 1440px) {
      font-size: 20px;
      line-height: 150%;
      margin-top: unset;
      margin-left: unset;
      min-width: 580px;
      margin-right: unset;
    }
  }

  &__methodology {
    img {
      margin: 80px 0;
    }

    &__image__mobile {
      @media (min-width: 1440px) {
        display: none
      }
    }

    &__image__desktop {
      img {
        width: 100%;
      }

      @media (min-width: 1440px) {
        display: block;

        img {
          margin-bottom: 100px;
        }
      }

    }

    &--label {
      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 22px;
      }

      display: flex;
      align-items: center;
      flex-direction: column;
      color: #718EC1;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
    }

    &__items__mobile {
      counter-reset: numbers 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 25px;

      @media (min-width: 1440px) {
        display: none;
      }
    }

    h3 {
      @media (max-width: 1023px) {
        font-size: 32px
      }
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      color: #193566;
      max-width: 306px;
      margin: 0 0 40px 0;
    }

    &__items__desktop {
      @media (min-width: 1440px) {
        display: flex;
      }
      margin-top: 150px;
      display: none;
      justify-content: center;
      flex-wrap: wrap;

      img {
        margin: unset !important;
      }
    }

    &__item__mobile {
      display: flex;
      z-index: 10;
      align-items: baseline;
      position: relative;

      h3 {
        margin: 0 0 30px 0;
      }

      &::before {
        @media (max-width: 1023px) {
          content: "";
          display: block;
          position: absolute;
          top: 48px;
          left: 14px;
          border: 2px solid #193566;
          opacity: 0.3;
          width: 1px;
          height: 95%;
        }

        @media (max-width: 360px) {
          left: 12px;
        }
      }

      &:nth-child(4) {
        &::before {
          display: none;
        }
      }
    }

    &__item__desktop {
      z-index: 10;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      margin-bottom: 120px;
      position: relative;

      &:nth-child(2),
      &:nth-child(4) {
        flex-direction: row-reverse;
      }

      &::before {
        @media (min-width: 1024px) {
          content: "";
          display: block;
          position: absolute;
          top: 10.8%;
          left: 49.96%;
          border: 2px solid #193566;
          opacity: 0.3;
          width: 1px;
          height: 124%;
        }
      }

      &:nth-child(4) {
        &::before {
          display: none !important;
        }
      }
    }

    &__item__title__and__description {
      @media (max-width: 1023px) {
        margin-left: 20px;
      }
    }

    &__item__description {
      @media (min-width: 1440px) {
        font-size: 20px;
        max-width: 426px;
      }

      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #193566;
      max-width: 296px;
    }

    &__item__circle {
      @media (max-width: 1023px) {
        width: 32px;
        height: 32px;
        font-size: 21px;
      }

      width: 38px;
      height: 38px;
      border-radius: 50%;
      background: #FFC726;
      text-align: center;
      font-size: 24px;
      color: #193566;
      font-weight: 700;
    }
  }

  .RelaxifyAboutTheApp__methodology.title {
    @media (min-width: 1440px) {
      font-size: 48px;
      margin: 15px auto 25px auto;
      max-width: 630px;
    }

    text-align: center;
    margin-top: 20px;
  }

  .RelaxifyAboutTheApp__methodology.description {
    @media (min-width: 1440px) {
      font-size: 20px;
      line-height: 30px;
      margin: 0 auto;
      max-width: 691px;
    }

    text-align: center;
    max-width: 345px;
    margin-bottom: 80px;
  }

  &__science {
    @media (min-width: 1440px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    background: #5358C2;

    &--part-name {
      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 22px;
      }
      text-align: center;
      color: #FFFFFF;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
      padding-top: 80px;
    }

    &--title {
      @media (min-width: 1440px) {
        font-size: 48px !important;
        margin: 15px auto 35px auto !important;
        min-width: 630px !important;
      }

      @media (max-width: 490px) {
        margin: 20px auto 20px auto !important;
      }

      color: #FFFFFF !important;
      text-align: center;
      margin: 20px 45px 20px 45px !important;
      width: 315px;
      line-height: unset !important;
    }

    &--description {
      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 30px;
        margin: 0 auto;
        max-width: 691px;
      }

      color: #FFFFFF;
      text-align: center;
      margin: 0 auto;
      max-width: 344px;
    }

    &--second-description {
      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 30px;
        margin: 20px auto 60px auto;
        max-width: 691px;
      }

      margin: 20px auto 60px auto;
      color: #FFFFFF;
      text-align: center;
      max-width: 250px;
    }

    &--wellbeing-elements {
      @media (min-width: 1440px) {
        margin: 0 150px;
      }

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    &--wellbeing-element {
      @media (min-width: 1440px) {
        flex: 30%;
        height: 214px;
        max-width: 311px;
        margin-bottom: 55px;
      }

      @media (min-width: 1700px) {
        max-width: 420px;
      }

      border: 1px solid #FFFFFF;
      border-radius: 10px;
      width: 170px;
      height: 188px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 30px;

      &--title {
        color: #FFFFFF;
        text-align: center;
        margin: 0 20px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .RelaxifyButton {
      @media (min-width: 1440px) {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }
      margin: 0 20px;
      padding-bottom: 80px;

      button {
        width: 100%;
        height: 56px !important;
        margin-top: 35px;
      }
    }
  }

  &__gamification {
    @media (min-width: 1440px) {
      padding-top: 40px;
      padding-bottom: 60px;
      margin-left: 100px;
    }

    &__image__and__elements {
      @media (min-width: 1440px) {
        display: flex;
        flex-direction: row-reverse;
        margin-left: 100px;
        padding-top: 50px;
      }
    }

    &__image {
      flex: 50%;
      @media (min-width: 1440px) {
        img {
          width: 538px;
          height: 683px;
        }
      }
    }

    background: white;

    &--name {
      @media (max-width: 1023px) {
        text-align: center;
      }
      @media (min-width: 1440px) {
        margin-left: 100px;
        font-size: 20px;
        line-height: 30px;
      }
      margin-top: 80px;
      margin-bottom: 20px;
      margin-left: 20px;
      color: #718EC1;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
    }

    &--title {

      @media (max-width: 1023px) {
        margin: 20px auto 35px auto !important;
        text-align: center !important;
      }

      @media (min-width: 1440px) {
        margin-left: 100px !important;
        font-size: 48px !important;;
        line-height: 110% !important;
        max-width: 890px !important;
      }

      margin-top: 20px !important;
      margin-bottom: 35px !important;
    }

    &--description {

      @media (max-width: 1023px) {
        text-align: center !important;
      }

      @media (min-width: 1440px) {
        font-size: 20px !important;;
        line-height: 150% !important;
        margin-left: 100px !important;
        max-width: 795px !important;
      }

      max-width: 365px !important;
      margin-bottom: 40px !important;
    }

    &__elements {
      .RelaxifyButton {
        margin-top: 25px !important;
      }

      @media (min-width: 1440px) {
        margin: unset;
        flex: 50%;

        RelaxifyButton {
          margin-top: unset !important;
        }
      }

      margin: 50px 20px 80px 20px;
    }

    &__element {
      &--first-row {
        @media (min-width: 1440px) {
          margin-top: unset;
        }

        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 40px;
      }
    }

    &-element--description {
      @media (min-width: 1440px) {
        max-width: 440px;
        margin-bottom: 30px;
      }

      color: #193566;
      font-weight: 400;
      line-height: 27px;
      font-size: 18px;
      margin-left: 53px;
      margin-top: 7px;
    }

    .RelaxifyButton {
      @media (min-width: 1440px) {
        margin-top: 50px;
      }

      margin: 0 20px 85px 20px;

      button {
        width: 100%;
        height: 56px !important;
      }
    }
  }

  &__first-row--title {
    color: #193566;
    font-weight: 540;
    font-size: 24px;
    line-height: 31.25px;
    margin-left: 15px;
  }

  a {
    text-decoration: none;
  }

  .RelaxifyButton {
    button {
      @media (min-width: 1440px) {
        width: 366px;
        height: 56px !important;
      }

      .v-btn__content {
        color: #193566;
      }
    }
  }

  .RelaxifyHeader {
    .RelaxifyButton {
      button {
        height: 40px !important;
      }
    }
  }
}
</style>
