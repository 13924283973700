<template>
  <div class="RelaxifyAwards">
    <RelaxifyHeader/>
    <div class="RelaxifyAwards__first-part">
      <div class="RelaxifyAwards__first-part__labels">
        <h1 v-html="awardsPageFirstPartTitle" class="RelaxifyAwards__first-box title"/>
      </div>
    </div>

    <div class="RelaxifyAwards__second_part">
      <div class="RelaxifyAwards__second_part--title "> {{ awardsPageSecondPart.firstLineTitle }}</div>
      <div class="RelaxifyAwards__second_part__main__image__wrapper">
        <img loading="lazy" :src="awardsPageSecondPart.mainImage.image" :alt="awardsPageSecondPart.mainImage.name"/>
      </div>
      <div class="RelaxifyAwards__second_part__description"> {{ awardsPageSecondPart.secondLineTitle }}</div>
      <div class="RelaxifyAwards__second_part__logos">
        <div v-for="(element, index) in awardsPageSecondPart.logos" :key="index"
             class="RelaxifyAwards__second_part__logos__element">
          <img loading="lazy" :src="element.image" :alt="element.name"/>
        </div>
      </div>
    </div>
    <div class="RelaxifyAwards__third_part">
      <div class="RelaxifyAwards__third_part__yellow__part">
        <p class="RelaxifyAwards__third_part__title "> {{ awardsPageThirdPart.yellowRectangle.title }} </p>
        <p class="RelaxifyAwards__third_part__description "> {{ awardsPageThirdPart.yellowRectangle.desc }} </p>
      </div>
      <p class="RelaxifyAwards__third_part__elements__title "> {{ awardsPageThirdPart.greyAwards.title }} </p>
      <div class="RelaxifyAwards__third_part__elements__container">
        <div v-for="(element, index) in awardsPageThirdPart.greyAwards.elements" :key="index"
             :class="{'last-element': index === awardsPageThirdPart.greyAwards.elements.length - 1, 'RelaxifyAwards__third_part__element': true}">
          <div class="header">
            <img loading="lazy" :src="element.logo.image" :alt="element.logo.name"/>
            <h3 class="RelaxifyAwards__third_part__element__title">{{ element.title }}</h3>
          </div>
          <div class="RelaxifyAwards__third_part__element__image__and__text">
            <h3 class="RelaxifyAwards__third_part__element__title">{{ element.title }}</h3>
            <p class="RelaxifyAwards__third_part__element__description">
              {{ element.desc }}
            </p>
          </div>

          <img v-if="index === awardsPageThirdPart.greyAwards.elements.length - 1"
               class="last-element-image mobile-phone"
               loading="lazy"
               :src="awardsPageThirdPart.greyAwards.phones.image"
               :alt="awardsPageThirdPart.greyAwards.phones.name"/>
          <img v-if="index === awardsPageThirdPart.greyAwards.elements.length - 1"
               class="last-element-image desktop-phone"
               loading="lazy"
               :src="awardsPageThirdPart.greyAwards.phonesDesktop.image"
               :alt="awardsPageThirdPart.greyAwards.phonesDesktop.name"/>
        </div>
      </div>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5R_GrrcJyNgrFOlZmpnIyrqLrDXo43C_jQTlXLdW_RnwzIw/viewform">
        <RelaxifyButton color="primary">
          {{ awardsPageThirdPart.greyAwards.button.title }}
        </RelaxifyButton>
      </a>

      <div class="RelaxifyAwards__third_part__grey__part">
        <p class="RelaxifyAwards__third_part__description "> {{ awardsPageThirdPart.greyAwards.lastElement.title }} </p>
      </div>
    </div>

    <div class="RelaxifyAwards__fourth_part">
      <div class="RelaxifyAwards__fourth_part__beginning">
        <img loading="lazy" :src="awardsPageFourthPart.firstIcon.image" :alt="awardsPageFourthPart.firstIcon.title"/>
        <h3 class="RelaxifyAwards__fourth_part__beginning__title">{{ awardsPageFourthPart.title }}</h3>
      </div>
      <div class="RelaxifyAwards__fourth_part__measures">
        <p> {{ awardsPageFourthPart.measuresFirstTitle }}</p>

        <div class="RelaxifyAwards__fourth_part__elements__container">
          <div v-for="(element, index) in awardsPageFourthPart.measures.slice(0,3)" :key="index"
               class="RelaxifyAwards__fourth_part__measures__element">
            <div class="header">
              <img loading="lazy" :src="element.logo.image" :alt="element.logo.name"/>
              <h3 class="RelaxifyAwards__fourth_part__measures__element__title">{{ element.title }}</h3>
            </div>
            <p class="RelaxifyAwards__fourth_part__measures__element__description">
              {{ element.desc }}
            </p>
            <img class="badge"
                 loading="lazy"
                 :src="awardsPageFourthPart.markImage.image"
                 :alt="awardsPageFourthPart.markImage.title"/>
          </div>
        </div>

        <p> {{ awardsPageFourthPart.measuresSecondTitle }}</p>

        <div class="RelaxifyAwards__fourth_part__elements__container">
          <div v-for="(element, index) in awardsPageFourthPart.measures.slice(3,6)" :key="index"
               class="RelaxifyAwards__fourth_part__measures__element">
            <div class="header">
              <img loading="lazy" :src="element.logo.image" :alt="element.logo.name"/>
              <h3 class="RelaxifyAwards__fourth_part__measures__element__title">{{ element.title }}</h3>
            </div>
            <p class="RelaxifyAwards__fourth_part__measures__element__description">
              {{ element.desc }}
            </p>
            <img class="badge"
                 loading="lazy"
                 :src="awardsPageFourthPart.markImage.image"
                 :alt="awardsPageFourthPart.markImage.title"/>
          </div>
        </div>


      </div>
    </div>

    <div class="RelaxifyAwards__fifth_part">
      <h3 class="RelaxifyAwards__fifth_part__title">{{ awardsPageFifthPart.title }}</h3>

      <div class="RelaxifyAwards__fifth_part__elements__container">
        <div v-for="(element, index) in awardsPageFifthPart.processItems" :key="index"
             class="RelaxifyAwards__fifth_part__element">

          <img loading="lazy" :src="element.logo.image" :alt="element.logo.name"/>
          <h3 class="RelaxifyAwards__fifth_part__element__title">{{ element.title }}</h3>

          <p v-html="element.desc" class="RelaxifyAwards__fifth_part__element__description"/>
        </div>
      </div>
    </div>

    <div class="RelaxifyAwards__sixth_part">
      <h3 class="RelaxifyAwards__sixth_part__title">{{ awardsPageSixthPart.title }}</h3>

      <div class="RelaxifyAwards__sixth_part__elements__container">
        <div class="RelaxifyAwards__sixth_part__elements__greyContainer list-box">
          <div class="RelaxifyAwards__sixt_part__greyElement "
               v-for="(element, index) in awardsPageSixthPart.greyCompanies" :key="index">

            <p class="RelaxifyAwards__sixt_part__greyElement__description">
              {{ element }}
            </p>
          </div>
        </div>

        <div class="RelaxifyAwards__sixth_part__elements__blueContainer list-box">
          <div class="RelaxifyAwards__sixt_part__blueElement"
               v-for="(element, index) in awardsPageSixthPart.blueCompanies" :key="index">

            <p class="RelaxifyAwards__sixt_part__blueElement__description">
              {{ element }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="RelaxifyAwards__first-part">
      <div class="RelaxifyAwards__first-part__labels">
        <h1 v-html="awardsPageFirstPartTitle" class="RelaxifyAwards__first-box title footer_part "/>
      </div>
    </div>

    <div class="RelaxifyAwards__apply__participation__part">
      <div class="RelaxifyAwards__apply__participation__part__yellow_container">
        <div class="RelaxifyAwards__apply__participation__part__yellow_container__firstPart">
          <div class="RelaxifyAwards__apply__participation__part__yellow_container__firstPart__titles">
            <p class="RelaxifyAwards__apply__participation__part__yellow_container__priceTitle">
              {{ awardsPageLastPart.title }} </p>
            <p class="RelaxifyAwards__apply__participation__part__yellow_container__price"> {{
                awardsPageLastPart.price
              }}</p>
          </div>
          <div class="RelaxifyAwards__apply__participation__part__yellow_container__firstPart__image__wrapper>">
            <img loading="lazy" :src="awardsPageLastPart.logo.image" :alt="awardsPageLastPart.logo.name"/>
          </div>
        </div>

        <div class="RelaxifyAwards__apply__participation__part__yellow_container__secondPart">
          <p class="RelaxifyAwards__apply__participation__part__yellow_container__secondPart__title">
            {{ awardsPageLastPart.until }} </p>
        </div>
      </div>
    </div>
    <p class="RelaxifyAwards__apply__participation__part__desc">
      {{ awardsPageLastPart.desc }}
    </p>

    <RelaxifyAwardsFooter/>
  </div>
</template>

<script>
import RelaxifyHeader from "@/components/RelaxifyHeader";
import {
  getAboutUsPageData, getAboutUsFirstBoxData,
  getAboutUsOurDriveData, getAboutUsTogetherData, getAboutUsTeamMembers, getAboutUsContactUs
} from "@/getters/aboutUsGetters";
import {goToFooterContacts, goToGetInTouchForm} from "@/helpers/helpers";
import resources from "@/resources";
import {computed, onMounted} from "vue";
import {
  getAwardsPageData,
  getAwardsPageFirstPartTitle, getAwardsPageFourthPart,
  getAwardsPageSecondPart,
  getAwardsPageThirdPart,
  getAwardsPageFifthPart,
  getAwardsPageSixthPart, getAwardsPageLastPart
} from "@/getters/awardsPageGetters";
import RelaxifyButton from "@/components/RelaxifyButton.vue";
import RelaxifyAwardsFooter from "@/components/RelaxifyAwardsFooter.vue";


export default {
  name: "RelaxifyAwards",
  components: {RelaxifyButton, RelaxifyAwardsFooter, RelaxifyHeader},
  metaInfo() {
    return {
      title: 'Work-life balance awards',
      meta: [
        {
          name: 'description',
          content: 'We want to help people achieve better emotional, social, cognitive, and physical performance through inner peace and balance. And we want to make it as fun and engaging as it could be!'
        },
        {
          name: 'robots',
          content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
        },
        {
          property: 'fb:app_id',
          content: '833032110773133'
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:title',
          content: 'Fight Depression, Anxiety, PTS, and Burnout Worldwide| RelaxifyApp'
        },
        {
          property: 'og:site_name',
          content: 'RelaxifyApp'
        }
      ],
      link: [
        {rel: 'canonical', href: window.location.href}
      ]
    }
  },
  setup() {
    const currentWindowWidth = computed(() => window.innerWidth);
    const aboutUsPageData = getAboutUsPageData(resources);
    const awardsPageData = getAwardsPageData(resources);
    const awardsPageSecondPart = getAwardsPageSecondPart(awardsPageData);
    const awardsPageThirdPart = getAwardsPageThirdPart(awardsPageData);
    const awardsPageFourthPart = getAwardsPageFourthPart(awardsPageData);
    const awardsPageFifthPart = getAwardsPageFifthPart(awardsPageData);
    const awardsPageSixthPart = getAwardsPageSixthPart(awardsPageData);
    const awardsPageLastPart = getAwardsPageLastPart(awardsPageData);
    const awardsTitleParts = getAwardsPageFirstPartTitle(awardsPageData)?.title || [];
    const awardsPageFirstPartTitle = awardsTitleParts.map((part, index) => {
      let fontSize;
      let textShadow = '';
      let text = part.text;

      if (text === '-') {
        text = `&#8209;`; // Use non-breaking hyphen to remove space
      }

      if (index <= 2) {
        fontSize = '66px';  // First line: Work - Life
      } else if (index === 3) {
        fontSize = '77px';  // Second line: Balance
      } else if (index === 4) {
        fontSize = '80px';  // Third line: Awards
        textShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';
      }

      const lineBreak = (index === 2 || index === 3) ? '<br>' : '';

      return `<span style="color:${part.color}; font-size:${fontSize};  text-shadow:${textShadow};">${text}</span>${lineBreak}`;
    }).join(' ');
    const aboutUsPageFirstBoxData = getAboutUsFirstBoxData(aboutUsPageData);
    const aboutUsOurDriveData = getAboutUsOurDriveData(aboutUsPageData);
    const aboutUsTogetherData = getAboutUsTogetherData(aboutUsPageData)
    const aboutUsTeamMembers = getAboutUsTeamMembers(aboutUsPageData);
    const aboutUsContactUs = getAboutUsContactUs(aboutUsPageData)
    const formFields = aboutUsContactUs.form;
    const formButtonTitle = aboutUsContactUs.submitButtonTitle;
    const investors = '/corporate/';

    onMounted(() => {
      const redirectedFrom = sessionStorage.getItem('previousRoute');
      if (redirectedFrom === investors) {
        goToGetInTouchForm('getInTouch');
      }
    });

    return {
      aboutUsPageData,
      aboutUsPageFirstBoxData,
      aboutUsOurDriveData,
      aboutUsTogetherData,
      togetherElements: aboutUsTogetherData.elements,
      aboutUsTeamMembers,
      foundingTeam: aboutUsTeamMembers.foundingTeam.members,
      foundingTeamTitle: aboutUsTeamMembers.foundingTeam.title,
      foundingTeamIcon: aboutUsTeamMembers.foundingTeam.icon,
      psychologyTeam: aboutUsTeamMembers.psychologyTeam.members,
      psychologyTeamTitle: aboutUsTeamMembers.psychologyTeam.title,
      psychologyTeamIcon: aboutUsTeamMembers.psychologyTeam.icon,
      aboutUsContactUs,
      formFields,
      formButtonTitle,
      goToFooterContacts,
      currentWindowWidth,
      awardsPageData,
      awardsPageFirstPartTitle,
      awardsPageSecondPart,
      awardsPageThirdPart,
      awardsPageFourthPart,
      awardsPageFifthPart,
      awardsPageSixthPart,
      awardsPageLastPart
    }
  }
}
</script>

<style lang="scss">
.RelaxifyAwards {

  a {
    text-decoration: none;
  }

  .RelaxifyButton {
    button {
      color: #193566 !important;
    }
  }

  .title {

    color: white;
    font-size: 32px;
    margin: 0 20px;
    padding-top: 70px;
    font-weight: 700;
    line-height: 120%;
    max-width: 280px;

    @media (max-width: 768px) {
      width: 100%;
      margin: unset;
      line-height: 175%;
      max-width: unset;
    }

    @media (min-width: 1024px) {
      max-width: 400px !important;
      line-height: 53px;
    }


  }

  .description {
    color: white;
    font-size: 18px;
    margin: 45px 20px 50px 20px;
    font-weight: 400;
    line-height: 150%;
    max-width: 370px;
  }

  &__first-part {
    display: flex;
    justify-content: center;

    &__labels {

    }

    background: white;

    img {
      padding-bottom: 50px;
    }

    .RelaxifyAwards__first-box--image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__first-box--image-wrapper {
    @media (min-width: 1440px) {
      img {
        width: 531px;
        height: 450px;
      }
    }
  }

  .RelaxifyAwards__first-box.title {
    @media (min-width: 1440px) {
      font-size: 56px;
      line-height: 110%;
      max-width: 615px;
    }
  }

  //.RelaxifyAwards__first-box .title {
  //  @media (min-width: 1024px) {
  //    max-width: 400px !important;
  //    line-height: 53px;
  //
  //  }
  //}

  .RelaxifyAwards__first-box.description,
  .RelaxifyAwards__first-box-description.description {
    @media (min-width: 1440px) {
      font-size: 20px;
      line-height: 150%;
      max-width: 585px;
    }
  }

  &__second_part {
    @media (max-width: 1023px) {
      padding-top: 20px;

      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    @media (min-width: 1024px) {
      background: #f2f2f2;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-bottom: 60px;
      padding-top: 35px;
      margin-top: 40px;
      position: relative;
      margin-bottom: 30px;
    }
    @media (min-width: 1440px) {
    }

    padding-top: 60px;
    background: white;

    &__description__and__logos {
      @media (min-width: 1024px) {
        display: flex;
      }
    }

    &--label {
      @media (max-width: 1023px) {
        text-align: center;
      }

      @media (min-width: 1440px) {
        text-align: center;
        font-size: 20px;
        line-height: 22px;
      }
      color: #718EC1;
      margin: 0 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
    }

    &--title {
      @media (max-width: 1023px) {
        font-size: 24px;
        padding-bottom: 10px;
        max-width: 270px;
        font-weight: bold;
      }
      font-size: 24px;
      padding-bottom: 10px;
      max-width: 270px;
      font-weight: bold;

      @media (min-width: 1024px) {
        max-width: unset;
      }

      @media (min-width: 1440px) {
        font-size: 32px !important;
      }

      color: #193566 !important;
      //max-width: 240px !important;
    }

    &__description {

      @media (max-width: 1023px) {
        font-size: 18px !important;
        font-weight: 500 !important;
        margin-top: 15px;
      }
      font-size: 24px !important;
      font-weight: 600;

      @media (min-width: 1440px) {
        font-size: 32px !important;
      }

      color: #193566 !important;
    }

    &--second-description {
      @media (min-width: 1440px) {
        min-width: 585px !important;
        margin-left: 60px !important;
      }

      color: #193566 !important;
      font-size: 20px !important;
      margin-top: unset !important;
      margin-bottom: 30px !important;
    }

    &__logos {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: 1024px) {
        position: absolute;
        right: 0;
        top: 348px
      }

      @media (min-width: 1440px) {
        right: 25px;
      }

      &__element {
        margin-right: 15px;

        img {
          max-width: 109px;
          max-height: 45px;
        }
      }
    }
  }

  &__third_part {
    &__yellow__part {
      background-color: #FFC726;
      padding: 20px;
      border-radius: 25px; /* Rounded corners */
      text-align: center; /* Center the text */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
      margin: 20px 20px; /* Center the box */

      @media (min-width: 1440px) {
       margin: 20px 177px;
      }
      @media (min-width: 1750px) {
        margin: 20px 250px;
      }
    }

    &__grey__part {
      background-color: #f2f2f2;
      padding: 20px;
      border-radius: 25px; /* Rounded corners */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
      margin: 30px 20px 20px 20px; /* Center the box */

      @media (min-width: 1024px) {
        margin-top: 60px;
      }

      @media (min-width: 1440px) {
        margin: 60px 100px 50px 170px;
        max-width: 979px;
      }
      @media (min-width: 1750px) {
        //margin-left: 250px;
      }

      .RelaxifyAwards__third_part__description {
        font-size: 20px;
      }
    }

    .last-element {
      position: relative;
    }

    .last-element-image {
      position: absolute;
      bottom: -60px; /* Overflow the container */
      right: -15px; /* Overflow the container */
      width: 100px; /* Adjust width to fit the image */
      z-index: 1;

      @media (min-width: 1440px) {
       bottom: -113px;
      }
    }

    .mobile-phone {
      @media (min-width: 1024px) {
        display: none;
      }
    }

    .desktop-phone {
      display: none;
      @media (min-width: 1024px) {
        display: block;
        width: 140px;
      }
      @media (min-width: 1440px) {
        width: 225px;
      }
    }

    .RelaxifyButton {
      margin: 80px 20px 20px 20px;

      @media (min-width: 1024px) {
        margin-top: 30px
      }
      @media (min-width: 1440px) {
        margin-top: 40px;
        margin-left: 170px;
      }
      @media (min-width: 1750px) {
        //margin-left: 250px;
      }

      button {
        width: 100%;
        height: 56px !important;

        @media (min-width: 1024px) {
          width: 414px;
          font-weight: bold;
        }
      }
    }

    .header {
      h3 {
        @media (min-width: 1024px) {
          display: none;
        }
      }
    }

    &__elements {
      &__title {
        text-align: center; /* Center the text */
        margin: 30px 20px 20px 20px; /* Center the box */
        color: #193566;
        font-size: 24px;
        font-weight: bold;

        @media (min-width: 1024px) {
          text-align: left;
        }

        @media (min-width: 1440px) {
          margin-left: 177px;
        }
        @media (min-width: 1750px) {
          margin-left: 250px;
        }
      }

      &__container {
        margin: 0 20px;
      }
    }

    &__element {
      display: flex;
      flex-direction: column;
      background-color: #ffffff; /* Background color */
      border-radius: 10px; /* Rounded corners */
      padding: 20px; /* Space inside the container */
      max-width: 1182px;
      box-shadow: 0px 0px 10px -2px #2993FF;
      margin: 20px auto; /* Center the box horizontally */

      &__image__and__text {
        @media (min-width: 1024px) {
          margin-left: 20px;
        }

        h3 {
          display: none;
          @media (min-width: 1024px) {
            display: block;
          }
        }
      }

      @media (min-width: 1024px) {
        flex-direction: row;
        align-items: flex-start;
        background-color: #f2f2f2;
        box-shadow: unset;
        border-radius: 30px;
        margin-bottom: 30px;
      }
      @media (min-width: 1440px) {
        margin: 20px 150px;
      }

      &__title {
        font-size: 24px;
        font-weight: bold;
        color: #193566; /* Darker text color */
        margin-top: unset;
        margin-left: 10px;

        @media (min-width: 1024px) {
          margin-left: unset;
          margin-bottom: 15px;
        }
      }

      &__description {
        font-size: 18px;
        color: #193566; /* Adjusted for readability */
        line-height: 1.4;
      }

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 15px; /* Space between icon/title and description */
      }

      .icon {
        width: 40px; /* Icon size */
        height: 40px;
        margin-right: 15px; /* Space between the icon and the title */
      }
    }

    &__title {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 25px;
      color: #193566;

      @media (min-width: 1024px) {
        padding: 0 140px;
      }
    }

    &__description {
      font-size: 18px;
      color: #193566;

      @media (min-width: 1024px) {
        font-size: 20px;
        padding: 0 20px;
      }
    }
  }

  &__fourth_part {
    margin: 40px 20px;
    @media (min-width: 1440px) {
      margin: 40px 100px !important;
    }
    @media (min-width: 1750px) {
      margin: 40px 170px!important;
    }

    &__beginning {
      display: flex;

      @media (min-width: 1024px) {
        margin: 0 40px;
        align-items: center;
      }

      img {
        width: 68px;
      }

      &__title {
        font-size: 24px;
        font-weight: bold;
        color: #193566;
        margin-top: unset;
        margin-left: 10px;

        @media (min-width: 1024px) {
          margin-left: 30px;
        }
      }
    }

    &__measures {
      text-align: center;

      p {
        font-size: 20px;
        color: #193566;
        font-weight: bold;
      }

      p:nth-of-type(2) {
        @media (min-width: 1024px) {
          margin: 20px 0;
        }
      }

      &__element {
        background-color: #193566;
        padding: 20px;
        border-radius: 25px; /* Rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
        margin: 20px 20px; /* Center the box */
        text-align: left !important;
        height: 288px;
        position: relative;

        @media (min-width: 1024px) {
         flex: 1;
        }
        @media (min-width: 1750px) {
          flex: unset;
          width: 288px;
        }

        .header {
          display: flex;
        }

        &__title {
          font-size: 24px;
          color: white;
          margin: 0 0 0 20px !important;

          @media (min-width: 1024px) {
            max-width: 150px;
          }

        }

        .badge {
          position: absolute;
          bottom: 215px; /* Overflow the container */
          right: -50px; /* Overflow the container */
          width: 100px; /* Adjust width to fit the image */
          z-index: 1;
        }

        &__description {
          font-size: 18px !important;
          color: white !important;
          text-align: left !important;
          font-weight: normal !important;
          margin-top: 25px !important;
        }
      }

    }

    &__elements__container {
      margin: 0 20px;
      @media (min-width: 1024px) {
        display: flex;
      }
      @media (min-width: 1750px) {
        justify-content: space-between;
      }
    }
  }

  &__fifth_part {
    &__title {
      font-size: 24px;
      color: #193566;
      text-align: center;
      margin-bottom: 20px;

      @media (min-width: 1024px) {
        text-align: left;
        margin-left: 60px;
      }
      @media (min-width: 1440px) {
        margin-left: 150px;
        margin-bottom: 40px;
      }
      @media (min-width: 1750px) {
        margin-left: 215px;
      }
    }

    &__element {
      background-color: #f2f2f2;
      padding: 20px;
      border-radius: 25px; /* Rounded corners */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
      margin: 10px auto 35px auto; /* Center the box */
      text-align: left !important;
      position: relative;
      width: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media (min-width: 1024px) {
       width: 270px;
        margin: 10px 20px 35px 20px;
      }

      @media (min-width: 1440px) {
       width: 330px;
        height: 562px;
        margin-right: unset;
        margin-left: unset;
      }

      &__title {
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }

    &__elements__container {
      @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
      }
      @media (min-width: 1024px) {
        justify-content: space-evenly;
        margin: 0 68px;
      }
    }
  }

  &__sixth_part {
    padding-bottom: 180px;

    @media (min-width: 1024px) {
      padding-bottom: unset;
    }

    &__elements__container {
      display: flex;
      justify-content: center;
      position: relative; /* Enable positioning for the overlap */
      align-items: center;
      padding: 25px;

    }

    &__title {
      font-size: 24px;
      color: #193566;
      padding: 20px;
      text-align: center;
    }

    .list-box {
      padding: 20px;
      border-radius: 20px;
      width: 300px;
      list-style-type: none;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      position: relative;

      @media (min-width: 1024px) {
        padding-left: 45px !important;
      }
      @media (min-width: 1440px) {
        padding-left: 100px !important;
        height: 360px;
        padding-top: 85px !important;
      }
    }

    &__elements__blueContainer {
      background-color: #193566; /* Dark blue background */
      color: #ffffff; /* White text */
      margin-left: -190px; /* Make it overlap by moving it to the left */
      margin-bottom: -420px;
      z-index: 2; /* Ensure the blue box is "on top" */
      padding: 40px 20px 40px 20px !important;

      @media (min-width: 1024px) {
        margin-left: unset;
        margin-bottom: unset;
        padding-bottom: 100px !important;
        padding-top: 40px !important;
        width: 420px !important;
      }
      @media (min-width: 1440px) {
        width: 500px!important;
      }


      p {
        font-size: 24px;
        color: #f2f2f2;
      }
    }

    &__elements__greyContainer {
      background-color: #f7f7f7; /* Light gray background */
      z-index: 1;
      padding-top: 40px !important;
      padding-bottom: 100px !important;

      @media (min-width: 1024px) {
       width: 340px!important;
      }
      @media (min-width: 1440px) {
        padding-left: 80px !important;
        width: 400px!important;
      }

      p {
        font-size: 24px;
        color: #193566;
      }
    }
  }

  .footer_part {
    padding: 40px 0;
  }

  &__apply__participation__part {

    display: flex;
    justify-content: center;

    &__yellow_container {
      width: 355px;
      background: #FFC726;
      border-radius: 25px;
      padding: 20px;

      @media (min-width: 1024px) {
        width: 538px;
      }
      @media (min-width: 1440px) {
        margin-top: 25px;
      }

      &__firstPart {
        display: flex;
        justify-content: space-between;

        &__titles {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }

      &__secondPart {
        &__title {
          font-size: 23px;
          font-weight: bold;
          color: #193566;
        }
      }

      &__priceTitle {
        font-size: 20px;
        font-weight: bold;
        color: #193566;
        line-height: 20px;
        letter-spacing: 4px;
      }

      &__price {
        font-size: 30px;
        font-weight: bolder;
        color: #193566;
      }
    }

    &__desc {
      font-size: 24px;
      color: #193566;
      font-weight: bold;
      padding: 80px 30px 30px 30px;
      text-align: center;

      @media (min-width: 1024px) {
        padding: 50px 300px 20px 300px;
      }
      @media (min-width: 1440px) {
        padding: 50px 500px 20px 500px;
      }
    }
  }


  &__together {
    @media (min-width: 1440px) {
      padding-top: 120px;
    }

    background: #ECF0F3;
    padding: 70px 30px;

    &__label {
      @media (min-width: 1440px) {
        text-align: center;
        font-size: 20px;
        line-height: 22px;
      }

      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #5358C2;
      letter-spacing: 0.14em;
    }

    &__title {
      @media (min-width: 1440px) {
        text-align: center !important;
        font-size: 48px !important;
        line-height: 22px !important;
        max-width: unset !important;
        margin-top: 30px !important;
      }

      text-align: center;
      font-size: 32px;
      font-weight: 700;
      color: #193566;
      margin: 20px 0 35px 0;
    }

    &__description {
      @media (min-width: 1440px) {
        font-size: 20px !important;
        line-height: 30px !important;
        max-width: 481px !important;
        margin: 40px auto 100px auto !important;
      }

      color: #193566 !important;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      margin: 0 20px 70px 20px;
    }

    &__elements {
      @media (min-width: 1440px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        margin-top: 20px;
      }

      margin: 0 20px;
    }

    &__element {
      @media (min-width: 1440px) {
        flex: 33%;
        flex-direction: column;
        max-width: 390px;
        margin-bottom: 80px;
      }

      @media (min-width: 1700px) {
        max-width: 470px;
      }

      @media (min-width: 1980px) {
        max-width: 500px;
      }

      display: flex;
      margin-bottom: 30px;
      align-items: self-start;

      &___text {
        @media (min-width: 1440px) {
          margin-left: unset;
        }

        margin-left: 30px;

        &____title {
          @media (min-width: 1440px) {
            margin-top: 20px;
          }

          font-size: 24px;
          line-height: 31.25px;
          font-weight: 500;
          color: #193566;
          font-style: normal;
          margin-bottom: 15px;
        }

        &____description {
          @media (min-width: 1440px) {
            max-width: 225px;
          }

          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #193566;
        }
      }
    }
  }

  &__team {
    @media (min-width: 1440px) {
      margin: 50px 223px;
    }

    padding: 70px 0;
    margin: 0 20px;

    &__label {
      @media (max-width: 1023px) {
        text-align: center;
      }

      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 22px;
      }

      font-weight: 400;
      font-size: 14px;
      color: #5358C2;
      letter-spacing: 0.14em;
    }

    &__title {

      @media (max-width: 1023px) {
        margin: 20px auto 25px auto !important;
        text-align: center;
      }

      @media (min-width: 1440px) {
        font-size: 48px !important;
        line-height: 22px !important;
        max-width: unset !important;
        margin-top: 30px !important;
      }

      font-size: 32px;
      font-weight: 700;
      color: #193566;
      margin: 20px 0 30px 0;
      max-width: 235px;
    }

    &__description {
      @media (max-width: 1023px) {
        margin: 0 auto 35px auto !important;
      }

      @media (min-width: 1440px) {
        font-size: 20px !important;
        line-height: 30px !important;
        max-width: 651px !important;
        margin-bottom: 80px;
      }

      color: #193566 !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      max-width: 330px;
      margin-bottom: 35px;
    }

    &__founding_team,
    &__psychology_team {
      margin-bottom: 40px;

      &__title {
        @media (min-width: 1440px) {
          margin-bottom: 50px;
        }

        display: flex;
        align-items: center;
        margin-bottom: 30px;
      }

      &__label {
        margin-left: 15px;
        font-weight: 500;
        font-size: 24px;
        line-height: 31.25px;
        color: #193566;
      }

      &__members {
        display: flex;
      }

      &__member {
        &__description {
          display: none;
        }

        @media (min-width: 1440px) {
          max-width: unset;
          img {
            width: 343px;
            height: 435px;
            margin-right: 40px;
          }

          &__description {
            display: block;
            margin-top: 20px;
            max-width: 305px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #193566;
          }
        }

        max-width: 110px;
        margin-right: 20px;

        &__name {
          @media (min-width: 1440px) {
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
          }

          font-weight: 550;
          font-size: 13px;
          line-height: 17px;
          color: #193566;
          margin-top: 8px;
        }

        &__role {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #193566;
          margin-top: 5px;
        }
      }
    }
  }

  &__contactUs {
    @media (min-width: 1440px) {
      padding: 130px 223px;
      display: flex;
    }

    &__labels {
      @media (max-width: 1023px) {
        text-align: center;
      }

      @media (min-width: 1440px) {
        margin-right: 115px;
      }
      @media (min-width: 1700px) {
        margin-right: 200px;
      }
    }

    background: #ECF0F3;
    padding: 70px 20px;

    &__label {
      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 22px;
      }

      font-weight: 400;
      font-size: 14px;
      color: #5358C2;
      letter-spacing: 0.14em;
    }

    &__title {
      @media (min-width: 1440px) {
        font-size: 48px !important;
        max-width: 376px !important;
        margin-top: 30px !important;
      }

      font-size: 32px;
      font-weight: 700;
      color: #193566;
      margin: 20px 0 30px 0;
    }

    &__description {
      display: none;
      color: #193566 !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      max-width: 330px;
      margin-bottom: 35px;

      @media (min-width: 1440px) {
        display: block;
        font-size: 20px !important;
        line-height: 30px !important;
        max-width: 414px !important;
        margin-bottom: 80px;
      }

      @media (min-width: 1770px) {
        max-width: 410px !important;
      }
    }
  }

  .mobile_avatar {
    @media (min-width: 1440px) {
      display: none;
    }
  }

  .desktop_avatar {
    @media (min-width: 1440px) {
      display: block;
    }

    display: none;
  }
}

</style>
