<template>
  <div class="RelaxifyCorporate">
    <RelaxifyHeader/>
    <div class="RelaxifyCorporate__start__page">
      <h1 class="RelaxifyCorporate__start__page title "> {{ corporateStartPage.title }} </h1>
      <div class="RelaxifyCorporate__start__page__description__and__button">
        <div class="RelaxifyCorporate__start__page description"> {{ corporateStartPage.description }}</div>
        <div class="RelaxifyCorporate__start__page__desktop__description"
             v-html="corporateStartPageDesktopDescription"/>
        <router-link  to="/about-us" @click="goToAboutUsPage('getInTouch')">
            <RelaxifyButton color="primary"> {{ corporateStartPage.buttonTitle }}</RelaxifyButton>
        </router-link>
        <img loading="lazy" height="320" :src="corporateStartPage.image" alt="Background"/>
      </div>
    </div>

    <div class="RelaxifyCorporate__start__page__background">
      <img loading="lazy" :src="corporateStartPage.image" alt="Background"/>
    </div>

    <div class="RelaxifyCorporate__facts">
      <div class="RelaxifyCorporate__facts__label"> {{ corporateFactsData.partName }}</div>
      <h2 class="RelaxifyCorporate__facts__title"> {{ corporateFactsData.title }}</h2>
      <div class="RelaxifyCorporate__facts__description description"> {{ corporateFactsData.description }}</div>
      <div class="RelaxifyCorporate__facts__elements">
        <div class="RelaxifyCorporate__facts__element" v-for="(element, index) in corporateFactsElements" :key="index">
          <div class="RelaxifyCorporate__facts__element__price"> {{ element.price }}</div>
          <div class="RelaxifyCorporate__facts__element__description"> {{ element.description }}</div>
        </div>
      </div>
      <div class="RelaxifyCorporate__facts__second__description description"> {{
          corporateFactsData.secondDescription
        }}
      </div>
      <a href="https://onelink.to/x3rfwb">
        <RelaxifyButton color="primary"> {{ corporateFactsData.buttonTitle }}</RelaxifyButton>
      </a>
    </div>

    <div class="RelaxifyCorporate__numbers">
      <div class="RelaxifyCorporate__numbers__label"> {{ corporateNumbersData.partName }}</div>
      <div class="RelaxifyCorporate__numbers__title__description__and__elements">
        <div class="RelaxifyCorporate__numbers__title__and__description">
          <h2 class="RelaxifyCorporate__numbers__title title"> {{ corporateNumbersData.title }} </h2>
          <div class="RelaxifyCorporate__numbers__description description"> {{ corporateNumbersData.description }}</div>
        </div>
        <div class="RelaxifyCorporate__numbers__elements">
          <div class="RelaxifyCorporate__numbers__element" v-for="(element, index) in corporateNumbersElements"
               :key="index">
            <div class="RelaxifyCorporate__numbers__element__content">
              <img loading="lazy" width="117" :src="element.image" :alt="`Element-index${index}`"/>
              <div class="RelaxifyCorporate__numbers__element__content__count__and__description">
                <div class="RelaxifyCorporate__numbers__element__content__count">
                  {{ element.count }}
                </div>
                <div class="RelaxifyCorporate__numbers__element__content__description">
                  {{ element.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a v-if="currentWindowWidth < 1024" href="https://onelink.to/x3rfwb">
        <RelaxifyButton color="primary" class="RelaxifyAboutTheApp__try-for-free-button">
          {{ corporateNumbersData.buttonTitle }}
        </RelaxifyButton>
      </a>
      <RelaxifyButton v-else @click="goToFooterContacts('relaxifyFooter')" color="primary"
                      class="RelaxifyAboutTheApp__try-for-free-button">
        {{ corporateNumbersData.buttonTitle }}
      </RelaxifyButton>
    </div>

    <div class="RelaxifyCorporate__success">
      <div class="RelaxifyCorporate__success__label"> {{ corporateSuccessData.partName }}</div>
      <h2 class="RelaxifyCorporate__success__title title"> {{ corporateSuccessData.title }} </h2>
      <div class="RelaxifyCorporate__success__achievements__list__desktop">
        <div class="RelaxifyCorporate__success__achievements__list__element" v-for="(achievement, i) in achievements"
             :key="i">
          <div class="RelaxifyCorporate__success__achievements__list__icon__and__content">
            <img loading="lazy" :src="corporateSuccessYellowDotImage" alt="Yellow Image"/>
            <div class="RelaxifyCorporate__success__achievements__list__title__and_desc">
              <div class="RelaxifyCorporate__success__achievements__list__title"> {{ achievement.title }}</div>
              <div class="RelaxifyCorporate__success__achievements__list__description"> {{
                  achievement.description
                }}
              </div>
            </div>
          </div>
          <div class="RelaxifyCorporate__success__achievements__list__icon__wrapper">
            <img :src="achievement.image" loading="lazy" :alt="achievement.title"/>
          </div>
        </div>
      </div>
    </div>

    <div class="RelaxifyCorporate__growth">
      <div class="RelaxifyCorporate__growth__label"> {{ corporateGrowthData.partName }}</div>
      <h2 class="RelaxifyCorporate__growth__title title"> {{ corporateGrowthData.title }} </h2>
      <div class="RelaxifyCorporate__growth__description description"> {{ corporateGrowthData.description }}</div>
      <div class="RelaxifyCorporate__growth__investors" id="investors">
        <img loading="lazy" width="157" v-for="(investor, index) in corporateGrowthData.investorsLogos" :key="index"
             :src="investor.logo" :alt="investor.name"/>
      </div>
      <div class="RelaxifyCorporate__growth__investors__eu__institutions" >
        <img loading="lazy" width="157" v-for="(investor, index) in corporateGrowthData.euInstitutionsLogos" :key="index"
             :src="investor.logo" :alt="investor.name"/>
      </div>
      <div class="RelaxifyCorporate__growth__descriptions">
      <div v-for="(desc, index) in corporateGrowthData.descriptions" :key="index" class="RelaxifyCorporate__growth__description description"> {{ desc }}</div>
      </div>
    </div>

    <RelaxifyFooter/>

  </div>
</template>

<script>
import {
  getCorporatePageData,
  getCorporateStartPage,
  getCorporateFactsData,
  getCorporateNumbersData,
  getCorporateSuccessData,
  getCorporateGrowthData,
  getCorporateStartPageDesktopDescription, getCorporateSuccessDataYellowDotImage
} from "@/getters/corporateGetters";
import { goToFooterContacts, goToGetInTouchForm } from "@/helpers/helpers";
import resources from "@/resources";
import RelaxifyHeader from "@/components/RelaxifyHeader";
import RelaxifyButton from "@/components/RelaxifyButton";
import RelaxifyFooter from "@/components/RelaxifyFooter";
import {computed} from "vue";

export default {
  name: "RelaxifyCorporate",
  components: {RelaxifyFooter, RelaxifyButton, RelaxifyHeader},
  metaInfo() {
    return {
      title: 'Invest in The Future With RelaxifyApp',
      meta: [
        {
          name: 'description',
          content: 'What the RelaxifyApp team strives for is positively impacting this negative tendency of increasing mental health issues in our society by designing an effective solution instead of replicating one. Thus, we rely solely on fact-based science and cutting-edge technology. We look forward to collaborating with our investors and improving the future together.'
        },
        {
          name: 'robots',
          content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
        },
        {
          property: 'fb:app_id',
          content: '833032110773133'
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:title',
          content: 'Invest in The Future With RelaxifyApp'
        },
        {
          property: 'og:site_name',
          content: 'RelaxifyApp'
        }
      ],
      link: [
        {rel: 'canonical', href: window.location.href}
      ]
    }
  },

  setup() {
    const currentWindowWidth = computed(() => window.innerWidth);
    const corporatePageData = getCorporatePageData(resources);
    const corporateStartPage = getCorporateStartPage(corporatePageData);
    const corporateStartPageDesktopDescription = getCorporateStartPageDesktopDescription(corporateStartPage);
    const corporateFactsData = getCorporateFactsData(corporatePageData);
    const corporateNumbersData = getCorporateNumbersData(corporatePageData);
    const corporateSuccessData = getCorporateSuccessData(corporatePageData);
    const corporateSuccessYellowDotImage = getCorporateSuccessDataYellowDotImage(corporateSuccessData);
    const corporateGrowthData = getCorporateGrowthData(corporatePageData);

    const goToAboutUsPage = () => {
        goToGetInTouchForm('getInTouch');
    }

    const carouselSettings = {
      itemsToShow: 1,
      wheelControl: false,
      breakpoints: {
        320: {
          itemsToShow: 1.26,
          centerMode: true,
        },
        600: {
          itemsToShow: 1.9,
        },
        620: {
          itemsToShow: 2,
        },
        650: {
          itemsToShow: 2.1,
        },
        680: {
          itemsToShow: 2.2,
        },
        700: {
          itemsToShow: 2.3,
        },
        750: {
          itemsToShow: 2.4,
        },
        800: {
          itemsToShow: 2.6,
        },
        850: {
          itemsToShow: 2.8,
        },
        900: {
          itemsToShow: 2.9,
        },
        950: {
          itemsToShow: 3.1,
        },
        1000: {
          itemsToShow: 3.25,
        },
        1050: {
          itemsToShow: 3.4,
        },
        1100: {
          itemsToShow: 3.5,
        },
        1150: {
          itemsToShow: 3.6,
        },
        1200: {
          itemsToShow: 3.7,
        },
        1250: {
          itemsToShow: 3.9,
        },
        1300: {
          itemsToShow: 4.1,
        },
        1350: {
          itemsToShow: 4.3,
        },
        1400: {
          itemsToShow: 4.5,
        },
        1450: {
          itemsToShow: 4.75,
        },
        1500: {
          itemsToShow: 4.8,
        },
        1550: {
          itemsToShow: 5,
        },
        1600: {
          itemsToShow: 5,
        },
      }
    }

    return {
      corporateStartPage,
      corporateStartPageDesktopDescription,
      corporateFactsData,
      corporateFactsElements: corporateFactsData.elements,
      corporateNumbersData,
      corporateNumbersElements: corporateNumbersData.elements,
      corporateSuccessData,
      corporateSuccessYellowDotImage,
      achievements: corporateSuccessData.achievementsElements,
      carouselSettings,
      corporateGrowthData,
      currentWindowWidth,
      goToFooterContacts,
      goToAboutUsPage
    }
  }
}
</script>

<style lang="scss">
.RelaxifyCorporate {

  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
    color: #193566;
    max-width: 305px;
  }

  .description {
    font-size: 20px;
    margin: 25px 10px 20px 10px;
    font-weight: 400;
    line-height: 150%;
    max-width: 345px;
    color: #193566;
  }

  &__start__page {
    @media (min-width: 1440px) {
      display: flex;
      margin: 80px 190px;

      img {
        display: none;
      }
    }
    @media (min-width: 1700px) {
      justify-content: space-evenly;
    }

    margin: 70px 10px 0 10px;

    .RelaxifyButton {
      @media (min-width: 1440px) {
        margin-top: 50px;
      }

      width: 100%;

      button {
        @media (min-width: 1440px) {
          width: 366px !important;
        }

        width: 100%;
        height: 50px !important;
        color: #193566 !important;
      }
    }

    img {
      margin: 40px 0 30px -10px;
    }
  }

  &__start__page__background {
    img {
      width: 100%;
    }

    @media (min-width: 1440px) {
      display: flex;
    }

    display: none;
  }

  .RelaxifyCorporate__start__page .description {
    @media (min-width: 1440px) {
      display: none;
    }
  }

  .RelaxifyCorporate__start__page__desktop__description {
    display: none;
    @media (min-width: 1440px) {
      display: flex;
      flex: 1;
      max-width: 654px;
      margin: unset;
      font-size: 20px;
      font-weight: 400;
      line-height: 160%;
      color: #193566;
    }
  }

  .RelaxifyCorporate__start__page .title {
    @media (min-width: 1440px) {
      font-size: 56px;
      max-width: 310px;
      margin: 0 170px 0 0;
    }
  }

  &__facts {
    @media (min-width: 1440px) {
      padding-top: 100px;
    }

    margin: 0 20px;
    padding-bottom: 40px;

    &__label {
      @media (min-width: 1440px) {
        font-size: 20px;
      }


      color: #5358C2;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
      text-align: center;
    }

    &__title {
      @media (min-width: 1440px) {
        font-size: 48px;
        margin: 0 auto;
        max-width: 480px;
      }

      text-align: center;
      font-size: 32px;
      font-weight: 700;
      line-height: 120%;
      color: #193566;
      margin: 0 30px 25px 30px;
    }

    &__description,
    &__second__description {
      @media (min-width: 1440px) {
        margin: 30px auto !important;
        max-width: 806px !important;
      }


      text-align: center;
      max-width: unset !important;
      margin: 10px !important;
    }

    &__elements {
      @media (min-width: 1440px) {
        display: flex;
        justify-content: center;
      }

      margin: 60px 10px
    }

    &__element {
      @media (min-width: 1440px) {
        width: 262px;
        height: 262px;
        text-align: center;
        margin-right: 35px;
      }

      background: #5358C2;
      border: 1px solid #718EC1;
      border-radius: 10px;
      margin-bottom: 25px;
      height: 222px;
      padding: 30px;

      &__price {
        @media (min-width: 1440px) {
          font-size: 40px;
        }

        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 150%;
        color: #FFFFFF;
        margin-bottom: 20px;
      }

      &__description {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #FFFFFF;
        max-width: 272px;
      }
    }

    .RelaxifyButton {
      @media (min-width: 1440px) {
        display: none;
      }

      margin-top: 45px;

      button {
        width: 100%;
        height: 50px !important;
        color: #193566 !important;
      }
    }
  }

  &__numbers {
    @media (min-width: 1440px) {
      padding-top: 130px;
      padding-left: 190px;
      padding-bottom: 110px;
    }

    @media (min-width: 1700px) {
      padding-left: 300px;
    }

    background: #ECF0F3;
    padding: 70px 20px;

    &__label {
      @media (max-width: 1023px) {
        text-align: center;
      }

      @media (min-width: 1440px) {
        font-size: 20px;
        margin-bottom: 40px;
      }

      color: #5358C2;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
    }

    &__title {
      @media (max-width: 1023px) {
        text-align: center;
        margin: 0 auto !important;
      }

      @media (min-width: 1440px) {
        font-size: 48px !important;
        max-width: 415px !important;
        margin-top: unset !important;
      }

      margin-top: 20px !important;

    }

    &__description {
      @media (max-width: 1023px) {
        text-align: center;
      }

      @media (min-width: 1440px) {
        max-width: 415px !important;
      }

      margin-left: unset !important;
      max-width: unset !important;
      margin-right: unset !important;
    }

    &__title__description__and__elements {
      @media (min-width: 1440px) {
        display: flex;
      }
    }

    &__title__and__description {
      @media (min-width: 1440px) {
        margin-right: 150px;
      }
    }

    &__elements {
      @media (min-width: 1440px) {
        flex: 1;
        padding: unset;
      }

      display: flex;
      flex-wrap: wrap;
      padding: 3px 14px;
      justify-content: space-between;
    }

    &__element {
      @media (min-width: 1440px) {
        max-width: unset;
      }

      flex: 50%;
      max-width: 117px;

      &__content {
        @media (min-width: 1440px) {
          display: flex;
        }

        margin-bottom: 70px;

        &__count__and__description {
          @media (min-width: 1440px) {
            margin-left: 20px;
          }
        }

        &__count {
          @media (min-width: 1440px) {
            margin-top: unset;
          }

          color: #193566;
          font-size: 36px;
          font-weight: 700;
          line-height: 47px;
          margin-top: 10px;
        }

        &__description {
          color: #193566;
          font-size: 16px;
          font-weight: 400;
          max-width: 140px;
        }
      }
    }

    .RelaxifyButton {
      @media (min-width: 1440px) {
        width: 366px !important;
        height: 56px !important;
        margin-top: -48px;
      }

      width: 100%;
      color: black;

      button {
        width: 100%;
        height: 50px !important;
        color: #193566 !important;
      }
    }
  }

  .RelaxifyCorporate_success__achievements__list {
    margin-top: 35px;
  }

  &__success {
    @media (min-width: 1440px) {
      margin-bottom: 100px;
    }

    @media (min-width: 1024px) {
      margin-bottom: unset;
    }

    background: white;
    padding-top: 80px;
    margin-bottom: 230px;


    &__label {
      @media (min-width: 1440px) {
        font-size: 20px;
      }

      color: #5358C2;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
      text-align: center;
    }

    &__title {
      @media (min-width: 1440px) {
        font-size: 48px !important;
        max-width: unset !important;
      }

      text-align: center;
      margin: 0 auto;
    }

    &__achievements__list {
      @media (min-width: 1024px) {
        display: none !important;


        &__title {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 120%;
          color: #193566;
          max-width: 242px;
        }

        &__description {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          color: #193566;
          margin-top: 15px;
          max-width: 324px;
        }

        &__element {
          display: flex;
          align-items: center;
          margin-bottom: 80px;
        }
      }
    }

    &__achievements__list__desktop {
      @media (max-width: 1023px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 70px;
    }

    &__achievements__list__icon__and__content {
      display: flex;
      align-items: baseline;
    }

    &__achievements__list__title__and_desc {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 100px;
    }

    .RelaxifyCarousel {
      .hooper-prev,
      .hooper-next {
        top: -380px !important;
        width: unset;
        height: unset;
        border-radius: unset;
        border: unset;
        display: inline-block;


        svg {
          width: 35px;
          height: 35px;
          color: #193566
        }

        background: unset;
      }

      .hooper-next {
        right: -326px;
      }

      .hooper-prev {
        left: -10px;
      }

      h3 {
        text-align: center;
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 29px;
        color: #193566;
        max-width: 172px;
        margin: 25px auto !important;
      }

      h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: #193566;
        margin: 0 auto !important;
        max-width: 264px;
      }
    }

    .RelaxifyLayer {
      height: 280px !important;
      max-width: 1531px !important;
      margin: 0 auto !important;
      border-color: white !important;
      filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.1));

      .hooper {
        height: auto !important;
        max-height: 350px !important;
      }

      .hooper-list {
        overflow: hidden !important;
      }

      .v-image {
        width: unset !important;
        height: unset !important;
        margin-top: unset !important;
      }
    }
  }

  &__growth {
    margin: 70px 0;

    &__label {
      @media (min-width: 1440px) {
        font-size: 20px;
      }

      color: #5358C2;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.14em;
      text-align: center;
    }

    &__title {
      @media (min-width: 1440px) {
        font-size: 48px !important;
      }

      text-align: center;
      margin: 0 auto;
      max-width: unset !important;
    }

    &__description {
      @media (min-width: 1440px) {
        margin: 20px auto !important;
        max-width: 484px !important;
      }

      margin: 30px !important;
      max-width: unset !important;
      text-align: center !important;
    }

    &__descriptions {
      margin-top: 30px;
      display: flex;
      @media (max-width: 1023px) {
        flex-direction: column;
      }
    }

    &__investors {
      @media (min-width: 1440px) {
        margin: 50px 100px 30px 100px;
      }

      //@media (min-width: 1800px) {
      //  margin: 50px 300px 100px 300px;
      //}


      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 20px;

      img {
        @media (min-width: 1440px) {
          width: 250px;
          height: 100px;

        }

        @media (min-width: 1920px) {
          width: 440px;
        }

        margin-bottom: 35px;
      }

      img:nth-child(5) {
        @media (min-width: 1440px) {
          height: 150px;
          width: 200px;

        }
      }

      img:nth-child(6),
      img:nth-child(7), {
        @media (min-width: 1440px) {
          height: 140px;
        }
      }

      img:nth-child(6) {
        @media (max-width: 600px) {
          height: 96px;
        }
      }

      img:nth-child(7) {
        @media (max-width: 600px) {
          height: 80px;
        }
      }
    }

    &__investors__eu__institutions {
      margin: 20px;
      @media (min-width: 1440px) {
        margin: 0 100px;
      }
     justify-content: space-between;
      display: flex;
      flex-wrap: wrap;

      img {
        @media (min-width: 360px) {
          height: 90px;
          width: 155px;
        }
        @media (min-width: 600px) {
          height: 160px;
        }

        @media (min-width: 768px) {
          width: 300px;
          height: 140px;
        }
      }

      img:nth-child(1) {
        @media (min-width: 360px) {
          height: 160px;
        }
        @media (min-width: 600px) {
          width: 180px;
          height: 160px;
        }
        @media (min-width: 768px) {
          height: 190px;
          width: 250px;
        }

      }

    }
  }

  a {
    text-decoration: none;
  }

  .RelaxifyFooter__blue-part {
  }
}
</style>
