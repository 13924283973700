// Constant titles
const footer = 'footer';

// Getters

export const getFooterData = (pages) => pages.find(item => item.page === footer);
export const getFooterUpperTitle = (footerData) => footerData.upperTitle;
export const getFooterCorporateUpperTitle = (footerData) => footerData.corporateUpperTitle;
export const getFooterMainTitle = (footerData) => footerData.mainTitle;
export const getFooterCorporateMainTitle = (footerData) => footerData.corporateMainTitle;
export const getFooterDescription = (footerData) => footerData.description;
export const getFooterCorporateDescription = (footerData) => footerData.corporateDescription;
export const getFooterCorporateButtonTitle = (footerData) => footerData.corporateButtonTitle;
export const getFooterGooglePlayLogo = (footerData) => footerData.googlePlayLogo;
export const getFooterFlowerLogo = (footerData) => footerData.flowerLogo
export const getFooterAppStoreLogo = (footerData) => footerData.appStoreLogo;
export const getFooterRedirectElements = (footerData) => footerData.redirectOptions;
export const getFooterSocialIcons = (footerData) => footerData.socialIcons
export const getFooterSocialIconsColored = (footerData) => footerData.socialIconsColored
export const getFooterTerms = (footerData) => footerData.terms
export const getFooterMenuOptions = (footerData) => footerData.redirectOptions;

const footerGetters = {
    getFooterData,
    getFooterUpperTitle,
    getFooterMainTitle,
    getFooterDescription,
    getFooterRedirectElements,
    getFooterSocialIcons,
    getFooterTerms,
    getFooterGooglePlayLogo,
    getFooterAppStoreLogo,
    getFooterFlowerLogo,
    getFooterCorporateUpperTitle,
    getFooterCorporateMainTitle,
    getFooterCorporateDescription,
    getFooterCorporateButtonTitle,
    getFooterMenuOptions,
    getFooterSocialIconsColored
}

export default footerGetters;
