// Constant titles
const header = 'header';

// Getters

export const getHeaderData = (pages) => pages.find(item => item.page === header);
export const getHeaderMenuItems = (headerData) => headerData.menuOptions;
export const getHeaderMenuItemsForCorporate = (headerData) => headerData.menuOptionsForCorporate;
export const getHeaderMenuItemsForAwards = (headerData) => headerData.menuOptionsForAwards;
export const getHeaderMenuLogo = (headerData) => headerData.logo;

const headerGetters = {
    getHeaderData,
    getHeaderMenuItems,
    getHeaderMenuItemsForCorporate,
    getHeaderMenuLogo

}

export default headerGetters;
