<template>
  <div>
    <RelaxifyHeader/>
    <div class="RelaxifyJoinTheCommunity">
      <div class="RelaxifyJoinTheCommunity__start__page">
        <h1 class="RelaxifyJoinTheCommunity__start__page__title"> {{ startPageTitle }} </h1>
        <h2 class="RelaxifyJoinTheCommunity__start__page__description"> {{ startPageDescription }} </h2>
        <div class="RelaxifyJoinTheCommunity__start__page__description"> {{ startPageSecondDescription }} </div>
      </div>
      <RelaxifyContactUsForm :advisory-area-options="advisoryAreaOptions" :educational-level-options="educationalLevelOptions" :form-button-title="formButtonTitle" :is-the-form-on-join-the-community="true" :form-data="formFieldNames" />
    </div>
    <RelaxifyFooter/>
  </div>
</template>

<script>
import RelaxifyFooter from "@/components/RelaxifyFooter";
import RelaxifyHeader from "@/components/RelaxifyHeader";
import {
  getJoinTheCommunityPageData, getJoinTheCommunityStartPage, getJoinTheCommunityStartPageTitle,
  getJoinTheCommunityStartPageDescription, getJoinTheCommunityStartPageSecondDescription,
  getJoinTheCommunityForm, getJoinTheCommunityEducationalLevelOptions, getJoinTheCommunityAdvisoryAreaOptions,
  getJoinTheCommunityButtonTitle
} from '@/getters/joinTheCommunityGetters';
import resources from "@/resources";
import RelaxifyContactUsForm from "@/components/RelaxifyContactUsForm";

export default {
  name: "RelaxifyJoinTheCommunity",
  components: {
    RelaxifyContactUsForm,
    RelaxifyFooter,
    RelaxifyHeader
  },
  metaInfo() {
    return {
      title: 'Join The Community - Become Our Advisor | RelaxifyApp',
      meta: [
        {
          name: 'description',
          content: 'To successfully complete your advisor application, please fill in the form below. A person from our team will reach out to you within the next several days.'
        },
        {
          name: 'robots',
          content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
        },
        {
          property: 'fb:app_id',
          content: '833032110773133'
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:title',
          content: 'Join The Community - Become Our Advisor | RelaxifyApp'
        },
        {
          property: 'og:site_name',
          content: 'RelaxifyApp'
        }
      ],
      link: [
        {rel: 'canonical', href: window.location.href}
      ]
    }
  },
  setup() {
    const joinTheCommunityPageData = getJoinTheCommunityPageData(resources);
    const joinTheCommunityStartPageData = getJoinTheCommunityStartPage(joinTheCommunityPageData);
    const startPageTitle = getJoinTheCommunityStartPageTitle(joinTheCommunityStartPageData);
    const startPageDescription = getJoinTheCommunityStartPageDescription(joinTheCommunityStartPageData);
    const startPageSecondDescription = getJoinTheCommunityStartPageSecondDescription(joinTheCommunityStartPageData);
    const formFieldNames = getJoinTheCommunityForm(joinTheCommunityStartPageData);
    const educationalLevelOptions = getJoinTheCommunityEducationalLevelOptions(joinTheCommunityStartPageData);
    const advisoryAreaOptions = getJoinTheCommunityAdvisoryAreaOptions(joinTheCommunityStartPageData);
    const formButtonTitle = getJoinTheCommunityButtonTitle(joinTheCommunityStartPageData);

    return {
      startPageTitle,
      startPageDescription,
      startPageSecondDescription,
      formFieldNames,
      educationalLevelOptions,
      advisoryAreaOptions,
      formButtonTitle
    }
  }
}
</script>

<style lang="scss">
.RelaxifyJoinTheCommunity {

  @media (min-width: 1440px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  margin: 0 10px;

  &__start__page {

    &__title {

      @media (min-width: 1440px) {
        font-weight: 700;
        font-size: 56px;
        line-height: 110%;
        max-width: 550px;
      }

      font-size: 32px;
      font-weight: 700;
      line-height: 120%;
      color: #193566;
      margin: 50px auto !important;
      text-align: center;
      max-width: 370px;
    }

    &__description {
      @media (min-width: 1440px) {
        max-width: 615px;
      }

      @media (max-width: 768px) {
        margin: 0 10px;
      }

      font-size: 20px;
      text-align: center;
      font-weight: 400;
      line-height: 150%;
      max-width: 377px;
      color: #193566;
      margin: 0 auto;

      &:first-of-type {
        margin-bottom: 25px;
      }
    }
  }

  .RelaxifyContactUsForm {
    margin: 50px 10px 40px 10px;

    @media (min-width: 1440px) {
      margin-right: 400px;
      margin-left: 400px;
    }

    @media (min-width: 1800px) {
      margin-right: 600px;
      margin-left: 600px;
    }
  }



}
</style>
