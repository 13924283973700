const homePage = 'home';
const startPage = 'start page';
const benefits = 'BENEFITS';
const puzzle = 'THE PUZZLE';
const methodology = 'METHODOLOGY'
const userTestimonials = 'USER TESTIMONIALS';
const numbers = 'NUMBERS';
const beHappy = 'BE HAPPY';
const media = 'IN MEDIA';
const collobarartion = 'COLLABORATION';
const hrSolution = 'HR SOLUTIONS DESIGNED TO HELP YOU';
const roadmap = 'ROADMAP';
const hrBiDashboard = 'HR BI Dashboard:';
const employeeSurvey = 'Employee Survey'
const humanResources = 'Human Resources Consultancy';
const relaxifyForEmployees = 'RelaxifyApp for your employees';
const relaxifyLastPart = 'last part';

export const getHomePageData = (pages) => pages.find(item => item.page.name = homePage);

export const getHomePageStartPartData = (content) => content.page.parts.find((item) => item.partName === startPage);
export const getHomePageStartPartTitle = (content) => content.title;
export const getHomePageStartPartDescription = (content) => content.description;
export const getHomePageStartPartButtonTitle = (content) => content.buttonTitle;
export const getHomePageStartPartBackground = (content) => content.backgroundImage;
export const getHomePageStartPartBackgroundMobile = (content) => content.backgroundMobileImage;

export const getHomePageHrSolutionData = (content) => content.page.parts.find(item => item.partName === hrSolution);
export const getHomePageHrSolutionTitle = (content) => content.partName;
export const getHomePageHrSolutionElements = (content) => content.elements;
export const getHomePageHrSolutionButtonTitle = (content) => content.buttonTitle;

export const getHomePageBenefitsData = (content) => content.page.parts.find((item) => item.partName === benefits);
export const getHomePageBenefitsTitle = (content) => content.title;
export const getHomePageBenefitsDescription = (content) => content.description;
export const getHomePageBenefitsItems = (content) => content.benefitsElements;

export const getHomePageRoadmapData = (content) => content.page.parts.find((item) => item.partName === roadmap);
export const getHomePageRoadmapElements = (content) => content.elements;

export const getHomePageHrBiDashboardData = (content) => content.page.parts.find((item) => item.partName === hrBiDashboard);

export const getHomePageEmployeeSurveyData = (content) => content.page.parts.find((item) => item.partName === employeeSurvey);

export const getHomePageHumanResourcesData = (content) => content.page.parts.find((item) => item.partName === humanResources);

export const getHomePageRelaxifyForEmployeesData = (content) => content.page.parts.find((item) => item.partName === relaxifyForEmployees);

export const getHomePageLastPartData = (content) => content.page.parts.find((item) => item.partName === relaxifyLastPart);

export const getHomePagePuzzleData = (content) => content.page.parts.find((item) => item.partName === puzzle);
export const getHomePagePuzzleTitle = (content) => content.title;
export const getHomePagePuzzleDescription = (content) => content.description;
export const getHomePagePuzzleImage = (content) => content.image;
export const getHomePagePuzzleImageDesktop = (content) => content.image_desktop;

export const getHomePageMethodologyData = (content) => content.page.parts.find((item) => item.partName === methodology);
export const getHomePageMethodologyTitle = (content) => content.title;
export const getHomePageMethodologyDescription = (content) => content.description;
export const getHomePageMethodologyDescriptionForDesktop = (content) => content.description_desktop;
export const getHomePageMethodologyImage = (content) => content.image;
export const getHomePageMethodologyButtonTitle = (content) => content.buttonTitle;

export const getHomePageUserTestimonialsData = (content) => content.page.parts.find((item) => item.partName === userTestimonials);
export const getHomePageUserTestimonialsTitle = (content) => content.title;
export const getHomePageUserTestimonialsDescription = (content) => content.description;
export const getHomePageUserTestimonialsReviews = (content) => content.userReviews;

export const getHomePageNumbersData = (content) => content.page.parts.find((item) => item.partName === numbers);
export const getHomePageNumbersTitle = (content) => content.title;
export const getHomePageNumbersDescription = (content) => content.description;
export const getHomePageNumbersItems = (content) => content.items;

export const getHomePageArticlesData = (content) => content.page.parts.find((item) => item.partName === beHappy);
export const getHomePageArticlesTitle = (content) => content.title;
export const getHomePageArticlesDescription = (content) => content.description;
export const getHomePageArticlesItems = (content) => content.items;

export const getHomePageMediaData = (content) => content.page.parts.find((item) => item.partName === media);
export const getHomePageMediaTitle = (content) => content.title;
export const getHomePageMediaLogos = (content) => content.mediaLogos;

export const getHomePageCollaborationData = (content) => content.page.parts.find((item) => item.partName === collobarartion);
export const getHomePageCollaborationTitle = (content) => content.title;
export const getHomePageCollaborationLogos = (content) => content.collaborationLogos;


const homePageGetters = {
  getHomePageData,
  getHomePageStartPartData,
  getHomePageStartPartTitle,
  getHomePageStartPartDescription,
  getHomePageStartPartButtonTitle,
  getHomePageStartPartBackground,
  getHomePageBenefitsData,
  getHomePageBenefitsItems,
  getHomePagePuzzleData,
  getHomePagePuzzleTitle,
  getHomePagePuzzleDescription,
  getHomePagePuzzleImage,
  getHomePageMethodologyData,
  getHomePageMethodologyTitle,
  getHomePageMethodologyDescription,
  getHomePageMethodologyImage,
  getHomePageMethodologyButtonTitle,
  getHomePageUserTestimonialsData,
  getHomePageUserTestimonialsTitle,
  getHomePageUserTestimonialsDescription,
  getHomePageUserTestimonialsReviews,
  getHomePageNumbersData,
  getHomePageNumbersTitle,
  getHomePageNumbersDescription,
  getHomePageNumbersItems,
  getHomePageArticlesData,
  getHomePageArticlesTitle,
  getHomePageArticlesDescription,
  getHomePageArticlesItems,
  getHomePageStartPartBackgroundMobile,
  getHomePageMediaData,
  getHomePageCollaborationData,
  getHomePageMediaTitle,
  getHomePageMediaLogos,
  getHomePageCollaborationTitle,
  getHomePageCollaborationLogos,
  getHomePageRoadmapData,
  getHomePageRoadmapElements,
  getHomePageHrBiDashboardData,
  getHomePageEmployeeSurveyData,
  getHomePageHumanResourcesData,
  getHomePageRelaxifyForEmployeesData,
  getHomePageLastPartData
}

export default homePageGetters;
