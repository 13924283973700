<template>
  <div class="RelaxifyAwardsFooter" id="relaxifyFooter">
    <div class="RelaxifyAwardsFooter__blue-part">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5R_GrrcJyNgrFOlZmpnIyrqLrDXo43C_jQTlXLdW_RnwzIw/viewform">
        <RelaxifyButton color="primary"> {{ corporateButtonTitle }}</RelaxifyButton>
      </a>
      <img loading="lazy" class="RelaxifyAwardsFooter__flower" alt="Relaxify flower" :src="flowerLogo"/>
    </div>
    <div v-if="currentPath !== corporate" class="RelaxifyAwardsFooter__investors__eu__institutions">
      <div class="RelaxifyAwardsFooter__investors__eu__institutions__logos">
        <img loading="lazy" width="157" v-for="(investor, index) in corporateGrowthData.euInstitutionsLogos"
             :key="index"
             :src="investor.logo" :alt="investor.name"/>
      </div>
      <div class="RelaxifyAwardsFooter__investors__eu__institutions__descriptions">
        <div v-for="(desc, index) in corporateGrowthData.descriptions" :key=index class="RelaxifyAwardsFooter__investors__eu__institutions__desc"> {{ desc }}</div>
      </div>
    </div>
    <div class="RelaxifyAwardsFooter__pages-for-redirect">
      <router-link class="RelaxifyFooter__logo" :to="innerLinkToHomePage">
        <img loading="lazy" class="RelaxifyHeader__second-part--logo" alt="Relaxify logo"
             src="../../public/header/relaxify-logo.svg">
      </router-link>
      <div class="RelaxifyFooter__redirect-items">
        <div class="RelaxifyFooter__redirect-item" v-for="(element, index) in menuOptions" :key="index">
          <router-link v-if="element.title !== blog && element.title !== forEmployees" :to="element.href">
            <v-list-item-title v-if="element.title !== 'Join the Community'" v-text="element.title"/>
          </router-link>
          <a v-else :href="element.href">
            <v-list-item-title v-text="element.title">
            </v-list-item-title>
          </a>
        </div>
      </div>
      <div class="RelaxifyFooter__social-icons">
        <a v-for="(icon, index) in socialIconsColored" :key="index" :href="icon.href">
          <img loading="lazy" class="RelaxifyFooter__social-icon icon-awards" :alt="icon.title" :src="icon.path"/>
        </a>
      </div>
    </div>
    <v-divider/>
    <div class="RelaxifyAwardsFooter__terms">
      <a v-for="(term, index) in terms" :key=index :href="term.href">
        <span> {{ term.title }} </span>
      </a>
    </div>
  </div>
</template>

<script>
import {
  getFooterData,
  getFooterUpperTitle,
  getFooterMainTitle,
  getFooterDescription,
  getFooterRedirectElements,
  getFooterSocialIcons,
  getFooterTerms,
  getFooterAppStoreLogo,
  getFooterGooglePlayLogo,
  getFooterFlowerLogo,
  getFooterCorporateUpperTitle,
  getFooterCorporateMainTitle,
  getFooterCorporateDescription,
  getFooterMenuOptions,
  getFooterSocialIconsColored
} from '../getters/footerGetters'
import resources from "@/resources";
import RelaxifyButton from "@/components/RelaxifyButton";
import {computed} from "vue";
import {getCorporateGrowthData, getCorporatePageData} from "@/getters/corporateGetters";

export default {
  name: "RelaxifyAwardsFooter",
  components: {RelaxifyButton},
  setup() {
    const footerData = getFooterData(resources);
    const currentPath = computed(() => window.location.pathname);
    const corporate = '/corporate/';
    const upperTitle = getFooterUpperTitle(footerData);
    const mainTitle = getFooterMainTitle(footerData);
    const description = getFooterDescription(footerData);
    const googlePlayLogo = getFooterGooglePlayLogo(footerData);
    const appStoreLogo = getFooterAppStoreLogo(footerData)
    const flowerLogo = getFooterFlowerLogo(footerData);
    const socialIcons = getFooterSocialIcons(footerData);
    const socialIconsColored = getFooterSocialIconsColored(footerData);
    const redirectElements = getFooterRedirectElements(footerData);
    const terms = getFooterTerms(footerData);
    const corporateUpperTitle = getFooterCorporateUpperTitle(footerData);
    const corporateMainTitle = getFooterCorporateMainTitle(footerData);
    const corporateDescription = getFooterCorporateDescription(footerData);
    const corporateButtonTitle = "Свържете се с нас";
    const menuOptions = getFooterMenuOptions(footerData);
    const corporatePageData = getCorporatePageData(resources);
    const corporateGrowthData = getCorporateGrowthData(corporatePageData);

    const innerLinkToHomePage = '/';
    const blog = 'Blog';
    const forEmployees = 'RelaxifyApp for employees';


    return {
      innerLinkToHomePage,
      socialIcons,
      terms,
      upperTitle,
      mainTitle,
      description,
      googlePlayLogo,
      appStoreLogo,
      redirectElements,
      flowerLogo,
      corporateUpperTitle,
      corporateMainTitle,
      corporateDescription,
      corporateButtonTitle,
      currentPath,
      corporate,
      yellowCircleImage: footerData.yellowCircleImage,
      blueCircleImage: footerData.blueCircleImage,
      menuOptions,
      socialIconsColored,
      corporateGrowthData,
      blog,
      forEmployees
    }
  }
}
</script>

<style lang="scss">

.RelaxifyAwardsFooter {

  .RelaxifyButton {
    @media (max-width: 767px) {
      display: block;
    }
  }

  &__mobile_stores {
    @media (max-width: 767px) {
      display: none;
    }
    display: block;
  }

  .parent {
    width: 100%;
    height: 200px;
    background-color: #8ae6a2;
    position: relative;
  }

  .child {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #8ae6a2;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  &__blue-part {
    @media (min-width: 1440px) {
    }

    @media (max-width: 539px) {

    }

    @media (min-width: 540px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 820px) {

    }

    @media (min-width: 912px) {

    }

    @media (min-width: 1024px) {

    }

    @media (min-width: 1280px) {

    }

    @media (min-width: 1440px) {

    }

    @media (min-width: 1780px) {

    }
    @media (min-width: 1840px) {

    }

    @media (min-width: 1920px) {

    }


    background: #193566;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .RelaxifyButton {
      margin-top: 70px;

      @media (min-width: 1024px) {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
      @media (min-width: 1440px) {
        margin-top: 30px;
      }

      button {
        width: 100%;
        height: 50px !important;
        color: #193566 !important;

        @media (min-width: 1024px) {
          width: 307px;
          font-weight: bold;
        }
      }
    }

    a {
      width: 100%;
      padding: 0 20px;
      margin-bottom: 50px;
    }

    //&__ellipse {
    //  width: 314.64px;
    //  height: 111.68px;
    //  background: #5358C2;
    //  position: absolute;
    //  top: 169px;
    //  z-index: -5;
    //  border-radius: 80% / 100% 100% 0% 0%;
    //}
  }

  &__flower {

    @media (min-width: 414px) {
    }

    @media (min-width: 412px) {
    }
  }

  &__h2-title {
    @media (min-width: 1440px) {
      font-size: 48px;
      max-width: 410px;
      margin-top: 25px;
    }

    font-size: 32px;
    text-align: center;
    color: white;
    font-weight: 700;
    margin: 15px 0 20px 0;
    max-width: 270px;
  }

  &__elipse {
    border-radius: 50% / 0 0 100% 100%;
    min-height: 80px;
    background: #5358C2;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }

  &__h6-title {
    @media (min-width: 1440px) {
      font-size: 20px;
      max-width: 500px;
      margin-top: 10px;
    }

    font-size: 14px;
    font-weight: 400;
    line-height: 15.4px;
    text-align: center;
    color: white;
  }

  &__description {
    @media (max-width: 1023px) {
      padding: 0 20px;
    }

    @media (min-width: 1024px) {
      max-width: 600px;
    }

    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: white;
    max-width: 370px;
    text-align: center;
    margin-bottom: 40px;
  }

  &__pages-for-redirect {
    @media (min-width: 1024px) {
      justify-content: space-around;
      display: flex;
      margin-left: unset;

      img {
        margin-right: 145px;
      }
    }
    margin-top: 60px;
    margin-left: 25px;

    img {
      @media (min-width: 1440px) {
        margin-right: 235px;
      }
      margin-bottom: 20px;
    }
  }

  &__redirect-items {
    @media (min-width: 1024px) {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
    }
  }

  &__redirect-item {
    flex: 35%;

    .v-list-item__title {
      @media (min-width: 1440px) {
        font-size: 22px;
        line-height: 29px;
      }

      margin-bottom: 17px;
      font-size: 16px;
      font-weight: 400;
      color: #193566
    }
  }

  &__social-icons {
    @media (min-width: 1024px) {
      margin: unset;
    }
    margin-top: 35px;

    .RelaxifyFooter__social-icon {
      margin-right: 15px;
    }
  }

  .v-divider {
    margin: 30px 20px 25px 20px;
  }

  &__terms {
    @media (min-width: 1024px) {
      text-align: center;

    }

    margin-left: 25px;

    span {
      @media (min-width: 1024px) {
        font-size: 12px;
      }
      @media (min-width: 1440px) {
        font-size: 14px;
        margin-right: 60px;
        line-height: 18px;
      }

      font-size: 10px;
      color: #193566;
      font-weight: 400;
      margin-right: 25px;
      padding-bottom: 25px;
    }
  }

  &__logo {
    @media (min-width: 1440px) {
      img {
        width: 179px;
        height: 50px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  &__investors__eu__institutions {

    &__logos {
      margin: 20px;
      @media (min-width: 1024px) {
      margin-top: 50px;
        margin-bottom: 20px;
      }

      @media (min-width: 1440px) {
        margin: 50px 100px;
      }
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;

      img {
        @media (min-width: 360px) {
          height: 90px;
          width: 155px;
        }
        @media (min-width: 600px) {
          height: 160px;
        }

        @media (min-width: 768px) {
          width: 300px;
          height: 140px;
        }
      }

      img:nth-child(1) {
        @media (min-width: 360px) {
          height: 160px;
        }
        @media (min-width: 600px) {
          width: 180px;
          height: 160px;
        }
        @media (min-width: 768px) {
          height: 190px;
          width: 250px;
        }
      }
    }

    &__descriptions {
      display: flex;
      margin-bottom: 90px;

      @media (max-width: 1023px) {
        flex-direction: column;
      }
    }

    &__desc {
      font-size: 20px;
      font-weight: 400;
      line-height: 150%;
      color: #193566;
      margin: 20px auto !important;
      max-width: 484px !important;
      text-align: center !important;
    }
  }

}

</style>
