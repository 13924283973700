<template>
  <div>
    <RelaxifyHeader/>
    <div class="RelaxifyNotFound__text"> {{ 'Page Not Found !' }}</div>
    <RelaxifyFooter/>
  </div>
</template>

<script>
import RelaxifyHeader from "@/components/RelaxifyHeader";
import RelaxifyFooter from "@/components/RelaxifyFooter";

export default {
  name: "RelaxifyNotFound",
  components: {RelaxifyFooter, RelaxifyHeader},

}
</script>

<style lang="scss">
.RelaxifyNotFound__text {
 background: red;
  font-size: 40px;
  text-align: center;
}
</style>
