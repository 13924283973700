// Constant titles
const aboutUs = 'about-us';
const firstBox = 'first-box';
const ourDrive = 'OUR DRIVE';
const together = 'TOGETHER';
const teamMembers = 'PROBLEM-SOLVERS';
const getInTouch = 'GET IN TOUCH';
// Getters

export const getAboutUsPageData = (pages) => pages.find(item => item.page.name === aboutUs);
export const getAboutUsFirstBoxData  = (content) => content.page.parts.find(item => item.partName === firstBox);
export const getAboutUsOurDriveData = (content) => content.page.parts.find(item => item.partName === ourDrive);
export const getAboutUsTogetherData = (content) => content.page.parts.find(item => item.partName === together);
export const getAboutUsTeamMembers = (content) => content.page.parts.find(item => item.partName === teamMembers);
export const getAboutUsContactUs = (content) => content.page.parts.find(item => item.partName === getInTouch);
const aboutUsGetters = {
    getAboutUsPageData,
    getAboutUsFirstBoxData,
    getAboutUsOurDriveData,
    getAboutUsTogetherData,
    getAboutUsTeamMembers,
    getAboutUsContactUs
}

export default aboutUsGetters;
