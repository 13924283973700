/* eslint-disable  */

export default {
  RelaxifyHome: {
    'test': 'test',
    'background-image-title': 'A Well-Being App to Play Your Troubles Away!',
    'background-image-description': 'Science, technology, and gamification come together to make you feel better and do better every next day. Start your journey now and do your mental fitness on the go!',
    'benefits-title': 'Benefits',
    'benefits-subtitle': 'One App - Four Ways to Improve Mental Health',
    'benefits-description': 'We know how to pick up the pieces of the puzzle. And we know how to make it fun!',
    'puzzle-title': ' THE PUZZLE',
    'puzzle-subtitle': 'Stay Calm and Play!',
    'puzzle-description': ' Working on your physical, emotional, cognitive and social well-being is how you pick up the pieces and put your puzzle together. When going through the different levels of your in-app program, you will be able to address issues such as:',
    'methodology-title': 'METHODOLOGY',
    'methodology-subtitle': 'The Health and Well-Being App, Inspired by Innovation',
    'methodology-description': 'As the world evolves, we evolve with it. In the Relaxify well-being app, we’ve brought together the latest findings in the world of conventional and e-psychology, along with the most advanced mobile technologies. Then, we’ve made it all about you!',
    'methodology-button-title': 'Learn more',
    'testimonials-title': 'USER TESTIMONIALS',
    'testimonials-subtitle': 'Let People Do The Speaking',
    'testimonials-description': 'Before you start your journey with us, you’d probably like to hear from those who are already there!',
    'numbers-title': 'NUMBERS',
    'numbers-subtitle': 'And Let Numbers Paint the Picture',
    'numbers-description': 'We believe that measurable results speak louder than words, so here is what we’ve managed to achieve until now',
    'articles-title': 'BE HAPPY',
    'articles-subtitle': 'Get Free Stress Release and Well-Being Tips',
    'articles-description': 'Keep an eye on our blog, where you’ll find tons of useful and hands-on advice on how to improve your mental health in the long run.',
  },
  RelaxifyHeader: {
    'first-part': 'Get 14 days for free now'
  },
  RelaxifyButton: {
    'title': 'Contact sales',
    'see-all-articles': 'See all articles'
  },
  RelaxifyFooter: {
    'h6-title': 'JOIN MILLIONS',
    'h2-title': 'Are You Ready to Get Better ?',
    'description': 'Take our well-being survey and get a personalized well-being program with daily games, challenges, and weekly targets!'
  }
};
