<template>
  <div class="RelaxifyHeader">
    <div v-if="currentPath === aboutTheApp" class="RelaxifyHeader__first-part">
      <a v-if="currentWindowWidth <1024" href="https://onelink.to/x3rfwb">
        <span> {{ $t('RelaxifyHeader.first-part') }}  </span>
      </a>
      <span v-else @click="goToFooterContacts('relaxifyFooter')"> {{ $t('RelaxifyHeader.first-part') }}  </span>
    </div>
    <div>
      <div class="RelaxifyHeader__second-part mobile">
        <img loading="lazy" v-if="!isMobileTryMenuOpen" width="28" height="19"
             class="RelaxifyHeader__second-part--burger-menu"
             src="../../public/icons/burger-menu.svg" alt="Burger menu" @click="openMobileTray"/>
        <img loading="lazy" width="20" height="20" v-else class="RelaxifyHeader__second-part--x-icon"
             src="../../public/icons/x-icon.svg" alt="x-icon"
             @click="closeMobileTray"/>
        <v-navigation-drawer
            temporary
            fixed
            class="RelaxifyHeaderTrayMenu"
            test-id="RelaxifyTrayMenu"
            :value="isMobileTryMenuOpen"
            :v-click-outside="closeMobileTray">

          <div class="RelaxifyHeader__menu-content">
            <v-list
                v-for="(item, i) in currentPath === corporate ? menuOptionsForCorporate : (currentPath === awards ? menuOptionsForAwards : menuOptions)"
                :key="i"
                class="RelaxifyHeaderTrayMenu__menu-item"
                :class="item.href === currentPath? 'selected' : ''">

              <router-link v-if="item.title !== blog && item.title !== forEmployees" :to="item.href">
                <v-list-item-title v-text="item.title">
                </v-list-item-title>
              </router-link>
              <a v-else :href="item.href">
                <v-list-item-title v-text="item.title">
                </v-list-item-title>
              </a>
            </v-list>
          </div>

          <div class="RelaxifyHeader__mobile-stores">
            <a href="https://play.google.com/store/apps/details?id=org.relaxifyapp.me&referrer=utm_source%3Dwebplay">
              <img loading="lazy" alt="Google play" src="../../public/icons/google-play.svg"/>
            </a>
            <a href="https://apps.apple.com/us/app/relaxyfyapp/id1573210878">
              <img loading="lazy" alt="App store" src="../../public/icons/app-store.svg"/>
            </a>
          </div>

        </v-navigation-drawer>
        <router-link :to="innerLinkToHomePage">
          <img loading="lazy" class="RelaxifyHeader__second-part--logo" alt="Relaxify logo" :src="logo">
        </router-link>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5R_GrrcJyNgrFOlZmpnIyrqLrDXo43C_jQTlXLdW_RnwzIw/viewform">
          <RelaxifyButton color="primary"> {{ $t('RelaxifyButton.title') }}</RelaxifyButton>
        </a>
      </div>
    </div>

    <div class="RelaxifyHeader__second__part__desktop">
      <header class="RelaxifyHeader__main__side__space">
        <div class="RelaxifyHeader__logo__space">
          <router-link :to="innerLinkToHomePage">
            <img loading="lazy" class="RelaxifyHeader__second-part--logo" alt="Relaxify logo" :src="logo">
          </router-link>
        </div>
        <div class="RelaxifyHeader__menu__options__side" :class="{ 'awards': currentPath === awards }">
          <v-list
              v-for="(item, i) in currentPath === corporate ? menuOptionsForCorporate : (currentPath === awards ? menuOptionsForAwards : menuOptions)"
              :key="i"
              class="RelaxifyHeader__menu__options__side__item"
              :class="item.href === currentPath? 'selected__desktop' : ''">

            <router-link v-if="item.title !== blog && item.title !== forEmployees" :to="item.href">
              <v-list-item-title v-text="item.title">
              </v-list-item-title>
            </router-link>
            <a v-else :href="item.href">
              <v-list-item-title v-text="item.title">
              </v-list-item-title>
            </a>
          </v-list>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5R_GrrcJyNgrFOlZmpnIyrqLrDXo43C_jQTlXLdW_RnwzIw/viewform">
            <RelaxifyButton class="RelaxifyHeader__menu__options__side__button" color="primary">
              {{ $t('RelaxifyButton.title') }}
            </RelaxifyButton>
          </a>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import RelaxifyButton from "@/components/RelaxifyButton";
import {
  getHeaderData,
  getHeaderMenuItems, getHeaderMenuItemsForAwards,
  getHeaderMenuItemsForCorporate,
  getHeaderMenuLogo
} from "@/getters/headerGetters";
import {goToFooterContacts} from "@/helpers/helpers";
import resources from "@/resources";

export default {
  name: "RelaxifyHeader",
  components: {RelaxifyButton},
  setup() {

    const currentPath = computed(() => window.location.pathname);

    const currentWindowWidth = computed(() => window.innerWidth);

    const isAdviceMenuOpen = ref(true);

    const isMobileTryMenuOpen = ref(false);

    const openMobileTray = () => {
      isMobileTryMenuOpen.value = true
    }

    const closeMobileTray = () => {
      isMobileTryMenuOpen.value = false
    }

    const headerData = getHeaderData(resources);

    const menuOptions = getHeaderMenuItems(headerData);

    const menuOptionsForCorporate = getHeaderMenuItemsForCorporate(headerData);

    const menuOptionsForAwards = getHeaderMenuItemsForAwards(headerData);

    const logo = getHeaderMenuLogo(headerData);

    const innerLinkToHomePage = '/';

    const corporate = '/corporate/';

    const awards = '/awards/';

    const aboutTheApp = '/about-the-app/';

    const blog = 'Blog';

    const forEmployees = 'RelaxifyApp for employees';


    return {
      openMobileTray,
      menuOptions,
      isAdviceMenuOpen,
      isMobileTryMenuOpen,
      closeMobileTray,
      currentPath,
      innerLinkToHomePage,
      headerData,
      corporate,
      menuOptionsForCorporate,
      blog,
      logo,
      goToFooterContacts,
      currentWindowWidth,
      forEmployees,
      menuOptionsForAwards,
      awards,
      aboutTheApp
    }
  }
}
</script>

<style lang="scss">
.RelaxifyHeader {
  &__first-part {
    height: 60px;
    background: #58B44D;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 16px;
    color: #193566 !important;

    span {
      cursor: pointer;
    }

    a:active {
      color: #193566;
    }
  }

  &__second-part {

    @media (min-width: 768px) {
      justify-content: space-between;
    }
    padding: 4px 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    flex-direction: row;
    height: 72px;
    background: #FFFF;

    &--menu {
      width: 28.48px;
      height: 20px;
    }

    &--logo {
      @media (min-width: 1440px) {
        width: 143px;
        height: 40px;
      }
      width: 126px;
      height: 35px;
      margin-right: 13px;
      margin-bottom: -8px;
    }

    &--burger-menu {
      cursor: pointer;

      @media (max-width: 490px) {
        margin-top: 4px;
        margin-right: 7px;
      }
    }

    v-btn {
      font-size: 16px;
      letter-spacing: 0.005em;
      padding: 13px 16px !important;
      border-radius: 5px;
      box-shadow: none;
      background: #FD6584;
      color: white;
      width: 127px;
      height: 14px;
      text-align: center;
    }

    .RelaxifyButton {
      button {
        min-width: 160px !important;
        color: #193566 !important;
      }
    }

    .RelaxifyHeaderTrayMenu {
      top: 132px !important;
      width: 100% !important;
      background: #5358C2;

      &__menu-item {
        &--title {
          height: 57px;
          font-size: 18px;
          line-height: 27px;
          padding: 0 16px
        }
      }
    }
  }

  .mobile {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .v-list-group__items {
    padding: 0 16px;
  }

  .v-list-item {
    padding: unset;
  }

  .RelaxifyHeaderTrayMenu {

    .RelaxifyHeader {

      &__menu-content {
        padding: 25px;
        margin-bottom: 20px;

        .selected {
          background: #FFC726;
          margin: 0 -25px;

          .v-list-item__title {
            margin-left: 25px;
          }
        }
      }

      &__mobile-stores {
        padding-left: 25px;
        display: inline-grid;

        img:nth-child(1) {
          margin-bottom: 20px;
        }
      }
    }

    .v-list-item__title {
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      line-height: 150%;
      color: #FFFFFF;
    }

    .v-list-group {

      .v-list-item {
        min-height: unset;
      }

      .v-list-item::before {
        opacity: unset;
        background: unset;
        transition: unset;
      }

      .expand-transition-leave-active {
        transition: unset !important;
      }

      .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        background: unset;
        transition: unset;
      }

      .v-list-item__title {
        flex: unset;
      }

      .v-list-item__icon {
        justify-content: unset;
        margin-left: 4px;

        .v-icon {
          color: #FFFFFF;
        }
      }
    }
  }

  &__main__side__space {
    margin: 0 60px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .RelaxifyHeader__logo__space {
      @media (min-width: 1440px) {
        flex: 30%;
      }
    }
  }

  .awards {
    flex: 1%;
  }

  &__menu__options__side {
    @media (min-width: 1440px) {
      flex: 85%;
      justify-content: space-evenly;
    }

    display: flex;
    justify-content: center;

    &__item {
      margin-right: 20px;

      .v-list-item__title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #193566;

        @media (min-width: 1024px) {
          font-size: 10px;
        }

        @media (min-width: 1180px) {
          font-size: 13px;
        }

        @media (min-width: 1366px) {
          font-size: 18px;
        }
      }
    }

    &__button {
      width: 160px !important;

      .v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.primary {
        width: 160px !important;
        min-width: unset !important;
        color: #193566 !important;
      }
    }

    .selected__desktop {
      border-bottom: 3px solid #5358C2;
    }

  }

  a {
    text-decoration: none;
  }

  &__second__part__desktop {
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
  }
}
</style>
